import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../assets/images/change-password-image.png";
import logo8 from "../assets/images/show-pass.svg";
import logo9 from "../assets/images/logo-simple.png";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { postData, updateData } from "../services/services/request";
import { getItem, setItem } from "../Lib/localStorage/localStorage";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(""); // Specific error for new password
  const [confirmPasswordError, setConfirmPasswordError] = useState(""); // Specific error for confirm password
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const uniqueCode = urlParams.get("uniqueCode");
  console.log("UNI",uniqueCode)
  const navigate = useNavigate();

  // Validate inputs (for password match, length, and empty confirm password)
  const validateInputs = () => {
    let newPasswordError = "";
    let confirmPasswordError = "";

    if (!newPassword.trim()) newPasswordError = "Password is required!";
    else if (newPassword.length < 6)
      newPasswordError = "Password must be at least 6 characters!";

    if (!confirmPassword.trim())
      confirmPasswordError = "Confirm Password is required!";
    else if (newPassword !== confirmPassword)
      confirmPasswordError = "Passwords do not match!";

    setNewPasswordError(newPasswordError);
    setConfirmPasswordError(confirmPasswordError);

    // Return whether there is any error
    return newPasswordError || confirmPasswordError;
  };

  // Submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Validate the inputs first
    const validationError = validateInputs();
    if (validationError) {
      setLoading(false); // Stop loading if there are validation errors
      return; // Don't proceed to backend if validation fails
    }

    // If validation passes, proceed with backend call
    const userId = getItem("UserId");

    try {
      const response = await updateData(
        `auths/resetPassword?uniqueCode=${uniqueCode}`,
        {
          password: newPassword, // Send the new password
        }
      );

      // If successful, navigate to login
      navigate("/login");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      console.error("Backend error:", errorMessage);
    } finally {
      setLoading(false); // Stop loading once the backend call is finished
    }
  };

  return (
    <div
      className="flex flex-col sm:flex-row justify-center items-center min-h-screen w-full mx-auto"
      style={{ backgroundImage: `url(${caresimply})` }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Left Side Image */}
        <div className="w-full sm:w-1/2">
          <Link>
            <img
              src={logo1}
              className="h-auto sm:h-[610px] w-full sm:w-[555px] object-cover"
              alt="Reset Password"
            />
          </Link>
        </div>
        {/* Right Side Form */}
        <div className="w-full sm:w-1/2 bg-[#F5F7F9] p-6 md:p-10 lg:p-12">
          <Link to="">
            {" "}
            <div className="flex justify-center h-[100px]">
              <img src={logo9} alt="Company Logo" />
            </div>
          </Link>

          <div className="text-left">
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
              Reset <span className="text-[#AEDFF7]">Password</span>
            </h1>
            <p className="text-[#5BACAD] font-semibold mt-2">
              Choose a new password for your account
            </p>
          </div>
          {/* Input Fields */}
          <div className="relative">
            <label className="font-semibold">
              New Password
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              className="block px-4 py-2 border border-gray-300 rounded-md w-full sm:max-w-md"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {newPasswordError && (
              <div className="text-red-500 font-semibold">
                {newPasswordError}
              </div>
            )}
            <div className="absolute lg:right-[13px] lg:top-[33px] right-[20px] top-[33px] text-[#C4C4C4]">
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} />
              </button>
            </div>
          </div>
          {/* Confirm Password */}
          <div className="relative py-2">
            <label className="font-semibold">
              Confirm Password
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="block px-4 py-2 border border-gray-300 rounded-md w-full sm:max-w-md"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPasswordError && (
              <div className="text-red-500 font-semibold">
                {confirmPasswordError}
              </div>
            )}
            <div className="absolute lg:right-[13px] lg:top-[40px] right-[20px] top-[33px] text-[#C4C4C4]">
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEye : faEyeSlash}
                />
              </button>
            </div>
          </div>
          {/* Submit Button */}
          <div className="mt-6">
            <button
              onClick={submitHandler}
              type="submit"
              className="bg-[#394E6C] text-white w-full rounded-md py-2 font-semibold hover:bg-blue-700 transition duration-200"
            >
              {loading ? "Loading ..." : "Reset Password"}
            </button>
          </div>
          {/* Back to Login */}
          <p className="text-center flex flex-col-reverse mt-32">
            <Link to="/login">
              <a href="#" className="font-semibold">
                Back to Login
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
