import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

import logo21 from "../assets/images/white-newArrowRight.svg";
import BellowHeader from "./BellowHeader";
import Recomendation from "./Recomendation";
import ShipAddress from "./ShipAddress";
import { getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";

export default function ShippingPayment() {
  const location = useLocation();
  const { id } = location.state || {};
  const [selectedAddress, setSeletedAddress] = useState({});
  const token = getItem("token");
  const tokens = token.replace(/"/g, "");
  const [cartInfo, setCartInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState("");

  const GetCartapi = async () => {
    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await getData("/carts/getCart/ByToken", config);

      if (response.data && Array.isArray(response.data.items)) {
        console.log("RESPONSE", response.data.totalAmount);
        setCartInfo(response.data.items);
        setPrice(response.data.totalAmount);
      } else {
        console.error(
          "Expected an array of items, but received:",
          response.data
        );
      }
    } catch (error) {
      console.log("Error in ADD to cart", error);
    }
  };
  useEffect(() => {
    GetCartapi();
  }, []);
  const address = {
    firstName: selectedAddress.firstName || "",
    lastName: selectedAddress.lastName || "",
    companyName: selectedAddress.companyName || "",
    address: selectedAddress.address || "",
    country: selectedAddress.country || "",
    state: selectedAddress.state || "",
    city: selectedAddress.city || "",
    zipCode: selectedAddress.zipCode || "",
    email: selectedAddress.email || "",
    phoneNumber: selectedAddress.phoneNumber || "",
  };

  const payment = {
    orderId: id,
    status: "Pending",
    amount: price,
  };

  const paymentDone = async () => {
    setLoading(true); // Start loading
    setError(""); // Reset error message

    if (!token) {
      console.error("UserId or Token not found in localStorage");
      setError("UserId or Token not found in localStorage");
      setLoading(false); // Stop loading after error
      return;
    }

    if (
      !address.firstName ||
      !address.lastName ||
      !address.address ||
      !address.city ||
      !address.state ||
      !address.zipCode
    ) {
      console.error("Please select an address");
      setError("Please select an address");
      setLoading(false); // Stop loading after error
      return;
    }

    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    const data = {
      address,
      payment,
    };

    try {
      const response = await postData(`/orders/placeOrder/${id}`, data, config);
      const paymentid = response.data._id;
      paymentComplete(paymentid);
    } catch (error) {
      console.error("Error placing order", error);
      setError("Error placing order, please try again.");
    } finally {
    }
  };

  const paymentComplete = async (paymentid) => {
    setLoading(true); // Start loading

    if (!token) {
      console.error("UserId or Token not found in localStorage");
      setError("UserId or Token not found in localStorage");
      setLoading(false); // Stop loading after error
      return;
    }

    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    const data = {
      orderId: paymentid,
    };

    try {
      const response = await postData(
        "/payments/createCheckoutProductSession",
        data,
        config
      );
      setLoading(true);
      if (response && response.data.paymentSession.url) {
        window.location.href = response.data.paymentSession.url; // Redirect to payment page
      } else {
        console.error("Checkout URL not found in response");
        setError("Error retrieving payment link");
      }
    } catch (error) {
      console.error("Error placing Payment", error);
      setError("Error placing payment, please try again.");
    } finally {
      setLoading(false); // Stop loading after the API call completes
    }
  };

  return (
    <>
      <Header />

      <div className="container mx-auto  max-w-[1450px] overflow-x-hidden pt-24 ">
        <BellowHeader />
        {/* billing information */}
        <div className=" px-2  lg:flex ">
          <div className=" w-2/3  flex flex-col justify-center text-center  items-start h-full pb-4">
            <h2 className="flex text-2xl font-semibold m-4 justify-start">
              Billing Information
            </h2>
            <div className="w-full">
              <ShipAddress setSeletedAddress={setSeletedAddress} />
            </div>
          </div>

          {/* right side */}
          <div className=" xs:w-screen lg:w-1/3  px-5 pr-8 ">
            <div className="flex flex-col w-full h-[450px]  rounded-md  border-2 mt-5 p-5">
              <div>
                <h1 className="font-semibold mb-5">Order Summary</h1>
              </div>

              <div class="max-h-[450px] h-full overflow-auto custom-scrollbar pr-4">
                {/* Render cart items dynamically using map */}
                {cartInfo.map((item, index) => (
                  <div className="pb-2" key={index}>
                    <div className="flex justify-between">
                      <div className="h-20 w-20 flex gap-4">
                        <img
                          src={item.medicineId.image[0]}
                          alt={item.name}
                          className="w-full h-auto object-cover"
                        />
                        <div className="p-5 pb-10">
                          <h4 className=" w-[120px] font-medium text-md truncate-100">
                            {item.medicineId.medicineName}
                          </h4>
                        </div>
                      </div>
                      <div className="pt-4">
                        <h1 className="">Qty : {item.quantity}</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="rounded-md pt-10 p-2 border-b-2">
                <div className="flex justify-between mb-1.5">
                  <h1>
                    <span className="text-gray-400 tracking-wider font-semibold text-md ">
                      Sub-total
                    </span>
                  </h1>
                  <div>
                    <p className="font-semibold">
                      <span className="p-1">${price}</span>
                    </p>
                  </div>
                </div>
                {/* <div className="flex justify-between mb-1.5">
                  <h1>
                    <span className="text-gray-400 tracking-wider font-semibold text-md ">
                      Shipping
                    </span>
                  </h1>
                  <div>
                    <p className="font-semibold">Free</p>
                  </div>
                </div>
                <div className="flex justify-between mb-1.5">
                  <h1>
                    <span className="text-gray-400 tracking-wider font-semibold text-md ">
                      Discount
                    </span>
                  </h1>
                  <div>
                    <p className="font-semibold">
                      <span className="p-1">${discount.toFixed(2)}</span>
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mb-1.5">
                  <h1>
                    <span className="text-gray-400 tracking-wider font-semibold text-md ">
                      Tax
                    </span>
                  </h1>
                  <div>
                    <p className="font-semibold">
                      <span className="p-1">${tax.toFixed(2)}</span>
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="rounded-md p-2">
                <div className="flex justify-between">
                  <h1>
                    <span className="text-xl font-semibold text-md ">
                      Total
                    </span>
                  </h1>
                  <div>
                    <p className="font-semibold">
                      <span className="p-1">${price} USD</span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <button
                    type="button" // Prevents form submission
                    onClick={paymentDone}
                    disabled={loading}
                    className="flex gap-2 items-center w-full justify-center bg-[#394e6c] text-white p-2 rounded-md"
                  >
                    {loading ? "Processing..." : "Place Order"}
                    <img src={logo21} alt="Icon" />
                  </button>

                  {/* Show error message below the button */}
                  {error && (
                    <div className="text-red-500 mt-2">
                      <p>{error}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* payment options */}
        <div className="pt-24 ">
          <Recomendation />
        </div>
      </div>
      <Footer />
    </>
  );
}
