import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../../services/services/request";
import { setItem } from "../../../utils/LocalStorageFunctions";

// Initial state for login
const initialState = {
  user: null,
  loading: false,
  error: null,
  emailPhoneError: "",
  passwordError: "",
};

// Async thunk to handle the login request
export const LoginFunction = createAsyncThunk(
  "login/loginFunction",
  async (body, { rejectWithValue }) => {
    const payload = {
      username: body.username,
      password: body.password,
      authMethod: "email", // Assuming you want to use email as the auth method
      verificationType: "password",
      deviceType: "web",
    };

    try {
      // Call your API with postData, passing the payload
      const response = await postData("/auths/login", payload);
      
      return response.data; // Return the data, which will be handled in the fulfilled case
    } catch (error) {
      const errorResponse = error.response?.data;
      return rejectWithValue(errorResponse);
    }
  }
);

// Slice to handle login logic
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmailPhoneError: (state, action) => {
      state.emailPhoneError = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    clearErrors: (state) => {
      state.emailPhoneError = "";
      state.passwordError = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload; // Assuming you get the user data on success

        // Save the token and other relevant data in localStorage after successful login
        setItem("token", action.payload.session.accessToken); // Save the token
        setItem("UserId", action.payload.id);
        setItem("Name", action.payload.fullName);
        setItem("Img", action.payload.imgUrl);
      })
      .addCase(LoginFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error response from API
      });
  },
});

// Export actions and reducer
export const {
  setEmailPhoneError,
  setPasswordError,
  clearErrors,
} = loginSlice.actions;
export default loginSlice.reducer;
