import React, { useEffect, useState } from "react";
import logo1 from "../assets/images/care-simply-membership.png";
import logo2 from "../assets/images/logo  22.svg";
import { Link } from "react-router-dom";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
function Membership() {
  const [Name, setName] = useState("");
  const [nameError, setnameError] = useState("");
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState("");
  useEffect(() => {
    setemailError("");
    setnameError("");
  }, [Name, email]);
  const submitHandler = () => {
    if (!Name) {
      return setnameError("Name is required");
    }
    if (!email) {
      return setemailError("Email is required");
    }
    if (!email.includes("@")) {
      return setemailError("Enter a valid email");
    }
    const details = { name: Name, email: email };
    console.log("Details", details);
    setName("");
    setemail("");
  };
  return (
    <>
      <div
        className="flex justify-center items-center h-screen w-full mx-auto"
        style={{ backgroundImage: `url(${caresimply})` }}
      >
        <div className="flex justify-center items-center max-w-5xl w-full">
          {/* Image Section */}
          <div className="w-[50%] flex justify-end">
            <img
              src={logo1}
              className="h-[570px] w-[415px]"
              alt="Forgot Password Illustration"
            />
          </div>
          {/* Form Section */}
          <div className="w-[50%] bg-[#F5F7F9]">
            <div className="flex flex-col h-[570px] justify-center pl-[25px]">
              <div className="flex justify-center">
                <img src={logo2} className="" />
              </div>
              <h1 className="font-semibold text-3xl text-center">
                Get Membership
              </h1>
              {/* Email Input */}
              <div className="flex flex-col w-full mt-4">
                <label
                  htmlFor="emailOrPhone"
                  className="block text-gray-700 font-bold text-xl"
                >
                  Name
                </label>
                <input
                  type="text"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  className="px-4 py-2 border border-gray-300 rounded-md mt-2 w-full max-w-[470px]"
                  placeholder="Name"
                />
                <p className="text-red-500 text-[12px]">{nameError}</p>
              </div>
              <div className="flex flex-col w-full mt-4">
                <label
                  htmlFor="emailOrPhone"
                  className="block text-gray-700 font-bold text-xl"
                >
                  Email
                </label>
                <input
                  type="Email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  id="email"
                  className="px-4 py-2 border border-gray-300 rounded-md mt-2 w-full max-w-[470px]"
                  placeholder="Email address"
                />{" "}
                <p className="text-red-500 text-[12px]">{emailError}</p>
              </div>
              <div className="flex gap-28 items-center max-w-[500px]">
                <div className="flex items-center mt-2 pb-2">
                  <input
                    type="checkbox"
                    id="checkbox2"
                    className="form-checkbox h-4 w-4
p
text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-200"
                  />
                  <label
                    htmlFor="checkbox2"
                    className="ml-2 text-gray-700"
                  ></label>
                  <p style={{ fontSize: "12px" }}>
                    Terms and conditions agreement should start with an
                    intoduction that lets users know they're reading a terms and
                    conditions agreement
                  </p>
                </div>
              </div>
              {/* Reset Password Button */}
              {/* <Link to="/error"> */}
              <button
                className="bg-[#394E6C] text-white w-full rounded-md p-2 font-semibold mt-6 max-w-[440px] "
                onClick={submitHandler}
              >
                SUBMIT
              </button>
              {/* </Link> */}
              {/* Back to Sign In */}
              <div className="text-center mt-4">
                <a href="#" className="text-gray-600">
                  Do you have account?{" "}
                  <span className="text-black">Sign In</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Membership;
