export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
  const item = localStorage.getItem(key);
  return item ? item : null;
};

export const deleteItem = (key) => {
  localStorage.removeItem(key);
};