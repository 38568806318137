import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteData,
  getData,
  postData,
  updateData,
} from "../services/services/request";
import { getItem, setItem } from "../utils/LocalStorageFunctions";
import logo9 from "../assets/images/red-circle.svg";
import CartEmpty from "../assets/images/cartempty.png";

function CardCheckout() {
  const token = getItem("token");
  const tokens = token.replace(/"/g, "");
  const [cartInfo, setCartInfo] = useState([]);
  const [cartid, setCartid] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Add new function to update quantity in API
  const updateQuantity = async (medicineId, newQuantity) => {
    try {
      if (!token) {
        console.error("Token not found");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const payload = {
        medicineId: medicineId,
        quantity: newQuantity,
      };

      await updateData(`/carts/${cartid}`, payload, config);

      // Update local state after successful API call
      setCartInfo(
        cartInfo.map((item) =>
          item.medicineId._id === medicineId
            ? { ...item, quantity: newQuantity }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  // Update increment and decrement functions
  const incrementQuantity = async (id) => {
    const item = cartInfo.find((item) => item.medicineId._id === id);
    if (item) {
      const newQuantity = item.quantity + 1;
      await updateQuantity(id, newQuantity);
    }
  };

  const decrementQuantity = async (id) => {
    const item = cartInfo.find((item) => item.medicineId._id === id);
    if (item && item.quantity > 1) {
      const newQuantity = item.quantity - 1;
      await updateQuantity(id, newQuantity);
    }
  };

  const GetCartapi = async () => {
    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await getData("/carts/getCart/ByToken", config);
      console.log("HELLO", response.data.items);

      if (response.data && Array.isArray(response.data.items)) {
        setCartInfo(response.data.items);
        setCartid(response.data._id);
      } else {
        console.error(
          "Expected an array of items, but received:",
          response.data
        );
      }
    } catch (error) {
      console.log("Error in ADD to cart", error);
    }
  };

  const handeldelte = async (id) => {
    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
        body: {
          medicineId: id,
        },
      };
      const payload = {
        medicineId: id,
      };
      console.log("PAYload", payload);

      const response = await deleteData(
        `/carts/delete/${cartid}`,
        payload,
        config
      );

      GetCartapi(); // Assuming this function updates the cart view
    } catch (error) {
      console.log("Error in deleting item from cart:", error.message || error);
    }
  };

  const Procedtocheckout = async () => {
    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      let data = [];
      const cartItem = cartInfo.map((item) => {
        if (item.medicineId) {
          data.push({
            medicineId: item.medicineId._id,
            quantity: item.quantity,
          });
        }
      });

      const Payload = {
        data,
      };

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      // Set loading to true before making the API call
      setLoading(true);

      const response = await postData(
        `/orders/proceedToCheckout/${cartid}`,
        Payload,
        config
      );

      const id = response.data._id;

      // Navigate to the next page
      navigate("/shippingpayment", { state: { id } });

      // After the API call, set loading to false
      setLoading(false);
    } catch (error) {
      // If there's an error, set loading to false
      console.log(
        "Error in Proceed to checkout from cart:",
        error.message || error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    GetCartapi();
    console.log("helllo", cartInfo);
  }, []);

  return (
    <>
      <Header />
      <div className="pt-28">
        {/* Conditionally render CartEmpty image when cart is empty */}
        {cartInfo.length === 0 ? (
          <div className="flex justify-center mt-10">
            <img src={CartEmpty} alt="Cart is empty" />
          </div>
        ) : (
          <div className="container mx-auto max-w-[1200px] mb-10 xs:overflow-x-hidden">
            {/* Main Container */}
            <div className="flex lg:flex-row flex-col w-full">
              {/* Shopping Cart Section */}
              <div className="lg:w-[70%] xs:w-full p-5">
                <div className="border-2 pt-4 pb-4">
                  <div className="flex justify-between">
                    <h1 className="font-semibold text-xl ml-2">
                      Shopping Cart
                    </h1>
                  </div>
                  <div className="w-full">
                    <div className="w-full overflow-x-auto">
                      {/* Desktop View */}
                      <table className="hidden md:table min-w-full table-auto border-t-2 border-b-2 p-4 mt-5">
                        <thead>
                          <tr className="bg-[#F1F8FF] h-[46px] border-b-2">
                            <th className="text-left px-5 py-3">Products</th>
                            <th className="text-left">Price</th>
                            <th className="text-left">Quantity</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartInfo.map((item) => (
                            <tr key={item?.medicineId?._id} className="p-4 mt-4">
                              <td className="flex items-center px-6 py-6 gap-2">
                                <button onClick={() => handeldelte(item?.medicineId?._id)}>
                                  <img src={logo9} alt="" className="w-4 h-4" />
                                </button>
                                <img src={item?.medicineId?.image[0]} alt="" className="w-12 h-12 object-cover" />
                                <Link to={`/product/${item?.medicineId?._id}`}>
                                  <h1 className="hover:text-blue-500">{item?.medicineId?.medicineName}</h1>
                                </Link>
                              </td>
                              <td><span className="p-1">$</span>{item?.medicineId?.price}</td>
                              <td>
                                <div className="flex items-center border-2 h-[39px] w-[90px] justify-center gap-4">
                                  <button onClick={() => decrementQuantity(item?.medicineId?._id)} 
                                    className="text-gray-500 hover:text-gray-700 disabled:opacity-50" 
                                    disabled={item.quantity <= 1}>-</button>
                                  <span>{item.quantity}</span>
                                  <button onClick={() => incrementQuantity(item?.medicineId?._id)} 
                                    className="text-gray-500 hover:text-gray-700">+</button>
                                </div>
                              </td>
                              <td className="text-center font-semibold">
                                <span className="p-1">$</span>
                                <span>{(item.quantity * item?.medicineId?.price).toFixed(2)}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Mobile View */}
                      <div className="md:hidden space-y-4">
                        {cartInfo.map((item) => (
                          <div key={item?.medicineId?._id} className="bg-white p-4 rounded-lg shadow">
                            <div className="flex items-center gap-4 mb-3">
                              <button onClick={() => handeldelte(item?.medicineId?._id)} 
                                className="text-red-500">
                                <img src={logo9} alt="" className="w-4 h-4" />
                              </button>
                              <img src={item?.medicineId?.image[0]} alt="" 
                                className="w-16 h-16 object-cover rounded" />
                              <div className="flex-1">
                                <Link to={`/product/${item?.medicineId?._id}`}>
                                  <h1 className="font-medium hover:text-blue-500">
                                    {item?.medicineId?.medicineName}
                                  </h1>
                                </Link>
                              </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4 text-sm">
                              <div>
                                <p className="text-gray-500">Price</p>
                                <p className="font-semibold">
                                  <span className="p-1">$</span>{item?.medicineId?.price}
                                </p>
                              </div>

                              <div>
                                <p className="text-gray-500">Subtotal</p>
                                <p className="font-semibold">
                                  <span className="p-1">$</span>
                                  {(item.quantity * item?.medicineId?.price).toFixed(2)}
                                </p>
                              </div>

                              <div className="col-span-2">
                                <p className="text-gray-500 mb-2">Quantity</p>
                                <div className="flex items-center border-2 h-[39px] w-[90px] justify-center gap-4">
                                  <button onClick={() => decrementQuantity(item?.medicineId?._id)}
                                    className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
                                    disabled={item.quantity <= 1}>-</button>
                                  <span>{item.quantity}</span>
                                  <button onClick={() => incrementQuantity(item?.medicineId?._id)}
                                    className="text-gray-500 hover:text-gray-700">+</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[8px]">
                    <div>
                      <div className="mt-2">
                        <Link to="/">
                          <button className="ml-2 p-2 lg:w-[197px] md:w-[240px] font-semibold border-2 border-blue-400 text-blue-400 flex justify-center gap-2">
                            RETURN TO SHOP
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cart Totals Section */}
              <div className="md:w-[50%] w-screen  md:mx-auto md:justify-center overflow-hidden overflow-x-hidden border-2 h-[200px] mt-[20px]">
                <div className="rounded-md borderxyz-2  p-5 md:p-5">
                  <h1 className="font-semibold">Cart Totals</h1>
                  <div className="border-b-2">
                    <div className="flex justify-between">
                      <span className="text-gray-400 font-semibold">
                        Subtotal
                      </span>
                      <p className="font-semibold">
                        <span className="p-1">$</span>
                        {cartInfo
                          .reduce(
                            (acc, item) =>
                              acc + item?.medicineId?.price * item.quantity,
                            0
                          )
                          .toFixed(2)}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between text-xl font-semibold mt-3">
                    <span>Total</span>
                    <p>
                      <span className="p-1">$</span>
                      {cartInfo
                        .reduce(
                          (acc, item) =>
                            acc + item?.medicineId?.price * item.quantity,
                          0
                        )
                        .toFixed(2)}
                    </p>
                  </div>

                  <div onClick={Procedtocheckout}>
                    <button
                      className="border-gray-300 p-2 font-semibold bg-[#394E6C] text-white flex justify-center lg:w-[453px] md:w-[300px] w-[350px] mt-4"
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="flex items-center">
                          <div className="spinner-border animate-spin border-2 border-t-2 border-white w-6 h-6 mr-2"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        "PROCEED TO CHECKOUT"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default CardCheckout;
