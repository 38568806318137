import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "@mui/material/Rating";
import { getData } from "../services/services/request";
import Skeleton from "@mui/material/Skeleton";

export default function Pamper() {
  const sliderRef = useRef(null);
  const [value, setValue] = useState(0);
  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1200, // Set autoplay interval (3 seconds)
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const scrollPrev = () => {
    sliderRef.current.slickPrev();
  };
  const scrollNext = () => {
    sliderRef.current.slickNext();
  };
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const SearchMedicine = async () => {
    const pageNo = 1000;
    try {
      setLoading(true); // Start loading when API call starts
      const response = await getData(`/medicines?pageSize=${pageNo}`);

      const data = response.items;
      const ratings = data.flatMap((med) =>
        med.ratings.map((rating) => rating.rating)
      );
      if (ratings.length > 0) {
        setValue(ratings[0]); // Set the state to the first rating value
      }

      if (Array.isArray(data)) {
        setProducts(data);
      } else {
        console.error("Received data is not an array:", data);
      }

      setLoading(false); // Set loading to false when API data is received
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    SearchMedicine();
  }, []);

  return (
    <>
      <div className="py-5">
        <div className="container mx-auto max-w-[1200px] ">
          <div className="relative flex items-center justify-center">
            <div className="text-[75px] opacity-15 text-blue-800 xs:text-[25px] uppercase text-center sm:text-[40px] md:text-[50px] lg:text-[75px]">
              Products Customer Rating
            </div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[35px] xs:text-[12px] uppercase text-center whitespace-nowrap sm:text-[8px] md:text-[25px] lg:text-[35px]">
              Our Health Care Products & Customer Rating
            </div>
          </div>

          {/* Header Section */}
          <div className="flex text-center items-center justify-between">
            <div>
              <h1 className="text-[12px] xs:pl-4 sm:text-xl md:text-2xl lg:text-3xl font-normal text-[#304F5E]">
                Trending Today & Customer Rating
              </h1>
            </div>
            <div className="flex gap-2">
              {/* Left Button */}
              <button
                onClick={scrollPrev}
                className="rounded-full p-3 h-[40px] flex bg-gray-200 hover:bg-[#4CAF50]"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              {/* Right Button */}
              <button
                onClick={scrollNext}
                className="rounded-full p-3 h-[40px] flex bg-gray-200 hover:bg-[#4CAF50]"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
          {/* Product Cards Section */}
          <div>
            {loading ? (
              <div className="flex justify-center items-center gap-5">
                <div className=" w-full">
                  <Skeleton height={300} />
                  <Skeleton width="60%" />
                </div>
                <div className=" w-full">
                  <Skeleton height={300} />
                  <Skeleton width="60%" />
                </div>
                <div className=" w-full">
                  <Skeleton height={300} />
                  <Skeleton width="60%" />
                </div>
              </div>
            ) : (
              <Slider ref={sliderRef} {...setting}>
                {products.map((product) => (
                  <div
                    class="card1 p-3 relative rounded-lg mt-4 lg:w-[32%] md:w-[80%] sm:w-[95%] mx-auto group "
                    key={product.id}
                  >
                    <div class="card1-inner h-[400px] ">
                      <div class="card1-front">
                        <Link
                          to={`/productdetails/${product.id}`}
                          rel="noopener noreferrer"
                        >
                          <div className="relative rounded-md  h-[373px] w-[265px] transition-all">
                            {/* Image */}
                            <div className="relative">
                              <img
                                src={product.image[0]}
                                alt={product.name}
                                className="h-[260px] w-[340px] object-fill"
                              />
                            </div>

                            <div className=" ">
                              <div className="flex justify-center">
                                <h1 className="font-bold text-lg text-truncate">
                                  {product.medicineName}
                                </h1>
                              </div>
                              <span className=" flex justify-center ">
                                <span className="p-1 rounded-md border-2 font-bold px-8 py-2 mt-3 mb-2 ">
                                  ${product.price}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div class="card1-back">
                        {" "}
                        <Link
                          to={`/productdetails/${product.id}`}
                          rel="noopener noreferrer"
                        >
                          <div className="relative rounded-md h-[373px] w-[272px] transition-all ">
                            <div className="absolute inset-0 rounded-md flex items-center bg-black bg-opacity-50 justify-center opacity-10 group-hover:opacity-100 transition-opacity">
                              <span className="text-white text-lg font-semibold">
                                Show more
                              </span>
                            </div>
                            <div className="p-4  w-full h-full">
                              <div className="flex justify-center px-2">
                                <h1 className="font-bold text-lg px-3">
                                  {product.medicineName}
                                </h1>
                              </div>
                              <span className="text-sm flex justify-center text-justify  w-[200px] h-[182px] overflow-hidden">
                                {product.medicineDetails}
                              </span>
                              <h1 className="flex  justify-center mt-2">
                                <span className="text-yellow-400 text-lg font-bold">
                                  <Rating
                                    name="read-only"
                                    value={value}
                                    readOnly
                                  />
                                </span>
                              </h1>
                              <span className="flex justify-center ">
                                <span className="font-bold px-8 py-2 mb-2 rounded-md border-2 p-1">
                                  ${product.price}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
