import React, { useEffect, useRef, useState } from "react";
import logo9 from "../assets/images/Stars-rating.svg";
import logo10 from "../assets/images/rating-one-icon.svg";
import logo11 from "../assets/images/two-rating-icon.svg";
import logo12 from "../assets/images/third-rating-icon.svg";
import logo13 from "../assets/images/four-ratings-icon.svg";
import logo14 from "../assets/images/four-ratings-icon.svg";
import Rating from "@mui/material/Rating";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../services/services/request";

function Reviews({ medId }) {
  const [rating, setRating] = useState(0);
  const [reviewData, setReviewData] = useState();

  const Productget = async () => {
    try {
      const response = await getData(`/ratings?medicineId=${medId}`);
      setReviewData(response.items);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    Productget();
  }, [medId]);
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  return (
    <div className="flex flex-col md:flex-row gap-5 mt-5">
      {/* Left Section */}
      <div className="w-full px-5">
        <div className="flex flex-col">
          <h1 className="font-semibold">Reviews </h1>
          <div className="mt-5">
            {reviewData && reviewData.length > 0 ? (
              reviewData.map((review, index) => (
                <div key={index} className="py-2">
                  <div className="flex gap-3 pb-2">
                    <div className="h-10 ">
                      <img
                        src={review?.userId?.imgUrl}
                        className="h-full w-full rounded-[20px]"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <p className="font-semibold pt-2">
                      {review?.userId?.fullName}
                    </p>
                  </div>
                  <div className="flex justify-betweenw-full gap-6">
                    <p>{review.review}</p>
                    <Rating
                      name={`read-only-rating-${index}`}
                      value={review.rating}
                      readOnly
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No reviews available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
