import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo1 from "../assets/images/special-picks-image.jpg";
import logo2 from "../assets/images/Vector.jpg";
import logo3 from "../assets/images/send-icon.jpg";
import logo35 from "../assets/images/nivea-cream-image.jpg";
import logo36 from "../assets/images/hair-spray-image.jpg";
import logo37 from "../assets/images/baby-johnson-image.jpg";
import logo38 from "../assets/images/honey-images.jpg";
import logo39 from "../assets/images/green-search.svg";
import logo40 from "../assets/images/double-cross.svg";
import logo41 from "../assets/images/mini-heart.svg";
import logo42 from "../assets/images/miini-baskit.svg";
import logo43 from "../assets/images/quick-view.png";
import logo44 from "../assets/images/reload-Vector.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import SideNavFilter from "../components/SideNavFilter";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getItem } from "../utils/LocalStorageFunctions";
import { getData } from "../services/services/request";
import Skeleton from "@mui/material/Skeleton";

export default function Analgesics() {
  const { id } = useParams();
  const [categoriess, setcategoriess] = useState("");
  const [idvalue, setIdvalue] = useState("");
  const [products, setProducts] = useState([]);
  const [showNav, setshowNav] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  const [imgarry, setImgarry] = useState();
  const BannerAPI = async () => {
    try {
      const response = await getData(
        "/banners?placement=category_page&type=web&status=active"
      );
      const data = response.items;

      if (Array.isArray(data) && data.length > 0) {
        const imageUrl = data[0].imageUrl;
        setImgarry(imageUrl); // Assuming setImgarry is used to set the image URL
        console.log("IMG", imageUrl);
      } else {
        console.error("No valid banners found or data is not an array");
        setImgarry(""); // Set to empty string if no valid image URL found
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  useEffect(() => {
    BannerAPI();
  }, []);
  const medicineget = async () => {
    try {
      setLoading(true); // Start loading when the request is made
      const token = getItem("token");
      const response = await getData(
        `/medicines?categoryId=${id}&&subCategoryId=${idvalue}&&companyId=${selectedCompany}`
      );
      setProducts(response.items);
      setLoading(false); // End loading after the response
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    medicineget();
  }, [id, idvalue, selectedCompany]);

  return (
    <>
      <Header />
      <div className="pt-16">
        <div className="w-full relative">
          {loading ? (
            <div className="flex justify-center items-center w-full h-[300px]">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="w-full h-auto mt-5">
              {imgarry ? (
                <img
                  src={imgarry}
                  alt="Banner"
                  className="w-full h-auto object-cover xs:h-[200px] sm:h-[250px] md:h-[350px] lg:h-[400px]"
                />
              ) : (
                <div>
                  <Skeleton variant="rounded" height={300} />
                </div>
              )}
            </div>
          )}
        </div>
        {/* <div className="lg:flex justify-start items-start absolute top-[100px] ">
        <button className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] ">
          <Link to="/">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </Link>
        </button>
      </div> */}
      </div>

      <div className="lg:hidden">
        <button
          onClick={() => {
            setshowNav(!showNav);
          }}
        >
          <div className="flex gap-3 items-center text-start xs:pt-8 xs:pl-8">
            <div>
              <img src={logo2} />
            </div>
            <div>
              <h1>All Categories</h1>
            </div>
            <div className="px-4">
              <button onClick={() => setcategoriess("")}>
                <img src={logo44} />
              </button>
            </div>
            {showNav ? (
              <FontAwesomeIcon
                icon={faCaretUp}
                style={{ color: "black", fontSize: "20px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{ color: "black", fontSize: "20px" }}
              />
            )}
          </div>
        </button>
        {showNav ? (
          <SideNavFilter
            setcategoriess={setcategoriess}
            categoriess={categoriess}
            setIdvalue={setIdvalue} // Pass setIdvalue function to the child component
            setSelectedCompany={setSelectedCompany} // Pass setSelectedCompany function to the child component
            idvalue={idvalue} // Pass idvalue state to the child component
            selectedCompany={selectedCompany}
          />
        ) : null}
      </div>

      <div className="container mx-auto max-w-[1400px] flex">
        <div className="hidden lg:block">
          <SideNavFilter
            setcategoriess={setcategoriess}
            categoriess={categoriess}
            setIdvalue={setIdvalue} // Pass setIdvalue function to the child component
            setSelectedCompany={setSelectedCompany} // Pass setSelectedCompany function to the child component
            idvalue={idvalue} // Pass idvalue state to the child component
            selectedCompany={selectedCompany}
          />
        </div>

        <div className="lg:w-[80%] mx-auto mt-5 lg:border-l-2">
          {/* Loading State */}
          {loading ? (
            <div className="flex justify-center items-center gap-5 px-10">
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 xs:px-3 md:px-4 md:gap-4 lg:grid-cols-3 gap-4 lg:ml-4 place-content-center place-items-center">
              {products
                .filter(
                  (product) =>
                    categoriess === "" || product.categories === categoriess
                )
                .map((product) => (
                  <div key={product.id} className="relative">
                    <Link to={`/productdetails/${product.id}`}>
                      <div className="border-2 w-[100%]  max-w-[285px] rounded-md shadow-md group hover:shadow-xl hover:scale-105  p-4">
                        <div className="md:h-[220px] md:w-[260px] xs:h-[180px] xs:w-[280px]">
                          <img
                            src={product.image[0]}
                            alt={product.name}
                            className=" w-full h-full object-contain"
                          />
                        </div>
                        <div className="p-4 xs:pt-0">
                          <div className="flex items-center justify-between">
                            <h1 className="font-bold text-xl text-[#008080]  my-2 text-truncate">
                              {product.medicineName}
                            </h1>
                          </div>
                          <h1 className="flex flex-col mt-2">
                            <div className="flex items-center gap-2 mb-2">
                              <span className="text-black font-bold text-lg">
                                <span className="p-1">$</span>
                                {product.price}
                              </span>
                            </div>
                          </h1>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
