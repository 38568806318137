import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo24 from "../assets/images/add-to-cart-baskit.svg";
import { Link, useNavigate } from "react-router-dom";
import Recomendation from "./Recomendation";
import { useParams } from "react-router-dom";
import { getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Reviews from "./Reviews";
import Usecartlogic from "./Hooks/usecartlogic";
// import { useHistory } from "react-router-dom";

function ProductDetails() {
  const { Pid } = useParams();
  // const history = useHistory();
  const [medId, setMedId] = useState([]);
  const [imgary, setImgary] = useState([]);
  const [productitem, setProuctitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState(null);
  const userId = JSON.parse(getItem("UserId"));
  const token = getItem("token");
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [hoveredImage, setHoveredImage] = useState(null);
  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Productget = async () => {
    try {
      const response = await getData(`/medicines/${Pid}`);
      setProuctitem(response.data);
      setImgary(response.data.image);
      setMedId(Pid);
      // console.log("IMG",response.data.image)
    } catch (error) {}
  };
  const memberShipData = async () => {
    try {
      const tokens = token.replace(/"/g, "");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(
        `userMemberships?userId=${userId}&&status=active`,
        config
      );

      if (response && response.items) {
        setMembership(response.items);
        // console.log("Data", response.items);

        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Productget();
    memberShipData();
  }, [Pid]);

  const handleBuyNow = () => {
    const token = getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      Buyapi();
      navigate("/cardcheckout");
    }
  };
  const { items, Cartdata } = Usecartlogic();

  const handleUPDateNow = () => {
    const token = getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      Buyapi();
      navigate(-1);
      Cartdata();
    }
  };
  const handleMembership = () => {
    const token = getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      navigate("/premiummembership  ");
    }
  };

  const payload = {
    item: [
      {
        medicineId: Pid,
        quantity: quantity,
      },
    ],
  };

  const Buyapi = async () => {
    try {
      const token = getItem("token");
      const tokens = token.replace(/"/g, "");
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await postData("/carts/addToCart", payload, config);
      console.log("CARt data", response);
    } catch (error) {
      console.log("Errorin ADD to cart", error);
    }
  };

  return (
    <>
      <Header />

      <div className="overflow-x-hidden pt-24">
        <div className="container mx-auto  max-w-[1200px] mb-10">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] "
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </button>
          <div className="flex flex-col lg:flex-row gap-4 mt-5">
            {/* Images Section */}
            <div className="flex flex-row gap-4">
              {/* Thumbnails */}
              <div className="flex flex-col gap-4 py-4 max-h-[400px] w-[60%] overflow-auto  h-full hide-scrollbarr">
                {imgary.map((imageUrl, index) => (
                  <div
                    key={index}
                    className="border-2 py-5 w-[70px] sm:w-[90px] md:w-[100px]"
                    onMouseEnter={() => setHoveredImage(imageUrl)}
                    onMouseLeave={() => setHoveredImage(null)}
                  >
                    <img
                      src={imageUrl}
                      className="h-[60px] sm:h-[70px] md:w-[90px] sm:w-[80px] w-[60px]"
                      alt={`thumbnail-${index}`}
                    />
                  </div>
                ))}
              </div>

              {/* Main Image */}
              <div>
                <img
                  src={hoveredImage || imgary[0]} // Show hovered image or default to the first image in the array
                  className="w-[300px] sm:w-[400px] md:w-[500px] max-h-[400px]"
                  alt="main product"
                />
              </div>
            </div>

            {/* Content Section */}
            <div className="flex flex-col lg:w-full w-full mt-5 px-4  md:w-full">
              {/* Product Name and Price */}
              <div>
                {/* Product Name and Price */}
                <h1 className="text-[#304F5E] mt-8">
                  {productitem?.medicineName || "Product Name"}
                </h1>
                <p>{productitem?.price ? `$${productitem.price}` : "$0.00"}</p>

                {/* Shipping Details */}
                <div className="flex flex-wrap gap-5 mt-5">
                  <h1 className="text-[#40a0a0]">Shipping</h1>
                  <div className="flex flex-col">
                    <h1 className="font-medium">
                      {productitem?.shipping?.territory
                        ? `Free Shipping to ${productitem.shipping.territory}`
                        : "Free Shipping"}
                    </h1>
                    <div className="flex gap-2">
                      <p className="font-medium">
                        Delivery Time:{" "}
                        {productitem?.shipping?.deliveryTime || "14-17 days"}
                      </p>
                      <div>
                        {membership && membership.length > 0 ? null : (
                          <button onClick={handleMembership}>
                            <p className="text-[#ffac60] font-medium">
                              [Get delivery in 2 or 3 days with caresimplicity
                              plus]
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Quantity Selector */}
                <div className="flex flex-wrap items-center gap-5 mt-5">
                  <p className="text-[#40a0a0] font-medium">Quantity</p>
                  <div className="border-2 h-[39px] w-[90px] flex justify-center rounded-md border-[#555555]">
                    <div className="flex items-center gap-2 text-[#555555]">
                      <button onClick={decrement}>-</button>
                      <span className="text-black">
                        {quantity.toString().padStart(2, "0")}
                      </span>
                      <button onClick={increment}>+</button>
                    </div>
                  </div>
                  <p className="font-medium text-[#555555]">
                    {productitem?.stock || 0} available
                    {/* {productitem?.sold || 0} sold */}
                  </p>
                </div>
              </div>

              {/* Buttons Section */}
              <div className="flex  lg:gap-5 md:gap-5 gap-2 mt-5 px-0">
                {/* <Link to="/cardcheckout"> */}
                <button
                  className="border-gray-300 w-[150px] md:w-[400px] p-1 font-semibold bg-[#3FBAD9] text-white rounded-md lg:w-[350px] "
                  onClick={handleBuyNow}
                >
                  BUY NOW
                </button>
                {/* </Link> */}
                <button onClick={handleUPDateNow}>
                  <button className="p-1 w-[200px]  md:w-[400px] font-semibold border-2 border-[#394e6c] rounded-md flex justify-center gap-4 lg:w-[320px] ">
                    <img src={logo24} alt="" />
                    ADD TO CART
                  </button>
                </button>
              </div>
            </div>
          </div>
          {/* Product Details and Review Section */}
          <div className=" flex justify-center">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Review" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {productitem?.medicineDetails || "Details "}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Reviews medId={medId} />
              </CustomTabPanel>
            </Box>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-center mb-10">
            <h1 className="relative text-xl font-bold text-center text-gray-800">
              {/* <div className="flex">
                {showdis ? (
                  <>
                    <img src={logo9} />
                    <button onClick={() => setshowdis(false)}>
                      <span className="relative before:content-[''] before:absolute before:left-[-540px] before:top-1/2 before:w-[520px] before:h-[2px] before:border-b-2 before:-translate-y-1/2 after:content-[''] after:absolute after:right-[-510px] after:top-1/2 after:w-[500px] after:h-[2px] after:border-b-2 after:-translate-y-1/2">
                        SHOW LESS
                      </span>
                    </button>
                  </>
                ) : (
                  <>
                    <img src={logo161} className="" />
                    <button onClick={() => setshowdis(true)}>
                      <span className="relative before:content-[''] before:absolute before:left-[-540px] before:top-1/2 before:w-[520px] before:h-[2px] before:border-b-2 before:-translate-y-1/2 after:content-[''] after:absolute after:right-[-510px] after:top-1/2 after:w-[500px] after:h-[2px] after:border-b-2 after:-translate-y-1/2">
                        SHOW MORE
                      </span>
                    </button>
                  </>
                )}
              </div> */}
            </h1>
          </div>
          {/* <ShowMore /> */}
          <Recomendation />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProductDetails;
