import React, { useEffect, useState } from "react";
import logo2 from "../assets/images/Vector.jpg";

import logo43 from "../assets/images/reload-Vector.svg";
import { useParams } from "react-router-dom";

import { getData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";

const SideNavFilter = ({
  setIdvalue,
  setSelectedCompany,
  idvalue,
  selectedCompany,
}) => {
  const { id } = useParams();
  const pageNo = 1000;
  const [name, setName] = useState();
  const [logo, setLogo] = useState([]);
  const [startctivateIndex, setStartactivateIndex] = useState(null);

  const startctivateAccordion = (index) => {
    setStartactivateIndex(startctivateIndex === index ? null : index);
  };

  const [activateIndex, setStartActivateIndex] = useState(null);

  const startActivateAccordion = (index) => {
    setStartActivateIndex(activateIndex === index ? null : index);
  };
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getData(`/categories/${id}?pageSize=${pageNo}`);
      // console.log(" Category respose", response);
      const dats = response.data.subCategory || [];
      // console.log("jsjdjdad", response.data.subCategory);
      setName(response.data.name);
      setData(dats);

      return data.items;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Topcompanies = async () => {
    try {
      const token = getItem("token");
      const response = await getData(`/companies/?pageSize=${pageNo}`);
      // console.log(" api data", response);
      const data = response.items;
      setLogo(data);
      // console.log("there is logo data ", logo);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
    Topcompanies();
  }, [id]);

  return (
    <div>
      <div className="flex">
        <div className=" xs:w-screen lg:w-[100%] mt-5 xs:pb-10 xs:pr-10 xs:pl-10  xs:border-2 lg:border-0 mx-2 border-gray-100 lg:px-5   ">
          <div className="flex  gap-3 items-center text-start  xs:hidden lg:flex">
            <div>
              <img src={logo2} alt="uuu" />
            </div>
            <div>
              <h1 className="font-semibold ">All Categories</h1>
            </div>
            <div className="pl-4">
              <button
                onClick={() => window.location.reload()}
                className="p-2 rounded-full "
              >
                <img src={logo43} alt="Logo" className="w-[18px] h-[18px]" />
              </button>
            </div>
          </div>
          {/* <div className=""> */}
          <div className="relative pr-7 py-2 border-b-2">
            {/* <div className="flex  items-center">
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder="Search"
                className="p-2 mt-10 w-full border-2 rounded-md mb-5"
              />
            </div> */}
            {/* <div className="border-b-2 mt-2">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute right-[40px] pointer-events-none top-[55px] text-gray-300 "
              />
            </div> */}
          </div>
          {/* mom & baby */}
          <div className="mt-5">
            <div className="">
              <button
                className="flex justify-between  w-[90%]"
                onClick={() => startctivateAccordion(1)}
              >
                {/* <span className="font-semibold text-xl  text-[#4caf50]"> */}
                <span className="font-semibold text-xl  text-[#3FBAD9] text-truncate">
                  {name}
                </span>
                <svg
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-300 ${
                    startctivateIndex === 1 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {startctivateIndex === 1 && (
                <div className="text-gray-600 mt-3">
                  <div>
                    {Array.isArray(data) && data.length > 0 ? (
                      data.map((med) => (
                        <div
                          className={`flex items-center mt-2 ${
                            med._id === idvalue ? "bg-blue-100 rounded-lg" : ""
                          }`}
                          key={med.id}
                          onClick={() => setIdvalue(med._id)}
                        >
                          <label
                            htmlFor={`checkbox${med.id}`}
                            className="ml-2 text-gray-700"
                          >
                            {med.name}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* brand section */}
          <div className="mt-4">
            <div className="">
              {" "}
              <button
                className="flex justify-between gap-10 w-[90%]"
                onClick={() => startActivateAccordion(1)}
              >
                <span className="font-semibold text-[#3FBAD9] text-xl">
                  Company
                </span>
                <svg
                  className={`h-5 w-5 text-gray-500 transform transition-transform duration-300 ${
                    activateIndex === 1 ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activateIndex === 1 && (
                <div className="text-gray-600 mt-3">
                  {logo.map((item, index) => (
                    <div
                      className={`flex items-center mt-2 ${
                        item.id === selectedCompany
                          ? "bg-blue-100 rounded-lg"
                          : ""
                      }`}
                      key={index}
                      onClick={() => setSelectedCompany(item.id)}
                    >
                      <label htmlFor={item.id} className="ml-2 text-gray-700">
                        {item.companyName}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavFilter;
