import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../assets/images/image-signIn.png";
import logo9 from "../assets/images/logo-simple.png";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getData, postData } from "../services/services/request";
import { setItem } from "../Lib/localStorage/localStorage";
import Cookies from "js-cookie";

export default function Login() {
  const [username, setemailPhone] = useState(""); // Phone number input
  const [countryCode, setCountryCode] = useState(""); // Country code input
  const [password, setPassword] = useState("");
  const [emailPhonEerror, setemailPhoneError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // Track the "Remember me" checkbox state
  const [authMethod, setAuthMethod] = useState("email");
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchCode, setSearchCode] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const savedAuthMethod = Cookies.get("authMethod");

    if (savedUsername && savedPassword) {
      setemailPhone(savedUsername);
      setPassword(savedPassword);
      if (savedAuthMethod) {
        setAuthMethod(savedAuthMethod);
      }
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    setemailPhoneError("");
    setpasswordError("");
  }, [username, password]);

  const Countrycode = async () => {
    try {
      const countriescode = await getData("/auths/countries/Pin");

      // Ensure the response contains valid data
      if (Array.isArray(countriescode.data)) {
        setCountryCode(countriescode.data);
      } else {
        setCountryCode([]); // Fallback in case data is not in the expected format
      }

      countriescode.data.forEach((country) => {
        // console.log(country.dial_code, "KKKKKKKKKKKKKKKKKKKK");
      });

      return countriescode;
    } catch (error) {
      console.error("Error fetching country code data:", error);
      setCountryCode([]); // If an error occurs, ensure `countryCode` is an empty array
      return null;
    }
  };

  const validateInputs = () => {
    let isValid = true;
    const phoneRegex = /^\d{10}$/; // Matches exactly 10 digits
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (!username) {
      setemailPhoneError("Phone number is required");
      isValid = false;
    } else if (!phoneRegex.test(username)) {
      setemailPhoneError("Please enter a valid phone number");
      isValid = false;
    }

    if (!selectedCountryCode) {
      setemailPhoneError("Country code is required");
      isValid = false;
    }

    if (!password) {
      setpasswordError("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const isNumeric = (value) => {
    return /^\d*$/.test(value);
  };

  const handleUsernameChange = (e) => {
    const { name, value } = e.target;

    // Allow only numeric values for phone number
    if (name === "phone" && !isNumeric(value)) {
      return;
    }

    // Limit phone number length (optional)
    if (value.length <= 10) {
      setemailPhone(value);
    }

    // Reset phone error if input is valid
    if (emailPhonEerror) {
      setemailPhoneError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError) {
      setpasswordError("");
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const response = await postData("/auths/login", {
        username: username, // Phone number
        countryCode: selectedCountryCode, // Country code
        password,
        authMethod: "phone",
        verificationType: "password",
        deviceType: "web",
      });

      setItem("token", response?.data.session.accessToken);
      setItem("UserId", response?.data.id);

      // Save username, password, and authMethod in cookies if "Remember me" is checked
      if (rememberMe) {
        Cookies.set("username", username, { expires: 30 });
        Cookies.set("password", password, { expires: 30 });
        Cookies.set("authMethod", authMethod, { expires: 30 });
      } else {
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("authMethod");
      }

      navigate("/");
    } catch (error) {
      let errorMessage = "Server offline ";

      if (error?.response) {
        const statusCode = error?.response?.status;
        if (statusCode === 401) {
          errorMessage = "Unauthorized access. Please check your credentials.";
        } else if (statusCode === 402) {
          errorMessage = "Payment required. Please check your account.";
        } else if (statusCode === 403) {
          errorMessage =
            "Forbidden. You do not have permission to access this resource.";
        } else {
          errorMessage = error?.response?.data?.message || errorMessage;
        }
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const handleCodeSearch = (value) => {
    setSearchCode(value);
    setIsDropdownOpen(true);

    // Show all codes when input is empty
    if (!value.trim()) {
      setFilteredCountryCodes(countryCode);
      return;
    }

    const filtered = countryCode.filter(
      (country) =>
        country.dial_code.includes(value) ||
        country.code.toLowerCase().includes(value.toLowerCase()) ||
        country.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredCountryCodes(filtered);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountryCode(country.dial_code);
    setSearchCode(country.dial_code);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    Countrycode();
  }, []);

  return (
    <div
      className="flex justify-center h-screen items-center py-10 w-full mx-auto bg-cover bg-center"
      style={{ backgroundImage: `url(${caresimply})` }}
    >
      <div className="flex flex-col lg:h-[570px] md:h-[570px] sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Left Side Image */}
        <div className="sm:h-full pt-52 md:h-full lg:pt-0 md:pt-0">
          <img
            src={logo1}
            className="h-[500px] w-[450px] object-cover"
            alt="Logo"
          />
        </div>

        {/* Right Side Form */}
        <div className="w-full sm:w-1/2 bg-[#F5F7F9] p-6 md:p-10 lg:p-12">
          <Link to="/">
            <div className="flex justify-center h-[100px]">
              <img src={logo9} alt="Company Logo" />
            </div>
          </Link>

          <div className="text-left">
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
              Welcome <span className="text-[#AEDFF7]">Back</span>
            </h1>
            <p className="text-[#5BACAD] font-semibold mt-2">
              Please SignIn to Continue
            </p>
          </div>

          <form onSubmit={submitHandler}>
            <div>
              {/* Phone number and country code input */}
              <div className="flex flex-col w-full">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold text-base md:text-lg"
                >
                  Phone Number
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>
                <div className="flex border rounded-md">
                  <div className="relative" ref={dropdownRef}>
                    <input
                      type="text"
                      value={searchCode}
                      onChange={(e) => handleCodeSearch(e.target.value)}
                      onFocus={() => {
                        setIsDropdownOpen(true);
                        setFilteredCountryCodes(countryCode); // Show all codes on focus
                      }}
                      placeholder="+00"
                      className="w-16 px-2 py-2 border-r rounded-md focus:outline-none"
                    />
                    {isDropdownOpen && (
                      <div className="absolute left-0 top-full mt-1 w-48 max-h-48 overflow-y-auto bg-white border rounded-md shadow-lg z-50">
                        {filteredCountryCodes.map((country) => (
                          <div
                            key={country.code}
                            onClick={() => handleCountrySelect(country)}
                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                          >
                            <span className="font-medium">
                              {country.dial_code}
                            </span>
                            <span className="text-sm text-gray-600">
                              {country.name} ({country.code})
                            </span>
                          </div>
                        ))}
                        {filteredCountryCodes.length === 0 && (
                          <div className="px-3 py-2 text-gray-500">
                            No matches found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    name="phone"
                    value={username}
                    onChange={handleUsernameChange}
                    maxLength="10"
                    className="flex-1 px-4 py-2 focus:outline-none"
                    placeholder="Phone number"
                  />
                </div>
                <p className="text-red-500 mt-1 mb-2 text-md">
                  {emailPhonEerror}
                </p>
              </div>

              {/* Password input */}
              <div className="relative">
                <label className="block text-gray-700 font-bold text-base md:text-lg">
                  Password
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>

                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="block px-4 py-2 border border-gray-300 mt-2 focus:outline-none w-full"
                  placeholder="Password"
                />
                <p className="text-red-500 mb-2 text-[15px]">{passwordError}</p>

                <div className="absolute right-[8px] top-[50px]">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 -top-1 text-[#C4C4C4]"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
            </div>

            {/* Render error message if any */}
            {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}

            {/* Remember Me and Forgot Password */}
            <div className="flex justify-between items-center py-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="checkbox2"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="checkbox2"
                  className="ml-2 text-gray-700 text-sm md:text-base"
                >
                  Remember me
                </label>
              </div>
              <Link
                to="/forgotpassword"
                className="text-sm md:text-base font-semibold"
              >
                Forgot Password
              </Link>
            </div>

            <div>
              <button
                type="submit"
                className="bg-[#394E6C] text-white w-full rounded-md py-2 font-semibold hover:bg-blue-700 transition duration-200"
              >
                {loading ? "Loading ..." : "Sign In"}
              </button>
            </div>
          </form>

          <Link to="/login">
            <button className=" mt-3 border text-[13px] w-full flex  justify-center px-2 gap-28 rounded-md py-2 bg-white transition duration-200">
              Login With Email
            </button>
          </Link>

          {/* Sign Up Link */}
          <div className="text-center py-2 flex gap-1 justify-center">
            <div> Don't have an account?</div>
            <Link to="/signup">
              <span className="text-blue-400 font-semibold">Sign Up</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
