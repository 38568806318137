import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../services/services/request"; // Assuming getData is defined in your service
import { setItem } from "../../../utils/LocalStorageFunctions";

// Initial state for category
const initialState = {
  categories: [],
  loading: false,
  error: null,
  pageNo: 100, // You can manage pagination if needed
};

// Async thunk to handle category data request
// Async thunk to handle category data request
export const fetchCategories = createAsyncThunk(
    "categories/fetchCategories",
    async ({ pageNo }, { rejectWithValue }) => {
      try {
        const response = await getData(`/categories/?pageSize=${pageNo}`);
        // console.log("Fetched Categories:", response.items);  // Debug the response here
        return response.items;  // Return the actual items array
      } catch (error) {
        const errorResponse = error.response?.data || {
          message: "An error occurred",
        };
        return rejectWithValue(errorResponse);
      }
    }
  );
  

// Slice to handle category state
const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    // You can add additional reducers to handle state updates
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;  // Ensure this gets populated
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;  // Make sure error is handled properly
      });
  },
});

// Export actions and reducer
export const { setPageNo } = categorySlice.actions;
export default categorySlice.reducer;
