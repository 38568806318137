import React from "react";
import logo1 from "../assets/images/care-simply-llc-doc-girl.png";
import logo2 from "../assets/images/logo  22.svg";
import logo3 from "../assets/images/green-check.svg";
import logo10 from "../assets/images/venmo-Icon.svg";
import logo11 from "../assets/images/paypal-image .svg";
import logo12 from "../assets/images/amazon-icon-1 1.svg";
import logo13 from "../assets/images/CreditCard.svg";
import logo8 from "../assets/images/CheckCircle.svg";
import logo7 from "../assets/images/Stack.svg";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";

const MembershipNotBought = () => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-slate-200 bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
          <div className="w-20 h-20 bg-red-200 border-4 border-red-600 rounded-full flex items-center justify-center mx-auto mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="w-10 h-10 text-red-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <h1 className="font-semibold text-lg">Something Went Wrong!</h1>
          <p className="text-gray-600 mb-6">
            We're sorry, but there was an issue with your membership purchase.
            Please try again later or contact support for assistance.
          </p>
          {/* <button
                onClick={() => setIsPopupOpen(false)} // Close popup
                className="bg-red-600 text-white py-2 px-4 rounded"
              >
                Close
              </button> */}

          <div className="flex items-center justify-center gap-5 mt-5">
            <div className="relative">
              <Link to="/">
                <button className="flex justify-center border-2 border-gray-300 p-2 w-[200px] font-semibold text-[#394e6c] px-10">
                  GO TO HOME
                </button>
              </Link>

              <div className="absolute right-[160px] top-[52%] transform -translate-y-1/2">
                <img src={logo7} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipNotBought;
