import React, { useState, useEffect } from "react";
import logo1 from "../assets/images/send-icon.jpg";
import logo_3 from "../assets/images/logo_3.png";
import logo53 from "../assets/images/visa-r-images.png";
import logo54 from "../assets/images/mastering-images.png";
import logo55 from "../assets/images/online-pay-pal-images.png";
import logo56 from "../assets/images/instagram.jpg";
import logo57 from "../assets/images/facebook.jpg";
import logo58 from "../assets/images/telegram.jpg";
import ChatbotComponent from "../pages/ChatBot";
import { Link } from "react-router-dom";

function Footer() {
  const [chatBotOpen, setchatBotOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.chatwoot.com/packs/js/sdk.js";
    script.defer = true;
    script.async = true;
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: "YRoPHVZWpMe8dKDh1HfSCp3W",
        baseUrl: "https://app.chatwoot.com",
      });
    };
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer className="relative">
      {/* Email Subscription Section */}
      <div className="container md:mx-auto lg:mx-auto lg:max-w-screen-xl">
        <div className="flex justify-center absolute top-[-108px] left-1/2 transform -translate-x-1/2 w-full md:w-[734px]">
          <div className="bg-[#7296AB] mt-5 text-center md:rounded-md ">
            <div className="p-7">
              <h1 className="text-2xl font-bold text-[#FFFFFF] mb-1">
                CareSimpliCity Plus
                <span className="text-[#D1E2EB] ml-2">Store</span>
              </h1>
              <p className="text-[#FFFFFF] text-xl mb-1">
                Register your email not to miss the last minutes off + Free
                delivery
              </p>
              {/* <div className="relative flex justify-center">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="p-2 mt-2 w-[90%] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 md:w-[334px]"
                />
                <button className="absolute inset-y-0 right-10 top-[10px] flex items-center md:right-[155px]">
                  <img src={logo1} alt="Send Icon" className="w-5 h-5" />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Content */}
      <div
        className="bg-[#AEDFF7] w-full mt-32 flex items-center"
        style={{ paddingTop: "6rem", paddingBottom: "2rem" }}
      >
        <div className="w-[100%] px-10 flex items-center justify-between">
          {/* Logo Section */}
          <div className="pt-5">
            <div>
              <img
                src={logo_3}
                alt="Medical Store Logo"
                className="h-[111px] w-[screen] sm:object-contain lg:object-cover md:object-cover ml-1"
              />
            </div>
          </div>
          {/* Links Section */}
          <div className="flex flex-col justify-start space-y-4">
            <div>
              <ul className="flex flex-col md:flex md:flex-row gap-10">
                <li>
                  <a
                    href="/support"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/support"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="/support"
                    className="text-gray-600 hover:text-blue-500"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Payment Logos Column */}
          <div>
            <ul className="flex md:flex flex-col space-y-4">
              <li>
                <div className="text-gray-500 hover:text-blue-500">
                  <img src={logo53} alt="Visa" className="h-[20px]" />
                </div>
              </li>
              <li>
                <div className="text-gray-500 hover:text-blue-500">
                  <img src={logo54} alt="MasterCard" className="h-[20px]" />
                </div>
              </li>
              <li>
                <div className="text-gray-500 hover:text-blue-500">
                  <img src={logo55} alt="PayPal" className="h-[20px]" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div>
        <div className="max-w-screen mx-auto px-4 py-4">
          <div className="flex flex-col items-center space-y-4 sm:flex-col sm:space-y-4 md:flex-row md:space-x-4 md:space-y-0 md:justify-between">
            <div className="text-center text-gray-600">
              © 2025 Copyright reserved for CareSimpliCity shop
            </div>
            <div className="flex gap-2 md:flex-row md:space-x-4 justify-center sm:justify-center md:justify-start">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={logo56}
                  alt="Instagram"
                  className="h-[24px] w-[24px]"
                />
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={logo57}
                  alt="Facebook"
                  className="h-[24px] w-[24px]"
                />
              </a>
              <a href="https://t.me" target="_blank" rel="noopener noreferrer">
                <img
                  src={logo58}
                  alt="Telegram"
                  className="h-[24px] w-[24px]"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Chatbot Component */}
      <div className="">
        {chatBotOpen && <ChatbotComponent setchatBotOpen={setchatBotOpen} />}
      </div>
    </footer>
  );
}

export default Footer;
