import React, { useEffect, useState } from "react";
import logo1 from "../assets/images/left.png";
import logo2 from "../assets/images/logo1.png";
// import logo2 from "../assets/images/logo2.png";
// import logo3 from "../assets/images/logo3.png";
import logo4 from "../assets/images/magic-Icon.svg";
import blue from "../assets/images/right-blue-bg-img.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getItem } from "../Lib/localStorage/localStorage";
import { getData } from "../services/services/request";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const Wellnes = () => {
  const [imgarry, setImgarry] = useState();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay interval (3 seconds)
    pauseOnHover: false,
    arrows: false,
    customPaging: function(i) {
      return (
        <div className=" relative flex items-center justify-center">
          <div
            className={` absolute bottom-6 w-4 h-4 rounded-full  transition-all transform ${
              this.currentSlide === i
                ? "bg-green-600"
                : "bg-[#D8D8D8] hover:bg-green-600"
            }`}
          />
          {this.currentSlide === i && (
            <div className="absolute bottom-4 w-8 h-8 rounded-full border border-green-600" />
          )}
        </div>
      );
    },
  };
  const BannerHomeAPI = async () => {
    try {
      const response = await getData(
        "/banners?placement=homepage&type=web&status=active"
      );
      const data = response.items;

      if (Array.isArray(data)) {
        setImgarry(data);
      } else {
        console.error("Expected an array for banners but got", data);
        setImgarry([]); // Set to empty array if data is not valid
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    BannerHomeAPI();
  }, []);

  return (
    <div className="w-full relative flex flex-col md:flex-row  mb-5">
      <div className="relative w-full">
        <Slider {...sliderSettings}>
          {imgarry && Array.isArray(imgarry) && imgarry.length > 0 ? (
            imgarry.map((image, index) => (
              <div key={index}>
                <img
                  src={image.imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-[50vh] sm:h-[70vh] md:h-screen xs:object-cover md:object-fill"
                />
              </div>
            ))
          ) : (
            <div className="w-full h-screen">
              <Skeleton variant="rounded" height="100%" />
            </div>
          )}
        </Slider>
      </div>

      {/* <div className="relative rounded-md ">
        <div className="absolute xs:w-[50%] xs:h-[100px] left-1/2 bottom-[-80px] md:bottom-12  md:transform -translate-x-1/2 -translate-y-1/2  bg-white  text-center shadow-lg rounded md:w-48 lg:w-64 lg:bottom-16">
          <h2 className=" font-bold pt-4 px-2 ">Medicine Essentials</h2>
          <p className="text-red-600 text-lg font-bold pb-2">20% off</p>
          <p className="text-white text-sm bg-black py-2">19 Jul - 30 Jul</p>
        </div>
      </div> */}

      {/* Right Section: Static Background and Text */}
      {/* <div
        className="w-full md:w-full lg:w-full flex items-center justify-center px-3 xs:h-[350px] lg:h-[410px]"
        style={{ backgroundImage: `url(${blue})` }}
      >
        <div className="flex flex-col justify-center items-center h-[300px] md:h-[350px] lg:h-[400px]">
          <h1 className="text-xl md:text-2xl lg:text-[40px] lg:leading-tight font-bold text-gray-800 text-center font-agrandir xs:mt-12 md:mt-[-40px]">
            YOUR PRESCRIPTION FOR AFFORDABLE HEALTH SOLUTIONS!
          </h1>
          <div className="w-[90%] md:w-[80%] lg:w-[70%] md:px-12">
            <h1 className="text-[#555555] text-center mt-3 lg:mt-5">
              Elevate your health journey with exclusive discounts and
              unparalleled convenience. Your path to well-being starts here,
              where every purchase is a prescription for savings.
            </h1>
          </div>
          {/* <button className="mt-6 bg-[#394e6c] text-white px-6 py-2 rounded-md font-semibold hover:bg-blue-700 flex gap-2">
            <img src={logo4} />
            SHOP NOW
          </button> 
        </div>
      </div> */}
    </div>
  );
};
