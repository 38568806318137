import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import logo1 from "../assets/images/Vector.svg";
import logo3 from "../assets/images/Vector2.png";
import logo2 from "../assets/images/image 13.svg";
import EditAddress from "./EditAddress";
import { deleteData, getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";
import Address from "./addAddress";

const ShipAddress = ({ setSeletedAddress }) => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([{ logo: logo1 }]);
  const [newAddressID, setNewAddressID] = useState(addresses?.length);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: newAddressID,
    name: "",
    email: "",
    address: "",
    phoneNumber: "",
  });
  const [selectedId, setSelectedId] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(""); // Track error message
  const token = getItem("token");
  const tokens = token.replace(/"/g, "");
  const userId = JSON.parse(getItem("UserId"));
  const pageNo = 1000;

  const config = {
    headers: {
      "x-access-token": tokens,
    },
  };

  const AddressAPI = async () => {
    setLoading(true); // Set loading to true when the request starts
    setError(""); // Clear previous errors

    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        setError("Token not found");
        setLoading(false);
        return;
      }

      const response = await getData(
        `/addresses?userId=${userId}&&pageSize=${pageNo}`,
        config
      );

      if (response.code === 200) {
        setAddresses(response.items);
      } else {
        console.error("Error: ", response.status);
        setError("Failed to load addresses");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Something went wrong while loading addresses.");
    } finally {
      setLoading(false); // Set loading to false after the request ends
    }
  };

  useEffect(() => {
    AddressAPI();
  }, []);

  useEffect(() => {
    if (addresses.length > 0) {
      setSelectedId(addresses[0]._id); // Set the first address as selected
      setSeletedAddress(addresses[0]); // Set the selected address in the parent component
    }
  }, [addresses]); // This effect runs when addresses are updated

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDelete = async (deleteId) => {
    const data = {};
    try {
      const response = await deleteData(
        `/addresses/delete/${deleteId}`,
        data,
        config
      );
      AddressAPI();
    } catch (error) {
      console.error("Error while deleting address: ", error);
    }
  };

  const handleAddressClick = (id) => {
    setSelectedId(id); // Set the selected address's id
    const selected = addresses.find((address) => address._id === id);
    setSeletedAddress(selected); // Set the selected address
  };

  return (
    <div>
      <Address
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close the modal
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        AddressAPI={AddressAPI}
      />
      <div className="flex flex-wrap mt-10 gap-5 lg:justify-between items-start">
        <div className="w-full flex justify-end lg:justify-end items-center px-2 text-right sm:text-left">
          <div>
            <img src={logo2} alt="Logo" />
          </div>
          <div className="ml-2">
            <button
              onClick={() => setModalOpen(true)}
              className="font-bold underline"
            >
              Add new address
            </button>
          </div>
        </div>
        <div className="flex justify-center flex-wrap gap-5 w-full">
          {loading && !addresses?.length && (
            <p className="mt-10 text-gray-400">
              <div className="w-full flex justify-center">
                <div className="loader1 flex justify-center"></div>
              </div>
              Loading addresses...
            </p>
          )}
          {error ? (
            <p className="mt-10 text-red-500">{error}</p>
          ) : addresses?.length === 0 ? (
            <p className="mt-10 text-gray-400 flex flex-col justify-center">
              <div className="w-full flex justify-center">
                {" "}
                <div className="loader1 flex justify-center"></div>
              </div>
              Please add a new address
            </p>
          ) : (
            addresses?.map((address) => (
              <div
                key={address._id}
                className={`w-full sm:w-[45%] lg:w-[35%] xs:w-[90%] rounded-md ${
                  address._id === selectedId ? " bg-[#394E6C] text-white" : ""
                }`}
                onClick={() => handleAddressClick(address._id)}
              >
                <div
                  className={`rounded-md p-4 ${
                    address._id === selectedId ? "" : "outline-dashed"
                  }`}
                >
                  <div className="flex flex-col">
                    <div className="pb-2 flex justify-between">
                      <img
                        src={address._id === selectedId ? logo1 : logo3}
                        alt="Logo"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        size="x"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(address._id);
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <h1 className="text-lg font-semibold">
                      {address.firstName} {address.lastName}
                    </h1>
                    <div>
                      <h1 className="text-lg font-semibold">
                        {address.address}, {address.city}, {address.state}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-lg font-semibold">
                        {address.country}, {address.zipCode}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipAddress;
