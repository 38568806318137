import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo1 from "../assets/images/Container.svg";
import logo2 from "../assets/images/current-icon.svg";
import logo3 from "../assets/images/Arrow-left-img.jpg";
import logo4 from "../assets/images/detol-product.jpg";
import logo5 from "../assets/images/lifeboy-product.jpg";
import logo6 from "../assets/images/detol-pouche-image.jpg";
import logo7 from "../assets/images/Arrow.jpg";
import logo19 from "../assets/images/Arrow.jpg";
import logo20 from "../assets/images/Arrow-left-img.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Subscription() {
  const sliderRef = useRef(null);

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  // Scroll buttons
  const scrollPrev = () => {
    sliderRef.current?.slickPrev();
  };
  const scrollNext = () => {
    sliderRef.current?.slickNext();
  };

  const [products] = useState([
    {
      id: 1,
      name: "Dettol Original Liquid handwash",
      description: " $39 Add to cart",
      price: 95,
      originalPrice: 145,
      discount: "-7%",
      image: logo4,
      link: "/orderactivity",
    },
    {
      id: 2,
      name: "Dettol Original Liquid handwash",
      description: " $39 Add to cart",
      price: 95,
      originalPrice: 145,
      discount: "-7%",
      image: logo5,
      link: "/support",
    },
    {
      id: 3,
      name: "Dettol Original Liquid handwash",
      description: " $39 Add to cart",
      price: 125,
      originalPrice: 156,
      discount: "-8%",
      image: logo6,
      link: "/cardcheckout",
    },
    {
      id: 4,
      name: "Dettol Original Liquid handwash",
      description: " $39 Add to cart",
      price: 365,
      originalPrice: 487,
      discount: "-15%",
      image: logo6,
      link: "/orderhistory",
    },
  ]);
  return (
    <>
      <Header />
      <div className="container mx-auto  max-w-[1200px] ">
        {/* Create pricing plan 1 */}
        <div className="flex flex-col pl-48 mt-10">
          <div>
            <h1 className="text-xl font-semibold">Create Pricing Plan</h1>
          </div>

          <div className="flex gap-5  border-b py-5">
            <div className="border-2 px-6 py-6 rounded-md">
              <div>
                <img src={logo1} />
                <h1 className="font-semibold">One-Time Payment</h1>
                <p>Charge users a one-time payment fee to access the content</p>
              </div>
            </div>
            <div className="border-2 px-6 py-6 rounded-md bg-[#f7f9fe]">
              <div>
                <Link to="/premiummembership">
                  <img src={logo2} />
                  <h1 className="font-semibold">Membership</h1>
                  <p>
                    Split the full bundle price over several monthly payments
                  </p>
                </Link>
              </div>
            </div>
          </div>

          {/*   name */}
          <div className="flex gap-32 mt-5">
            <div className="flex flex-col w-[30%] ">
              <label
                htmlFor="companyName"
                className="flex text-gray-700 font-medium justify-start"
              >
                Name
              </label>
              <input
                type="text"
                id="companyName"
                className="w-[960px] mt-2  px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="PRO Subscription"
              />
            </div>
          </div>

          {/* price/billing */}
          <div className="flex gap-5 mt-5">
            <div className="flex flex-col   ">
              <label
                htmlFor="country"
                className="flex text-gray-700 font-medium justify-start"
              >
                Price
              </label>
              <div className="  flex items-center border border-gray-300 rounded-md overflow-hidden">
                {/* Select Dropdown */}
                <select
                  id="country"
                  className=" border-none focus:ring-0 text-sm px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="USA">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="UK">United Kingdom</option>
                </select>

                {/* Text Input */}
                <input
                  type="number"
                  className="w-[300px] flex-1 px-4 py-2 focus:outline-none "
                  // placeholder="Enter a value"
                />
              </div>
            </div>

            {/* Apt,suite,unit */}
            <div className="flex flex-col  ">
              <label
                htmlFor="country"
                className="flex text-gray-700 font-medium justify-start"
              >
                Enrollment Duration
              </label>
              <input
                id="country"
                placeholder="months"
                className="w-[500px]  px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              ></input>
            </div>
          </div>
          {/* check boxes */}
          <div>
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="checkbox2"
                className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                htmlFor="checkbox2"
                className="ml-2 text-gray-700 text-sm md:text-base"
              >
                Charge a setup fee
              </label>
            </div>

            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                id="checkbox2"
                className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                htmlFor="checkbox2"
                className="ml-2 text-gray-700 text-sm md:text-base"
              >
                Add usage limit
              </label>
            </div>

            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                id="checkbox2"
                className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                htmlFor="checkbox2"
                className="ml-2 text-gray-700 text-sm md:text-base"
              >
                This plan has a trial period
              </label>
            </div>
          </div>

          {/* days */}
          <div className="flex gap-40  ">
            <div className="flex flex-col  w-[300px] ">
              <input
                id="country"
                className="w-[430px] ml-5 mt-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              ></input>
            </div>

            {/* days */}
            <div className="flex flex-col  ">
              <select
                id="country"
                className="w-[500px] mt-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                {/* <option value="">Select your country/region</option> */}
                <option value="USA">Days</option>
                <option value="USA">Months</option>
                <option value="Canada">Yearly</option>
                {/* <option value="UK">United Kingdom</option> */}
                {/* Add more options as needed */}
              </select>
            </div>
          </div>

          <div className="mt-4">
            <button className="bg-[#394e6c] text-white rounded-md p-2 w-[95%]">
              Save
            </button>
          </div>
        </div>
        {/* Create pricing plan 2 */}
        <div className="flex flex-col pl-48 mt-10">
          <div>
            <h1 className="text-xl font-semibold">Create Pricing Plan</h1>
          </div>

          <div className="flex gap-5  border-b py-5">
            <div className="border-2 px-6 py-6 rounded-md bg-[#f7f9fe]">
              <div>
                <img src={logo1} />
                <h1 className="font-semibold">One-Time Payment</h1>
                <p>Charge users a one-time payment fee to access the content</p>
              </div>
            </div>
            <div className="border-2 px-6 py-6 rounded-md">
              <div>
                <img src={logo2} />
                <h1 className="font-semibold">One-Time Payment</h1>
                <p>Charge users a one-time payment fee to access the content</p>
              </div>
            </div>
          </div>

          {/*   name */}
          <div className="flex gap-32 mt-5">
            <div className="flex flex-col w-[30%] ">
              <label
                htmlFor="companyName"
                className="flex text-gray-700 font-medium justify-start"
              >
                Name
              </label>
              <input
                type="text"
                id="companyName"
                className="w-[960px] mt-2  px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="PRO Subscription"
              />
            </div>
          </div>

          {/* price/billing */}
          <div className="flex gap-5 mt-5">
            <div className="flex flex-col   ">
              <label
                htmlFor="country"
                className="flex text-gray-700 font-medium justify-start"
              >
                Price
              </label>
              <div className="  flex items-center border border-gray-300 rounded-md overflow-hidden">
                {/* Select Dropdown */}
                <select
                  id="country"
                  className=" border-none focus:ring-0 text-sm px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="USA">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="UK">United Kingdom</option>
                </select>

                {/* Text Input */}
                <input
                  // type="number"
                  className="w-[300px] flex-1 px-4 py-2 focus:outline-none"
                  // placeholder="Enter a value"
                />
              </div>
            </div>

            {/* Apt,suite,unit */}
            <div className="flex flex-col  ">
              <label
                htmlFor="country"
                className="flex text-gray-700 font-medium justify-start"
              >
                Enrollment Duration
              </label>
              <input
                id="country"
                placeholder="months"
                className="w-[500px]  px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              ></input>
            </div>
          </div>

          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="checkbox2"
              className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
            />
            <label
              htmlFor="checkbox2"
              className="ml-2 text-gray-700 text-sm md:text-base"
            >
              Add usage limit
            </label>
          </div>

          <div className="flex gap-32 mt-2">
            <div className="flex flex-col w-[30%] ">
              <input
                type="text"
                id="companyName"
                className="w-[960px]   px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                // placeholder="PRO Subscription"
              />
            </div>
          </div>
          <div className="mt-5">
            <button className="bg-[#394e6c] text-white rounded-md p-2 w-[95%]">
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto  max-w-[1200px] ">
        <div className="flex text-center items-center justify-between">
          <div>
            <h1 className="text-xl">Recommendation</h1>
          </div>
          <div className="flex gap-2 border-2 p-2 justify-center">
            <div className="border-r-2  p-2">
              <button onClick={scrollPrev} className=" p-2 ">
                <img src={logo19} />
              </button>
            </div>
            <div className="p-2">
              <button onClick={scrollNext} className=" p-2   ">
                <img src={logo20} />
              </button>
            </div>
          </div>
        </div>

        {/* Product Cards Section */}
        <Slider ref={sliderRef} {...settings}>
          {/* <div className="flex items-center justify-between gap-2 mt-4 relative hover:red-700  "> */}
          {products.map((product) => (
            <div key={product.id} className="w-[25%]  relative  ">
              <Link to={product.link}>
                <div className=" ">
                  {/* Image */}
                  <div className=" flex  ">
                    <img src={product.image} className="h-[150px] w-[105px]" />
                    <div className="flex flex-col">
                      {" "}
                      <h1 className="font-bold text-xl text-[#008080] mt-2">
                        {product.name}
                      </h1>
                      <div className="flex mt-3 gap-5">
                        {" "}
                        <span className="text-black font-bold text-xl">
                          ${product.price}
                        </span>
                        <span className="text-gray-300 text-xl line-through">
                          ${product.originalPrice}
                        </span>
                        <span className="border-2 bg-[#394e6c] p-1 w-[24%] h-[35px] flex justify-center text-white rounded-md">
                          {product.discount}
                        </span>
                      </div>
                      <p className="border-2 hover:bg-[#394e6c] p-2 w-[90%] flex justify-center rounded-md mt-3">
                        {product.description}
                      </p>
                    </div>
                  </div>
                  {/* Product Details */}
                  <div className="p-4 ">
                    <div className="flex text-center items-center justify-between"></div>

                    <h1 className="flex flex-col ">
                      <span className="text-yellow-300">{product.rating}</span>
                    </h1>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          {/* </div> */}
        </Slider>
      </div>

      <Footer />
    </>
  );
}

export default Subscription;
