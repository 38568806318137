import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import BellowHeader from "./BellowHeader";

function OrderNotConfirmed() {
  return (
    <>
      <Header />
      <div className="container mx-auto max-w-[1200px] overflow-x-hidden">
        {/* <BellowHeader /> */}
        <div className="flex justify-center mt-32">
          {/* Cross Icon */}
          <div className="size-20 bg-red-200 border-4 border-red-600 rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="w-10 h-10 text-red-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-center mt-5 sm:px-12 text-center">
          <h1 className="text-2xl font-semibold text">
            Your Order Could Not Be Confirmed
          </h1>
        </div>
        <div className="flex justify-center items-center mt-4">
          <h1 className="text-[#5F6C72] flex justify-center items-center text-center w-[80%] md:w-[35%]">
            Unfortunately, we couldn't confirm your order due to a payment
            issue. Please try again or reach out to support for further
            assistance.
          </h1>
        </div>
        <div className="mb-20 flex items-center gap-24 justify-center mt-5 md:gap-20">
          <div className="flex gap-5">
            <Link to="/">
              <button className="flex gap-2 border-2 px-5 py-3 text-[#394E6C]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 12l7.5 7.5M3 12l7.5-7.5M3 12h18"
                  />
                </svg>
                Go to Home
              </button>
            </Link>

            <Link to="/shippingpayment">
              <button className="flex gap-2 border-2 px-5 py-3 bg-[#394E6C] border-[#394E6C] text-white">
                Retry Payment
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 12h18m0 0l-7.5 7.5M21 12l-7.5-7.5"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderNotConfirmed;
