import api from "./base";

// 1. GET Request
export const getData = async (endpoint, config = {}) => {
  try {
    const response = await api.get(endpoint, config);
    return response.data;
  } catch (error) {
    console.error("Error in GET request:", error);
    throw error;
  }
};

// 2. POST Request
export const postData = async (endpoint, config, data) => {
  try {
    const response = await api.post(endpoint, config, data);
    return response.data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

// 3. PUT Request
export const updateData = async (endpoint, data, config) => {
  try {
    const response = await api.put(endpoint, data, config);
    return response.data;
  } catch (error) {
    console.error("Error in PUT request:", error);
    throw error;
  }
};

// 4. DELETE Request
export const deleteData = async (endpoint, data, config) => {
  try {
    const response = await api.delete(endpoint, {
      headers: config.headers,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("Error in DELETE request:", error);
    throw error;
  }
};
