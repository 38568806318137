import React, { useState } from "react";
import { getData } from "../../services/services/request";
import { getItem } from "../../utils/LocalStorageFunctions";

const Usecartlogic = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(0);
  const Cartdata = async () => {
    const token = getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const tokens = token.replace(/"/g, ""); // safe now

    try {
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData("carts/getCart/ByToken", config);

      if (response && response.data && Array.isArray(response.data.items)) {
        const itemsLength =
          response.data.items.length > 0 ? response.data.items.length : 0;
        setItems(itemsLength);
      } else {
        console.error("No data or items found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Cart:", error);
      setLoading(false);
    }
  };
  return { items, Cartdata };
};

export default Usecartlogic;
