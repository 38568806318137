import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo1 from "../assets/images/reset-password-image.jpg";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";

export default function PasswordReset() {
  const inputRefs = useRef([]); // Refs to handle input focus
  const [otp, setOtp] = useState(["", "", "", ""]); // State to store OTP
  console.log(otp);

  const handleInputChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      // Allow only single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (otp[index]) {
        // Clear current input
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        // Move to previous input if empty
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  return (
    <div
      className="flex flex-col sm:flex-row justify-center items-center min-h-screen w-full mx-auto"
      style={{
        backgroundImage: `url(${caresimply})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end p-4">
          <img
            src={logo1}
            className="h-auto sm:h-[637px] w-full sm:w-[555px] object-cover"
            alt="Forgot Password Illustration"
          />
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2 bg-[#f5f7f9] p-6">
          <div className="flex flex-col h-auto lg:h-[521px] justify-center pl-6 lg:pl-[25px] py-4">
            <h1 className="font-bold text-2xl lg:text-4xl text-left">
              Password <span className="text-[#AEDFF7]">Reset</span>
            </h1>
            <p className="text-[#5bacad] font-semibold mt-2">
              We sent a OTP to amelie@gmail.com
            </p>

            <label
              htmlFor="otp"
              className="block text-gray-700 font-bold text-lg lg:text-xl mt-4"
            >
              Enter OTP
            </label>
            {/* OTP Input Fields */}
            <div className="flex justify-start sm:justify-start lg:justify-start items-center gap-2 mt-2 max-w-[440px]">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)} // Assign ref
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className="w-16 h-14 text-center text-2xl border-2 border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
                />
              ))}
            </div>

            {/* Reset Password Button */}
            <Link to="/login">
              {" "}
              <button className="bg-[#394e6c] text-white rounded-md py-2 px-4 font-semibold mt-6 w-full lg:w-[400px]">
                RESET PASSWORD
              </button>
            </Link>

            {/* Back to Sign In */}
            <div className="text-center mt-4 py-5">
              <Link to="/login">
                {" "}
                <a href="#" className="text-gray-600">
                  Back to <span className="text-black">Sign In</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
