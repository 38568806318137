import React, { useState } from "react";
import logo1 from "../assets/images/image-signIn.png";
import logo2 from "../assets/images/google.svg";
import logo3 from "../assets/images/apple-logo-svgrepo-com 1.svg";
import logo4 from "../assets/images/device-mobile.svg";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function SignPhoneLogin() {
  const navigate = useNavigate();
  const [SignUpNumber, setSignUpNumber] = useState({
    name: "",
  });
  const [error, setError] = useState({});

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setSignUpNumber({
  //     ...SignUpNumber,
  //     [name]: value,
  //   });
  //   setError({
  //     ...error,
  //     [name]: "",
  //   });
  // };

  const handlesignupnubersubmit = (e) => {
    e.preventDefault();

    const newError = {};

    if (!SignUpNumber.name) {
      newError.name = "Field is required";
    } else if (SignUpNumber.name.length !== 10) {
      newError.name = "Phone number should be 10 digits";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError); // Set errors only if there are any
      return;
    }

    // Handle the form submission if no errors
    console.log("Form submitted", SignUpNumber);
    navigate("/login");
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only numeric characters and limit to 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  return (
    <>
      <div
        className="flex flex-col sm:flex-row justify-center items-center min-h-screen w-full mx-auto"
        style={{ backgroundImage: ` url(${caresimply})` }}
      >
        <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-3xl mx-auto">
          {/* Image Section */}
          <div className="w-full sm:w-1/2 px-4 sm:px-8">
            <img
              src={logo1}
              className="w-full sm:w-[500px] sm:h-[509px] object-contain"
              alt="Sign Up"
            />
          </div>

          {/* Form Section */}
          <div className="w-full sm:w-1/2 bg-[#f5f7f9] p-6">
            <h1 className="font-bold text-2xl sm:text-4xl text-left mb-4">
              Sign <span className="text-[#AEDFF7]">In</span>
            </h1>

            <form onSubmit={handlesignupnubersubmit}>
              <div className="flex flex-col w-full mt-4">
                {/* <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-bold w-full text-xl"
                >
                  Phone Number
                </label> */}
                <div className="flex gap-2 items-center justify-between bg-[#F5F7F9] border-2">
                  <select className="w-[70px] bg-[#F5F7F9] border-r-2 outline-none flex gap-4 ">
                    <option value="USA">+33</option>
                    <option value="USA">+91</option>
                    <option value="Canada">+46</option>
                    <option value="UK">+92</option>
                  </select>
                  <input
                    type="number"
                    maxLength={10}
                    id="phoneNumber"
                    className="block px-4 py-2 bg-[#F5F7F9]  rounded-md mt-2 w-full max-w-md outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                    placeholder="0 00 00 00 00"
                    name="name"
                    value={phoneNumber}
                    onChange={handleChange}
                  />
                </div>

                {error.name && (
                  <div className="text-red-500 font-semibold mt-1">
                    {error.name}
                  </div>
                )}
              </div>

              <div>
                <Link to="/otpverification">
                  <button
                    type="submit"
                    className="bg-[#394e6c] text-white w-full  rounded-md p-2 font-semibold mt-5"
                  >
                    SIGN IN
                  </button>
                </Link>
              </div>
            </form>

            {/* Divider */}
            <div className="relative flex py-4 items-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="flex-shrink mx-4 text-gray-500">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>

            {/* Social Login Icons */}
            <div className="flex gap-5 justify-center">
              <button className="bg-white border border-gray-300 rounded-lg p-4 flex items-center justify-center w-20 h-10 ">
                <img src={logo2} alt="Google" />
              </button>
              {/* <button className="bg-white border border-gray-300 rounded-lg p-4 flex items-center justify-center w-20 h-10 ">
                <img src={logo3} alt="Apple" />
              </button> */}
              {/* <button className="bg-white border border-gray-300 rounded-lg p-4 flex items-center justify-center w-20 h-10 ">
                <img src={logo4} alt="Mobile" />
              </button> */}
            </div>

            {/* Sign Up Link */}
            {/* <p className="text-center flex flex-col-reverse mt-32">
              <a href="#" className="text-[#3B4054]">
                Do you have account?{" "}
                <span className="text-black font-semibold">Sign In</span>
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SignPhoneLogin;
