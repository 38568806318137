import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Personal from "./pages/Personal";
import SkinCare from "./components/personal/SkinCare";
import Cart from "./components/cart/Cart";
import Profile from "./components/profile/Profile";
import Whishlist from "./components/profile/Whishlist";
import Addres from "./components/profile/Addres";
import MyOrder from "./components/profile/MyOrder";
import Active from "./components/profile/Active";
import Order from "./components/profile/Order";
import Cancellation from "./components/profile/Cancellation";
import Myinfo from "./components/profile/Myinfo";
import Medical from "./components/profile/Medical";
import Uploadprescription from "./components/Uploadprescription";
import Cancellationdetails from "./components/profile/Cancellationdetails";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import OtpVerification from "./components/OtpVerification";
import PasswordReset from "./components/PasswordReset";
import SignUpNumber from "./components/SignUpNumber";
import Analgesics from "./pages/Analgesics";
import CardCheckout from "./components/CardCheckout";
import ShippingPayment from "./components/ShippingPayment";
import ProductConfirmation from "./components/ProductConfirmation";
import OrderActivity from "./components/OrderActivity";
import Liveril from "./components/Liveril";
import PremiumMembership from "./components/PremiumMembership";
import Payment from "./components/Payment";
import PaymentSucseful from "./components/PaymentSucseful";
import Membership from "./components/Membership";
import Error from "./components/Error";
import NewAddress from "./components/NewAddress";
import EditAddress from "./components/addAddress";
import ProfileSection from "./components/ProfileSection";
import Support from "./components/Support";
import Subscription from "./components/Subscription";
import OrderhHistory from "./components/OrderhHistory";
import ShowLess from "./components/ShowLess";
import ProductDetails from "./components/ProductDetails";
import Reviews from "./components/Reviews";
import ChangePassword from "./components/ChangePassword";
import Veterinary from "./pages/Veterinary";
import Orphan from "./pages/Orphan";
import Dietary from "./pages/Dietary";
import Generic from "./pages/Generic";
import Skin from "./pages/Skin";
// import IND from "./pages/Ind";
import Ind from "./pages/Ind";
import Herbal from "./pages/Herbal";
import BestSellers from "./pages/BestSellers";
import ScrollToTop from "./components/ScrollToTop";
import SignPhoneLogin from "./components/SignPhoneLogin";
// import OrderPaymentFailed from "./components/OrderNotConfirmed";
import OrderNotConfirmed from "./components/OrderNotConfirmed";
import MembershipBought from "./components/MembershipBought";
import MembershipNotBought from "./components/MembershipNotBought";
import EditProfile from "./components/EditProfile";
import Header from "./components/Header";
import OtpVerificationSing from "./components/OtpVerificationSing";
import Termscondition from "./components/Terms&condition";
import Loginwithphone from "./components/Loginwithphone";

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/personalcare" element={<Personal />} /> */}
        {/* <Route path="/skincare" element={<SkinCare />} /> */}
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/whishlist" element={<Whishlist />} /> */}
        {/* <Route path="/addres" element={<Addres />} /> */}
        {/* <Route path="/myorder" element={<MyOrder />} /> */}
        <Route path="termsCondition" element={<Termscondition />} />
        {/* <Route path="/active" element={<Active />} /> */}
        {/* <Route path="/order" element={<Order />} /> */}
        {/* <Route path="/cancellation" element={<Cancellation />} /> */}
        {/* <Route path="/myinfo" element={<Myinfo />} /> */}
        {/* <Route path="/medical" element={<Medical />} /> */}
        {/* <Route path="/uploadprescription" element={<Uploadprescription />} /> */}
        {/* <Route path="/cancellationdetails" element={<Cancellationdetails />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/loginWithnumber" element={<Loginwithphone />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/otpverification" element={<OtpVerification />} />
        <Route path="/OtpVerificationSing" element={<OtpVerificationSing />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        {/* <Route path="/signupnumber" element={<SignUpNumber />} /> */}
        <Route path="/Prouductdetails/:id" element={<Analgesics />} />
        <Route path="/cardcheckout" element={<CardCheckout />} />
        <Route path="/shippingpayment" element={<ShippingPayment />} />
        <Route path="/productconfirmation" element={<ProductConfirmation />} />
        <Route path="/orderactivity" element={<OrderActivity />} />
        {/* <Route path="/liveril" element={<Liveril />} /> */}
        <Route path="/premiummembership" element={<PremiumMembership />} />
        {/* <Route path="/payment" element={<Payment />} /> */}
        <Route path="/paymentsucseful" element={<PaymentSucseful />} />
        {/* <Route path="/membership" element={<Membership />} /> */}
        <Route path="*" element={<Error />} />
        <Route path="/newaddress" element={<NewAddress />} />
        <Route path="/editaddress" element={<EditAddress />} />
        <Route path="/profilesection" element={<ProfileSection />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/support" element={<Support />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/orderhistory" element={<OrderhHistory />} />
        {/* <Route path="/showless" element={<ShowLess />} /> */}
        <Route path="/productdetails/:Pid" element={<ProductDetails />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        {/* <Route path="/veterinary" element={<Veterinary />} /> */}
        {/* <Route path="/orphan" element={<Orphan />} /> */}
        {/* <Route path="/dietary" element={<Dietary />} /> */}
        {/* <Route path="/veterinary" element={<Veterinary />} /> */}
        {/* <Route path="/generic" element={<Generic />} /> */}
        {/* <Route path="/Antibiotics" element={<Ind />} /> */}
        {/* <Route path="/herbal" element={<Herbal />} /> */}
        {/* <Route path="/bestsellers" element={<BestSellers />} /> */}
        <Route path="/signphonelogin" element={<SignPhoneLogin />} />
        <Route path="/membershipbought" element={<MembershipBought />} />
        <Route path="/ordernotconfirmed" element={<OrderNotConfirmed />} />
        <Route path="/MembershipNotBought" element={<MembershipNotBought />} />
      </Routes>
    </Router>
  );
}

export default App;
