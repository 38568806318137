import React, { useEffect, useState } from "react";
import logo35 from "../assets/images/skin-care-images.png";
import logo38 from "../assets/images/vitamin-E.png";
import logo39 from "../assets/images/simply-cream-images.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { getItem } from "../Lib/localStorage/localStorage";
import { getData } from "../services/services/request";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function FeaturedProducts() {
  const [logo, setLogo] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,

    customPaging: function(i) {
      return (
        <div className="relative flex items-center justify-center">
          <div
            className={`w-4 h-4 rounded-full transition-all transform ${
              this.currentSlide === i
                ? "bg-green-600"
                : "bg-[#D8D8D8] hover:bg-green-600"
            }`}
          />
          {this.currentSlide === i && (
            <div className="absolute w-8 h-8 rounded-full border border-green-600" />
          )}
        </div>
      );
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  const Topcompanies = async () => {
    try {
      const response = await getData("/companies/");
      const data = response.items;
      const companyNames = response.items.map((item) => item.companyName);
      setCompanyName(companyNames);
      setLogo(data.map((med) => med.logo));
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Topcompanies();
  }, []);

  return (
    <div className="">
      <div className="relative flex items-center justify-center">
        <div className="text-[90px] opacity-15 text-blue-800 uppercase text-center xs:text-[45px] sm:text-[40px] md:text-[50px] lg:text-[90px]">
          TOP COMPANIES
        </div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[35px] uppercase text-center whitespace-nowrap sm:text-[8px] md:text-[25px] lg:text-[40px]">
          TOP COMPANIES
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center gap-5">
          <div className=" w-full">
            <Skeleton height={300} />
            <Skeleton width="60%" />
          </div>
          <div className=" w-full">
            <Skeleton height={300} />
            <Skeleton width="60%" />
          </div>
          <div className=" w-full">
            <Skeleton height={300} />
            <Skeleton width="60%" />
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          {logo.map((image, index) => (
            <div key={index} className="p-2">
              <div className="flex bg-white shadow-lg py-10 justify-center border-blue-900 items-center my-10 rounded-[20px]">
                <div className="w-full max-w-[200px] lg:max-w-[250px] gap-6 mx-auto">
                  <img
                    src={image}
                    className="w-full h-[250px] object-scale-down rounded-md"
                    alt={`Logo ${index}`}
                  />
                  {/* <div className="flex justify-center font-bold pt-8">
                    <h2>{companyName[index]}</h2>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
