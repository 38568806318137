import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo1 from "../assets/images/Avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getData, updateData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";

function EditProfile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countryCode, setCountryCode] = useState([]);

  const [formData, setFormData] = useState({
    // fullName: "",
    // email: "",
    // phone: "",
    // zipCode: "",
    // Country: "",
    // state: "",
    // imgUrl: logo1, // Default image
  });
  const [error, setError] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("00"); // Default to India
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchCode, setSearchCode] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isNumeric = (value) => {
    return /^\d*$/.test(value);
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setFormData({
      ...formData,
      State: stateName, // Update State with the selected state name
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((prevError) => ({
      ...prevError,
      [name]: "",
    }));
    if (name === "phone" && !isNumeric(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Log the file to the console to see the image details
      // console.log("Uploaded Image:", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        // Log the base64 URL of the image
        console.log("Image as Base64:", reader.result);

        // Set the uploaded image URL in formData
        setFormData({
          ...formData,
          imgUrl: reader.result, // Set the base64 string as the image URL
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    document.getElementById("uploadProfilePicture").click();
  };

  // const captlizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  // const formValidation = (formData) => {
  //   let errors = {};
  //   const emptyField = Object.keys(formData).find((key) => !formData[key]);

  //   if (emptyField) {
  //     errors[emptyField] = `${captlizeFirstLetter(emptyField)} is Required`;
  //   }

  //   setError(errors);
  //   return Object.keys(errors).length === 0;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      zipCode: formData.zipCode,
      Country: formData.Country,
      State: formData.State,
      imgUrl: formData.imgUrl,
      countryCode: selectedCountryCode,
    };
    // console.log("payload data", payload);

    try {
      const userId = JSON.parse(getItem("UserId"));
      const token = getItem("token");
      const tokens = token.replace(/"/g, "");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await updateData(
        `/users/update/${userId}`,
        payload,
        config
      );

      if (response && response.data) {
        // console.log("Data updated successfully:", response.data);
        navigate("/profilesection");
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoading(false);
    }
  };

  const Profiledata = async () => {
    try {
      const userId = JSON.parse(getItem("UserId"));
      const token = getItem("token");
      const tokens = token.replace(/"/g, "");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(`/users/${userId}`, config);

      if (response && response.data) {
        setFormData(response.data);
        const formattedCode = response.data.countryCode.startsWith("+")
          ? response.data.countryCode
          : `+${response.data.countryCode}`;

        setSelectedCountryCode(formattedCode);

        setSearchCode(formattedCode);

        // console.log("profiledata", response.data);
        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };
  const Countrycode = async () => {
    try {
      const countriescode = await getData("/auths/countries/Pin");
      setCountryCode(countriescode.data);
      countriescode.data.forEach((country) => {
        // console.log(country.dial_code, "KKKKKKKKKKKKKKKKKKKK");
      });

      return countriescode;
    } catch (error) {
      console.error("Error fetching country code data:", error);
      return null;
    }
  };

  const Countrycall = async () => {
    try {
      const countryres = await getData("/auths/countries/List");
      setCountries(countryres.data);
      // console.log("country", countryres.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryCode) => {
    try {
      const response = await getData(
        `/auths/states?countryCode=${countryCode}`
      );

      if (response.data && Array.isArray(response.data.states)) {
        setStates(response.data.states);
      } else {
        console.error("Invalid states data:", response.data);
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      setStates([]); // Fallback to an empty array on error
    }
  };

  // After fetching the profile data:

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    const selectedCountry = countries.find(
      (Country) => Country.code3 === countryCode
    );

    if (selectedCountry) {
      setFormData({
        ...formData,
        Country: selectedCountry.name,
        state: "",
      });

      // Update the phone country code based on country selection
      switch (selectedCountry.code3) {
        case "IND":
          setSelectedCountryCode("+91");
          break;
        case "USA":
          setSelectedCountryCode("+1");
          break;
        case "GBR":
          setSelectedCountryCode("+44");
          break;
        case "FRA":
          setSelectedCountryCode("+33");
          break;
        case "CAN":
          setSelectedCountryCode("+1");
          break;
        // Add more countries as needed
        default:
          setSelectedCountryCode("+91");
      }

      fetchStates(countryCode);
    }
  };

  const handleCodeSearch = (value) => {
    setSearchCode(value);
    setIsDropdownOpen(true);

    // Show all codes when input is empty
    if (!value.trim()) {
      setFilteredCountryCodes(countryCode);
      return;
    }

    const filtered = countryCode.filter(
      (country) =>
        country.dial_code.includes(value) ||
        country.code.toLowerCase().includes(value.toLowerCase()) ||
        country.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredCountryCodes(filtered);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountryCode(country.dial_code);
    setSearchCode(country.dial_code);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    Profiledata();
    Countrycode();
    Countrycall();
  }, []);

  return (
    <>
      <Header />
      <div className="lg:flex justify-start items-start pt-20  ">
        {" "}
        <button className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] ">
          <Link to="/profilesection">
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </Link>
        </button>
      </div>
      <div className="container mx-auto max-w-[1200px]">
        <div className="border-2 mt-10 w-[85%] mx-auto">
          <h1 className="border-b-2 py-2 px-5 text-lg font-bold">
            EDIT PROFILE
          </h1>
          <form action="" onSubmit={handleSubmit}>
            <div className="flex flex-wrap lg:flex-nowrap gap-5 p-5">
              <div className="w-auto mx-auto lg:w-[25%] flex justify-center items-start relative">
                <img
                  src={formData.imgUrl || logo1}
                  alt="Avatar"
                  className="rounded-full w-32 h-32"
                />
                <div className="absolute top-24 xs:right-4 lg:right-16 border-0 border-red-600">
                  <FontAwesomeIcon
                    icon={faPen}
                    className="text-white border-2 border-white bg-[#0181B6] p-2 rounded-full cursor-pointer"
                    onClick={handleImageClick}
                  />
                </div>
              </div>

              <input
                type="file"
                hidden
                id="uploadProfilePicture"
                onChange={handleImageChange}
              />
              <div className="w-full lg:w-[75%] flex flex-col gap-5">
                {/* Name and Email */}
                <div className="flex flex-col lg:flex-row gap-5">
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="companyName"
                      className="text-gray-700 font-medium"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className={`w-full mt-2 px-4 py-2 border rounded-md focus:outline-none ${
                        error.name ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Enter Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                    <div className="text-red-500 text-[11px] mt-1">
                      {error.name}
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="email"
                      className="text-gray-700 font-medium"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      disabled
                      id="email"
                      className={`w-full mt-2 px-4 py-2 border rounded-md focus:outline-none ${
                        error.email ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email || formData.username}
                      onChange={handleChange}
                    />
                    {error.email && (
                      <div className="text-red-500 text-[11px] mt-1">
                        {error.email}
                      </div>
                    )}
                  </div>
                </div>

                {/* Phone Number */}
                <div className="flex flex-col lg:flex-row gap-5">
                  <div className="flex flex-col lg:w-[350px] md:w-[full]">
                    <label className="flex text-gray-700 font-medium justify-start">
                      Phone Number
                    </label>
                    <div
                      className={`flex border mt-2 rounded-md ${
                        error.phone ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <div className="flex">
                        <div className="relative" ref={dropdownRef}>
                          <input
                            type="text"
                            value={searchCode}
                            onChange={(e) => handleCodeSearch(e.target.value)}
                            onFocus={() => {
                              setIsDropdownOpen(true);
                              setFilteredCountryCodes(countryCode); // Show all codes on focus
                            }}
                            placeholder="+00"
                            className="w-16 px-2 py-2 border-r rounded-md focus:outline-none"
                          />
                          {isDropdownOpen && (
                            <div className="absolute left-0 top-full mt-1 w-48 max-h-48 overflow-y-auto bg-white border rounded-md shadow-lg z-50">
                              {filteredCountryCodes.map((country) => (
                                <div
                                  key={country.code}
                                  onClick={() => handleCountrySelect(country)}
                                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                >
                                  <span className="font-medium">
                                    {country.dial_code}
                                  </span>
                                  <span className="text-sm text-gray-600">
                                    {country.name} ({country.code})
                                  </span>
                                </div>
                              ))}
                              {filteredCountryCodes.length === 0 && (
                                <div className="px-3 py-2 text-gray-500">
                                  No matches found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <input
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          maxLength="10"
                          className="flex-1 px-4 py-2 focus:outline-none"
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                    <p className="text-red-500 text-left mt-1 text-[11px]">
                      {error.phone}
                    </p>
                  </div>
                  <div className="flex flex-col w-full lg:w-[50%]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 font-medium"
                    >
                      Country/Region
                    </label>
                    <select
                      name="country"
                      value={formData.Country}
                      onChange={handleCountryChange}
                      className={`w-full mt-2 px-4 py-3 border rounded-md focus:outline-none ${
                        error.country ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">{formData.Country || "Select"}</option>
                      {countries.map((country) => (
                        <option key={country.code3} value={country.code3}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 mt-1 text-[11px]">
                      {error.country}
                    </p>
                  </div>
                </div>

                {/* Country/State */}
                <div className="flex flex-col lg:flex-row gap-5">
                  <div className="flex flex-col w-full lg:w-[50%]">
                    <label
                      htmlFor="state"
                      className="text-gray-700 font-medium"
                    >
                      State
                    </label>
                    <select
                      name="state"
                      value={formData.State || ""} // Ensure default empty string if State is not selected
                      onChange={handleStateChange} // Call the function to update the state
                      className={`w-full mt-2 px-4 py-3 border rounded-md focus:outline-none ${
                        error.state ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">{formData.State || "Select"}</option>{" "}
                      {/* Default option for state */}
                      {Array.isArray(states) && states.length > 0 ? (
                        states.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No states available</option>
                      )}
                    </select>

                    <p className="text-red-500 text-[11px] mt-1">
                      {error.state}
                    </p>
                  </div>
                  <div className="flex flex-col md:w-full lg:pr-0 lg:w-[340px]">
                    <label className="flex text-gray-700 font-medium justify-start">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Zip code"
                      className={`w-full mt-2 px-4 py-2 border rounded-md focus:outline-none ${
                        error.zipCode ? "border-red-500" : "border-gray-300"
                      }`}
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      onInput={(e) => {
                        if (e.target.value.length > 6) {
                          e.target.value = e.target.value.slice(0, 6);
                        }
                      }}
                      maxLength="6"
                    />
                    <p className="text-red-500 text-[11px] mt-1">
                      {error.zipCode}
                    </p>
                  </div>
                </div>

                <button
                  type="submit"
                  className="bg-[#0181B6] px-8 py-3 text-md font-semibold text-white p-2 rounded-md self-start"
                >
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditProfile;
