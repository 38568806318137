import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo1 from "../assets/images/Avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../utils/LocalStorageFunctions";
import { getData } from "../services/services/request";

function ProfileSection() {
  const navigate = useNavigate();
  const userId = JSON.parse(getItem("UserId"));
  const token = getItem("token");
  const [profile, setProfile] = useState(null); // State to store fetched profile data
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState(null);

  const ProfileData = async () => {
    try {
      const tokens = token.replace(/"/g, "");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(`/users/${userId}`, config);

      if (response && response.data) {
        setProfile(response.data);
        // console.log("Data", response.data);
        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };
  const memberShipData = async () => {
    try {
      const tokens = token.replace(/"/g, "");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(
        `userMemberships?userId=${userId}&&status=active`,
        config
      );

      if (response && response.items) {
        setMembership(response.items);
        // console.log("Data", response.items);

        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };
  function formatDate(dateString, format = "DD/MM/YYYY HH:mm:ss") {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    switch (format) {
      case "DD/MM/YYYY HH:mm:ss":
        return `${day}/${month}/${year} `;
      case "MM/DD/YYYY HH:mm:ss":
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
      case "YYYY-MM-DD HH:mm:ss":
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      case "Month DD, YYYY HH:mm:ss":
        return `${date.toLocaleString("default", {
          month: "long",
        })} ${day}, ${year} ${hours}:${minutes}`;
      default:
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  }

  useEffect(() => {
    ProfileData();
    memberShipData();
  }, []);

  // Show a loading spinner or placeholder if data is still loading
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <Header />
      {/* <div className="lg:flex justify-start items-start pt-24 ">
      
        <button className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] ">
          <Link to="/">
            {" "}
            {/* <FontAwesomeIcon icon={faArrowAltCircleLeft} /> */}
      {/* </Link> */}
      {/* // </button> */}
      {/* // </div> } */}
      <div className="container mx-auto max-w-[1200px]  pt-24 ">
        <div className="border-2 mt-10 w-[85%] mx-auto">
          <h1 className="border-b-2 p-2 text-lg font-bold">PROFILE</h1>
          <div className="flex flex-wrap lg:flex-nowrap gap-5 p-5">
            <div className="w-full lg:w-[25%] flex justify-center items-start">
              <img
                src={profile?.imgUrl || logo1}
                alt="Avatar"
                className="rounded-full w-32 h-32"
              />
            </div>

            <div className="w-full lg:w-[75%] flex flex-col gap-5">
              <div className="flex flex-col md:flex-row gap-5">
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">Name</h5>
                  <h4 className="font-bold text-[17px] mt-1">
                    {profile?.fullName}
                  </h4>
                </div>
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">Email</h5>
                  <h4 className="font-bold text-[17px] mt-1">
                    {profile?.email || profile?.username}
                  </h4>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-5">
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">
                    Phone Number
                  </h5>
                  <h4 className="font-bold text-[17px] mt-1">
                    {profile?.countryCode} {profile?.phone}
                  </h4>
                </div>
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">
                    Country/Region
                  </h5>
                  <h4 className="font-bold text-[17px]">{profile?.Country}</h4>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-5">
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">State</h5>
                  <h4 className="font-bold text-[17px] mt-1">
                    {profile?.State}
                  </h4>
                </div>
                <div className="flex flex-col w-full">
                  <h5 className="text-gray-500 text-[15px] font-bold">
                    Zip Code
                  </h5>
                  <h4 className="font-bold text-[17px] mt-1">
                    {profile?.zipCode}
                  </h4>
                </div>
              </div>
              <div>
                {membership && membership.length > 0
                  ? membership.map((item, index) => (
                      <div key={index}>
                        <div className="flex flex-col py-3 md:flex-row gap-5">
                          <div className="flex flex-col w-full">
                            <h5 className="text-gray-500 text-[15px] font-bold">
                              Subscription Plan Name
                            </h5>
                            <h4 className="font-bold text-[17px]">
                              {item?.membershipId?.membershipName}
                            </h4>
                          </div>
                          <div className="flex flex-col w-full">
                            <h5 className="text-gray-500 text-[15px] font-bold">
                              Duration
                            </h5>
                            <h4 className="font-bold text-[17px]">
                              {item?.membershipId?.membershipDuration}
                            </h4>
                          </div>
                        </div>
                        <div className="flex flex-col  md:flex-row gap-5">
                          <div className="flex flex-col w-full">
                            <h5 className="text-gray-500 text-[15px] font-bold">
                              Start Date
                            </h5>
                            <h4 className="font-bold text-[17px]">
                              {formatDate(item?.membershipFrom)}
                            </h4>
                          </div>
                          <div className="flex flex-col w-full">
                            <h5 className="text-gray-500 text-[15px] font-bold">
                              End Date
                            </h5>
                            <h4 className="font-bold text-[17px] ">
                              {formatDate(item?.membershipTo)}
                            </h4>
                          </div>
                        </div>
                        <div className="flex flex-col pt-4 md:flex-row gap-5">
                          <div className="flex flex-col w-full">
                            <h5 className="text-gray-500 text-[15px] font-bold">
                              Subscription Status
                            </h5>
                            <h4 className="font-bold text-[17px] capitalize">
                              {item?.status}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              <button
                type="submit"
                className="bg-[#0181B6] text-md font-semibold px-10 text-white p-2 rounded-md self-start"
                onClick={() => navigate("/editprofile")}
              >
                <FontAwesomeIcon icon={faPen} className="text-sm pr-3" />
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProfileSection;
