import React, { useEffect, useState } from "react";
import logo2 from "../assets/images/logo  22.svg";
import logo3 from "../assets/images/green-check.svg";

import { Link } from "react-router-dom";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { getItem } from "../utils/LocalStorageFunctions";
import { deleteData, getData, postData } from "../services/services/request";

function PremiumMembership() {
  const [contentProduct, setContentProduct] = useState([]);
  const [activeSlideId, setActiveSlideId] = useState("");
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(getItem("UserId"));
  const token = getItem("token");
  const [activeMembership, setActiveMembership] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [membershipId, setMembershipId] = useState(null);

  const Membershipshearch = async () => {
    const pageNo = 1000;
    try {
      const token = getItem("token");
      const tokens = token.replace(/"/g, "");

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await getData(`/memberships?pageSize=${pageNo}`, config);
      const data = response.items;
      setContentProduct(data);
      // console.log("Membership", data);
    } catch (error) {
      console.error("Error Membership:", error);
    }
  };
  useEffect(() => {
    Membershipshearch();
  }, []);

  const NextArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "black",
        borderRadius: "50%",
        padding: "1px 10px",
        position: "absolute",
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
    >
      <span style={{ color: "white", fontSize: "20px" }}>→</span>
    </div>
  );

  useEffect(() => {
    // Set the active slide ID when the component mounts (for the first slide)
    if (contentProduct && contentProduct.length > 0) {
      setActiveSlideId(contentProduct[0].id);
    }
  }, [contentProduct]);
  const PrevArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "black",
        borderRadius: "50%",
        padding: "1px 10px",
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
    >
      <span style={{ color: "white", fontSize: "20px" }}>←</span>
    </div>
  );

  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      // Get the ID of the active slide (you can adjust this to use whatever property you need)
      const activeSlide = contentProduct[index];
      setActiveSlideId(activeSlide.id); // Update the active slide ID
    },
    arrows: true, // Enable arrows
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: function(i) {
      if (i > 2) {
        return <div className="hidden" />;
      }

      return (
        <div className="relative flex items-center justify-center">
          <div
            className={`w-4 h-4 rounded-full transition-all transform ${
              this.currentSlide === i
                ? "bg-green-600"
                : "bg-[#D8D8D8] hover:bg-green-600"
            }`}
          />
          {this.currentSlide === i && (
            <div className="absolute w-8 h-8 rounded-full border border-green-600" />
          )}
        </div>
      );
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "300px", // Adjust this to control vertical positioning
          left: "35%",
          // transform: "translateX(-0%)", // Center the dots horizontally
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };
  const getmembership = async () => {
    if (!token) {
      console.error("No token found in localStorage.");
      alert("No token found. Please log in first.");
      return;
    }

    const tokens = token.replace(/"/g, "");

    if (!activeSlideId) {
      console.error("No activeSlideId available.");
      alert("No active slide ID available. Please try again.");
      return;
    }

    const Payload = {
      membershipId: activeSlideId,
    };

    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    // Set loading to true when the request starts
    setLoading(true);

    try {
      const response = await postData(
        "/userMemberships/create",
        Payload,
        config
      );

      if (response && response.data && response.data.stripeSessionUrl) {
        console.log("Redirecting to Stripe:", response.data.stripeSessionUrl);
        window.location.href = response.data.stripeSessionUrl;
      } else {
        console.error(
          "Unexpected response format or missing stripeSessionUrl:",
          response
        );
        alert("Unexpected response from the server. Please try again.");
      }
    } catch (error) {
      console.error("Error creating membership:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert(
          "An error occurred while creating your membership. Please try again."
        );
      }
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  const fetchActiveMembership = async () => {
    try {
      const tokens = token.replace(/"/g, "");
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(
        `userMemberships?userId=${userId}&&status=active`,
        config
      );
      if (response && response.items) {
        setActiveMembership(response.items);
      }
    } catch (error) {
      console.error("Error fetching active membership:", error);
    }
  };

  const handleCancelMembership = async (id) => {
    console.log("HELLO", id);
    try {
      const tokens = token.replace(/"/g, "");
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      await postData(
        `userMemberships/cancelMembership?membershipId=${id}`,
        {},
        config
      );
      setShowCancelModal(false);
      fetchActiveMembership();
    } catch (error) {
      console.error("Error canceling membership:", error);
    }
  };

  useEffect(() => {
    fetchActiveMembership();
  }, []);

  return (
    <>
      <div className="lg:flex justify-start items-start  bg-gray-200">
        {" "}
        <button className="rounded-full p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] ">
          <Link to="/">
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </Link>
        </button>
      </div>
      <div
        className="flex justify-center items-center min-h-screen w-full mx-auto  bg-cover bg-center"
        style={{ backgroundImage: `url(${caresimply}) ` }}
      >
        <div className="flex flex-col md:flex-row justify-center items-center bg-white bg-opacity-90 rounded-lg shadow-lg max-w-5xl w-full overflow-hidden">
          {/* Image Section */}
          <div className="flex justify-center items-center p-4 h-auto w-full max-w-sm md:h-[580px] md:w-[415px]">
            {activeMembership && activeMembership.length > 0 ? (
              <div className="bg-white p-6 rounded-lg shadow-md w-full">
                <h2 className="text-2xl font-bold mb-4">
                  Your Active Membership
                </h2>

                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Plan:</span>
                    <span className="text-blue-600">
                      {activeMembership[0]?.membershipId?.membershipName}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Status:</span>
                    <span className="text-green-600 capitalize">
                      {activeMembership[0]?.status}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Price:</span>
                    <span>
                      ${activeMembership[0]?.price}/{" "}
                      {activeMembership[0]?.membershipId?.membershipDuration}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Start Date:</span>
                    <span>
                      {new Date(
                        activeMembership[0]?.membershipFrom
                      ).toLocaleDateString()}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="font-semibold">Next Billing:</span>
                    <span>
                      {new Date(
                        activeMembership[0]?.membershipTo
                      ).toLocaleDateString()}
                    </span>
                  </div>

                  <div className="mt-6 space-y-3">
                    <button
                      onClick={() => setShowCancelModal(true)}
                      className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition-colors"
                    >
                      Cancel Membership
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center text-gray-600">
                <p>No active membership</p>
                <p className="mt-2">Select a plan to get started</p>
              </div>
            )}
          </div>

          {/* Form Section */}
          <div className="w-full md:w-1/2 my-6 bg-[#f5f7f9] p-4    ">
            <div className="flex justify-center mb-4  ">
              <div>
                <img src={logo2} alt="Care Simply Logo" />
              </div>
            </div>
            <Slider {...settings}>
              {/* <div className="flex flex-col justify-center w-full max-w-md items-center "> */}
              {contentProduct.map((subscription) => (
                <div className=" flex justify-items-center">
                  <div
                    key={subscription.id}
                    className="  max-w-[302px] margin-0 place-content-centeritems-center"
                  >
                    <h1 className="text-3xl md:text-4xl font-semibold pr-10  justify-center">
                      {subscription.membershipName}
                      {/* <span className="text-white bg-blue-500 p-1 text-lg rounded-md ">
                    Popular
                  </span> */}
                    </h1>
                    <p className="mt-2 flex justify-center">
                      {subscription.description1}
                      {/* Ideal for burgeoning startups seeking continuous design
                    assistance. */}
                    </p>
                    <h1 className="flex   font-bold text-2xl  ">
                      ${subscription.price}
                      {/* $999 */}
                    </h1>
                    <h1 className="font-semibold mt-2 pr-8">
                      Pause or cancel anytime.
                      <br /> 7 days money-back guarantee.
                    </h1>
                    <div className="flex flex-col space-y-2 pr-7 py-5">
                      {subscription.membershipDescription.map((feature) => (
                        <div key={feature.id} className="flex items-center">
                          <img
                            src={logo3}
                            alt="Feature Icon"
                            className="mr-2 h-5 w-5"
                          />
                          <span>{feature}</span>
                        </div>
                      ))}
                    </div>{" "}
                  </div>
                </div>
              ))}
            </Slider>

            <div className="w-full py-5 mt-4">
              <button
                onClick={getmembership}
                className="bg-[#394e6c] text-white w-full py-2 font-semibold rounded-md"
              >
                {loading ? (
                  <div className="flex justify-center">
                    <div className="spinner-border animate-spin border-2 border-t-2 border-white w-6 h-6 mr-2"></div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  "CONTINUE TO PAY"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showCancelModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
            <h3 className="text-xl font-bold mb-4">Cancel Membership</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to cancel your membership? This action
              cannot be undone.
            </p>
            <div className="flex space-x-4">
              <button
                onClick={() => setShowCancelModal(false)}
                className="flex-1 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
              >
                Keep Membership
              </button>
              <button
                onClick={() => handleCancelMembership(activeMembership[0]?.id)}
                className="flex-1 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PremiumMembership;
