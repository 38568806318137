import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import logo1 from "../assets/images/care-simply-llc-doc-girl.png";
import logo2 from "../assets/images/logo  22.svg";
import logo3 from "../assets/images/green-check.svg";
import logo10 from "../assets/images/venmo-Icon.svg";
import logo11 from "../assets/images/paypal-image .svg";
import logo12 from "../assets/images/amazon-icon-1 1.svg";
import logo13 from "../assets/images/CreditCard.svg";
import logo8 from "../assets/images/CheckCircle.svg";
import logo7 from "../assets/images/Stack.svg";
import { Link } from "react-router-dom";
import { getItem, setItem } from "../utils/LocalStorageFunctions";
import { postData } from "../services/services/request";

const MembershipBought = () => {
  const urlParams = new URLSearchParams(window.location.search); // Get the query string from the current URL
  const sessionId = urlParams.get("session_id");
  //   console.log("IDDDDDDdd", sessionId);

  const placeMembership = async () => {
    const token = getItem("token");
    const tokens = token.replace(/"/g, "");
    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };
    console.log("TOKEN", config);

    const data = {};

    try {
      const response = await postData(
        `/userMemberships/handleSession?sessionId=${sessionId}`,
        data,
        config
      );
      console.log("Response done", response);
      setItem("membershipId", response?.data.stripeMembershipId);
    } catch (error) {
      console.error("Error Order confirm", error);
    }
  };

  useEffect(() => {
    placeMembership();
  }, [sessionId]); // Dependency on tokens and sessionId to avoid running without them

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-slate-200 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
        <div className="flex justify-center">
          <img src={logo8} />
        </div>
        <h1 className="font-semibold text-lg">
          Membership Successfully Purchased!
        </h1>
        <p className="text-gray-600 mb-6">
          Thank you for your purchase. Your membership has been successfully
          activated, and you're all set to enjoy the benefits.
        </p>
        <div className="flex items-center justify-center gap-5 mt-5">
          <div className="relative">
            <Link to="/">
              <button className="flex justify-center border-2 border-gray-300 p-2 w-[200px] font-semibold text-[#394e6c] px-10">
                GO TO HOME
              </button>
            </Link>

            <div className="absolute right-[160px] top-[52%] transform -translate-y-1/2">
              <img src={logo7} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipBought;
