import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo2 from "../assets/images/Arrow.jpg";
import logo3 from "../assets/images/Arrow-left-img.jpg";
import logo4 from "../assets/images/detol-product.jpg";
import logo5 from "../assets/images/lifeboy-product.jpg";
import logo6 from "../assets/images/detol-pouche-image.jpg";
import logo7 from "../assets/images/tick-Icon.svg";
import logo8 from "../assets/images/profile-Icon.svg";
import logo9 from "../assets/images/mark-loc-Icon.svg";
import logo10 from "../assets/images/pages-fold-Icon.svg";
import logo11 from "../assets/images/all-right-Icon.svg";
import logo12 from "../assets/images/book-notes-Icon.svg";
import logo13 from "../assets/images/true-Check.svg";
import logo14 from "../assets/images/Notebook.png";
import logo15 from "../assets/images/Package.svg";
import logo16 from "../assets/images/Truck.svg";
import logo17 from "../assets/images/Handshake.svg";
import logo19 from "../assets/images/Arrow.jpg";
import logo20 from "../assets/images/Arrow-left-img.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Recomendation from "./Recomendation";
import BellowHeader from "./BellowHeader";
import { getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Rating from "@mui/material/Rating";
import { TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

function OrderActivity() {
  const location = useLocation();
  const { id } = location.state || {};
  // console.log("HELLLLLOOOOO", id);
  const [order, setOrder] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  // console.log("ACtive", activeStep);
  const [value, setValue] = React.useState(0);
  const [reviewText, setReviewText] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [existingReview, setExistingReview] = useState(null);
  const [medicineIDD, setMEdicineIDD] = useState([]);
  // console.log("MEDICINEID", medicineIDD);
  const token = getItem("token");
  const tokens = token.replace(/"/g, "");
  const navigate = useNavigate();

  const SearchOrder = async () => {
    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    try {
      const response = await getData(`/orders/${id}`, config);
      setOrder(response.data);
      const medicineIds = response.data.items.map(
        (item) => item.medicineId._id
      );
      setMEdicineIDD(medicineIds);
      const status = response.data.status;
      // console.log("Stauts", status);
      switch (status) {
        case "PENDING":
          setActiveStep(0);
          break;
        case "PROCESSING":
          setActiveStep(1);
          break;
        case "SHIPPED":
          setActiveStep(2);
          break;
        case "DELIVERED":
          setActiveStep(3);
          break;
        default:
          setActiveStep(0); // Default to 0 if the status is unknown
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    SearchOrder();
  }, [id]);
  const fetchExistingReview = async () => {
    const userId = JSON.parse(localStorage.getItem("UserId"));
    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    try {
      const responses = await Promise.all(
        medicineIDD.map(async (medicineId) => {
          try {
            const response = await getData(
              `/ratings?medicineId=${medicineId}&userId=${userId}`,
              config
            );
            return {
              medicineId: medicineId,
              response: response.data || response, // Adjust based on your API response structure
            };
          } catch (err) {
            console.error(
              `Error fetching review for medicine ${medicineId}:`,
              err
            );
            return {
              medicineId: medicineId,
              response: { items: [] },
            };
          }
        })
      );

      const newReviews = responses.map((response) => ({
        medicineId: response.medicineId,
        review: response.response.items?.[0] || null,
      }));

      setReviews(newReviews);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    if (medicineIDD.length > 0) {
      fetchExistingReview();
    }
  }, [medicineIDD]);

  // Handle submitting a new review
  const handleSubmit = async (medicineId) => {
    if (!value || reviewText.trim() === "") {
      setError("Please provide both rating and review");
      return;
    }

    try {
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const userId = JSON.parse(localStorage.getItem("UserId"));

      const reviewData = {
        medicineId: medicineId,
        rating: value,
        review: reviewText.trim(),
      };

      const response = await postData("/ratings", reviewData, config);

      if (response.data) {
        // Check for response.data instead of response.status
        // Update the reviews state immediately
        setReviews((prevReviews) =>
          prevReviews.map((review) => {
            if (review.medicineId === medicineId) {
              return {
                ...review,
                review: {
                  rating: value,
                  review: reviewText,
                  medicineId: medicineId,
                  userId: userId,
                },
              };
            }
            return review;
          })
        );

        // Reset form
        setReviewText("");
        setValue(0);
        setReviewData(null);
        setError("");

        // Fetch updated reviews
        await fetchExistingReview();
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      setError(
        error.response?.data?.message ||
          "Failed to submit review. Please try again."
      );
    }
  };

  const steps = [
    <div className="mt-2 flex flex-col justify-center" key="order-placed">
      <div className="flex justify-center">
        <img src={logo14} className="h-[35px] w-10 " alt="Order Icon" />
      </div>
      <h1 className="text-center">Order Placed</h1>
    </div>,
    <div className="mt-2 flex flex-col justify-center">
      <img src={logo15} className="h-[35px]" alt="Order Icon" />
      <h1 className="text-center">Packaging</h1>
    </div>,
    <div className="mt-2 flex flex-col justify-center">
      <img src={logo16} className="h-[35px]" alt="Order Icon" />
      <h1 className="text-center">In Transit </h1>
    </div>,
    <div className="mt-2 flex flex-col justify-center">
      <img src={logo17} className="h-[35px]" alt="Order Icon" />
      <h1>Delivered</h1>
    </div>,
  ];

  return (
    <>
      <Header />
      <div className="pt-28 px-6">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ClearIcon />
        </button>
      </div>
      <div className="container mx-auto  max-w-[1000px] mb-10 ">
        <div className="items-center flex justify-center px-4">
          <div className="bg-[#fdfae7] p-4 border-2 border-[#f8edb0] mt-5 max-w-[750px] w-[100%]">
            {order ? (
              <div>
                <div className="flex text-center items-center justify-between">
                  <div>
                    <h1 className="font-semibold">#{order.id}</h1>
                  </div>
                  <div className="mt-4 mr-4">
                    <button className="text-xl font-bold text-blue-400 rounded-md p-2">
                      <span className="p-1">$</span>
                      {order.totalAmount}
                    </button>
                  </div>
                </div>
                <div>
                  <p font-semibold>
                    {Array.isArray(order.items) && order.items.length > 0
                      ? `${order.items.length} Products`
                      : "No Products"}{" "}
                    {/* {new Date(order.createdAt).toLocaleDateString()} at{" "}
                    {new Date(order.createdAt).toLocaleTimeString()} */}
                  </p>
                </div>
              </div>
            ) : (
              <p>Loading order...</p>
            )}
          </div>
        </div>

        {/* <div className="flex justify-start xs: px-14 py-3 md:px-24 lg:px-32  mt-2 ">
          <div>
            <h1 className="text-gray-400 font-semibold text-lg">
              Order expected delivery
              <span className="text-black ml-2">23 Jan, 2021</span>
            </h1>
          </div>
        </div> */}
        {/* progress bar */}
        <div className="max-w-[775px] mx-auto items-center m-5 py-10">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        {/* order activity */}
        <div className=" md:border-2 px-10 mb-10 md:mx-10 mt-3 py-10">
          <div className=" w-full items-center justify-center ">
            <div>
              <h1 className="font-semibold text-xl">Order Activity</h1>
            </div>

            {activeStep === 3 && (
              <div className="flex mt-5">
                <div className="">
                  <img src={logo7} alt="" />
                </div>
                <div className="ml-4">
                  <h1>
                    Your order has been delivered. Thank you for shopping at
                    CareSimpliCity!
                  </h1>
                </div>
              </div>
            )}
            {activeStep === 3 && (
              <div className="mt-8">
                <h1 className="text-[30px] font-bold text-center mb-6">
                  Order Reviews
                </h1>

                {/* Iterate through each medicine */}
                {order.items.map((item) => {
                  const medicineId = item.medicineId._id;
                  const existingReview = reviews.find(
                    (review) => review.medicineId === medicineId
                  );

                  return (
                    <div
                      key={medicineId}
                      className="mb-8 border rounded-lg p-4 shadow-sm"
                    >
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex gap-10">
                          <div>
                            <img
                              src={item.medicineId.image[0]}
                              alt="Avatar"
                              className="rounded-full h-[100px]"
                            />
                          </div>
                          <div>
                            <h3 className="font-semibold text-lg">
                              {item.medicineId.medicineName}
                            </h3>
                            <p className="text-gray-600 text-sm">
                              ID: {medicineId}
                            </p>
                          </div>
                        </div>

                        {!existingReview?.review && (
                          <button
                            onClick={() => setReviewData(medicineId)}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                          >
                            Add Review
                          </button>
                        )}
                      </div>

                      {/* Display existing review if available */}
                      {existingReview?.review && (
                        <div className="bg-gray-50 p-4 rounded-md">
                          <div className="flex items-center mb-2">
                            <Rating
                              name="read-only"
                              value={existingReview.review.rating}
                              readOnly
                              sx={{ fontSize: 20 }}
                            />
                            <span className="ml-2 text-gray-600">
                              ({existingReview.review.rating}/5)
                            </span>
                          </div>
                          <p className="text-gray-700">
                            {existingReview.review.review}
                          </p>
                        </div>
                      )}

                      {/* Review form - only show when reviewData matches this medicine */}
                      {reviewData === medicineId && !existingReview?.review && (
                        <div className="mt-4 bg-gray-50 p-4 rounded-md">
                          <div className="mb-4">
                            <h4 className="font-medium mb-2">Rating*</h4>
                            <Rating
                              name="simple-controlled"
                              value={value}
                              onChange={(event, newValue) => setValue(newValue)}
                              sx={{ fontSize: 24 }}
                            />
                          </div>

                          <TextField
                            className="w-full mb-4"
                            placeholder="Write your review here..."
                            multiline
                            rows={3}
                            value={reviewText}
                            onChange={(e) => setReviewText(e.target.value)}
                          />

                          {error && (
                            <p className="text-red-500 text-sm mb-3">{error}</p>
                          )}

                          <div className="flex gap-3 py-5">
                            <button
                              onClick={() => handleSubmit(medicineId)}
                              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                            >
                              Submit Review
                            </button>
                            <button
                              onClick={() => {
                                setReviewData(null);
                                setError("");
                                setReviewText("");
                                setValue(0);
                              }}
                              className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {activeStep === 2 && (
              <div className="flex mt-5">
                <div className="">
                  <img src={logo8} alt="" />
                </div>
                <div className="ml-4">
                  <h1>
                    Our delivery man has picked-up your order for delivery.
                  </h1>
                  <p className="text-gray-400"></p>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className="flex mt-5">
                <div className="">
                  <img src={logo9} alt="" />
                </div>
                <div className="ml-4">
                  <h1>Your order is being packed.</h1>
                </div>
              </div>
            )}
            {activeStep === 0 && (
              <div className="flex mt-5">
                <div className="">
                  <img src={logo12} alt="" />
                </div>
                <div className="ml-4">
                  <h1>Your order has been confirmed</h1>
                  <p className="text-gray-400"></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Recomendation */}
      <Recomendation />

      <Footer />
    </>
  );
}

export default OrderActivity;
