import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { getItem } from "../utils/LocalStorageFunctions";
import { getData, postData, updateData } from "../services/services/request";
import { useNavigate } from "react-router-dom";

function EditAddress({
  open,
  onClose,
  formData,
  setFormData,
  handleInputChange,
  error,
  AddressAPI,
  selectedId,
}) {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [errror, setError] = useState(null);
  const [countryCode, setCountryCode] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idvalue, setIdvalue] = useState(null);
  console.log("ID", idvalue);
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchCode, setSearchCode] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    formData.countryCode || ""
  );
  const token = getItem("token");

  const fetchAddressData = async () => {
    try {
      const tokens = token.replace(/"/g, "");
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await getData(`/addresses/${formData.id}`, config);

      if (response && response.data) {
        // Set form data with existing address data
        setFormData({
          firstname: response.data.firstName || "",
          lastname: response.data.lastName || "",
          email: response.data.email || "",
          phoneNumber: response.data.phoneNumber || "",
          address: response.data.address || "",
          city: response.data.city || "",
          zipcode: response.data.zipCode || "",
          Country: response.data.country || "",
          state: response.data.state || "",
        });
        setIdvalue(formData.id);

        // Set country code
        setSelectedCountryCode(response.data.phoneCode || "+91");

        // Fetch states for the selected country
        if (response.data.country) {
          const countryObj = countries.find(
            (c) => c.name === response.data.country
          );
          if (countryObj) {
            fetchStates(countryObj.code3);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation before submitting
    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.address ||
      !formData.city ||
      !formData.zipcode ||
      !formData.Country ||
      !formData.state
    ) {
      setError("Please fill all required fields.");
      return;
    }

    // Ensure phone number is numeric and of valid length
    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      setError("Invalid phone number. Please enter a valid 10-digit number.");
      return;
    }

    // Log the form data before sending the request
    // console.log("Form data on submit:", formData);

    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      address: formData.address,
      country: formData.Country,
      state: formData.state,
      city: formData.city,
      zipCode: formData.zipcode,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      phoneCode: selectedCountryCode,
    };

    try {
      const tokens = token.replace(/"/g, "");
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await updateData(`/addresses/${idvalue}`, data, config);

      if (response.code === 200) {
        onClose();
        AddressAPI(); // Refresh address list
      }
    } catch (error) {
      console.error("Error updating address:", error);
      setError(error.response?.data?.message || "Failed to update address");
    }
  };

  const Countrycall = async () => {
    try {
      const countryres = await getData("/auths/countries/List");
      setCountries(countryres.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await getData("/auths/countries/Pin");
      if (response.data) {
        setCountryCode(response.data);
        // If formData doesn't have a countryCode, set the first one as default
        if (!formData.countryCode && response.data.length > 0) {
          setSelectedCountryCode(response.data[0].dial_code);
        }
      }
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  const addressData = async () => {
    try {
      const tokens = token.replace(/"/g, "");

      if (!token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(`/addresses/${selectedId}`, config);

      if (response && response.data) {
        // setProfile(response.data);
        // console.log("Data", response.data);
        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };
  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    const selectedCountry = countries.find(
      (Country) => Country.code3 === countryCode
    );

    if (selectedCountry) {
      setFormData({
        ...formData,
        Country: selectedCountry.name,
        state: "",
      });

      // Update the phone country code based on country selection
      switch (selectedCountry.code3) {
        case "IND":
          setSelectedCountryCode("+91");
          break;
        case "USA":
          setSelectedCountryCode("+1");
          break;
        case "GBR":
          setSelectedCountryCode("+44");
          break;
        case "FRA":
          setSelectedCountryCode("+33");
          break;
        case "CAN":
          setSelectedCountryCode("+1");
          break;
        // Add more countries as needed
        default:
          setSelectedCountryCode("+91");
      }

      fetchStates(countryCode);
    }
  };

  const handleCodeSearch = (value) => {
    setSearchCode(value);
    setIsDropdownOpen(true);

    // Show all codes when input is empty
    if (!value.trim()) {
      setFilteredCountryCodes(countryCode);
      return;
    }

    const filtered = countryCode.filter(
      (country) =>
        country.dial_code.includes(value) ||
        country.code.toLowerCase().includes(value.toLowerCase()) ||
        country.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredCountryCodes(filtered);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountryCode(country.dial_code);
    setSearchCode(country.dial_code);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (open && formData.id) {
      Countrycall();
      addressData();
      fetchCountryCodes();
      fetchAddressData();
    }
  }, [open, formData.id]);

  const fetchStates = async (countryCode) => {
    try {
      setLoadingStates(true); // Set loading to true while fetching states
      const response = await getData(
        `/auths/states?countryCode=${countryCode}`
      );

      if (response.data && Array.isArray(response.data.states)) {
        setStates(response.data.states);
      } else {
        console.error("Invalid states data:", response.data);
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      setStates([]); // Fallback to an empty array on error
    } finally {
      setLoadingStates(false); // Set loading to false after fetching states
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded-md w-[500px]">
          <h2 className="text-2xl font-bold mb-4">Edit Address</h2>
          <form onSubmit={handleSubmit}>
            <div className="flex gap-2 mt-2">
              <div>
                <label className="block text-sm font-medium">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  className="w-[215px] border rounded p-1 mt-1"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  className="w-[215px] border rounded p-1 mt-1"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col lg:w-[430px] md:w-[440px] mt-2">
              <label
                htmlFor="country"
                className="flex text-gray-700 font-medium justify-start"
              >
                Phone Number
              </label>
              <div className="flex border rounded">
                <div className="relative" ref={dropdownRef}>
                  <input
                    type="text"
                    value={searchCode}
                    onChange={(e) => handleCodeSearch(e.target.value)}
                    onFocus={() => {
                      setIsDropdownOpen(true);
                      setFilteredCountryCodes(countryCode); // Show all codes on focus
                    }}
                    placeholder="+00"
                    className="w-16 px-2 py-2 border-r rounded-md focus:outline-none"
                  />
                  {isDropdownOpen && (
                    <div className="absolute left-0 top-full mt-1 w-48 max-h-48 overflow-y-auto bg-white border rounded-md shadow-lg z-50">
                      {filteredCountryCodes.map((country) => (
                        <div
                          key={country.code}
                          onClick={() => handleCountrySelect(country)}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                        >
                          <span className="font-medium">
                            {country.dial_code}
                          </span>
                          <span className="text-sm text-gray-600">
                            {country.name} ({country.code})
                          </span>
                        </div>
                      ))}
                      {filteredCountryCodes.length === 0 && (
                        <div className="px-3 py-2 text-gray-500">
                          No matches found
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter phone number"
                  maxLength="10"
                  className="flex-1 px-2 py-1 focus:outline-none"
                />
              </div>
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full border rounded p-1 mt-1"
                required
              />
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full border rounded p-1 mt-1"
                required
              />
            </div>
            <div className="flex gap-2 mt-2">
              <div>
                <label className="block text-sm font-medium">Country</label>
                <select
                  name="country"
                  value={formData.Country}
                  onChange={handleCountryChange}
                  className="w-[215px] border rounded p-2 mt-1"
                >
                  <option value="">{formData.Country || "Select"}</option>
                  {countries.map((country) => (
                    <option key={country.code3} value={country.code3}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium">State</label>
                <select
                  name="state"
                  value={formData.state || ""}
                  onChange={handleInputChange}
                  className="w-[215px] border rounded p-2 mt-1"
                  disabled={loadingStates} // Disable while states are loading
                >
                  <option value="">Select</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {loadingStates && <p>Loading states...</p>}{" "}
                {/* Show loading message */}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="mt-2">
                <label className="block text-sm font-medium">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-[215px] border rounded p-1 mt-1"
                  required
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium">Zip Code</label>
                <input
                  type="text"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleInputChange}
                  className="w-[215px] border rounded p-1 mt-1"
                  required
                />
              </div>
            </div>
            <p className="text-red-500 flex justify-center mb-2 text-[15px]">
              {errror}
            </p>

            <div className="flex justify-between mt-[10px]">
              <button
                type="button"
                onClick={() => onClose()}
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default EditAddress;
