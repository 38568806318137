import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../components/Redux/Slices/LoginSlice";
import CategoryReducer from "../components/Redux/Slices/CategorySlice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    Category:CategoryReducer
  },
});

export default store;
