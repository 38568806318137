import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import reactangle from "../assets/images/Rectangle.jpg";
import logo1 from "../assets/images/Truck-img.svg";
import logo2 from "../assets/images/LockOpen.svg";
import logo3 from "../assets/images/CreditCard-image.svg";
import logo4 from "../assets/images/Notepad-image.svg";
import logo5 from "../assets/images/Storefront.svg";
import logo6 from "../assets/images/call-Icon.svg";
import logo7 from "../assets/images/Stack.svg";
import logo8 from "../assets/images/message-Icon.svg";
import logo10 from "../assets/images/venmo-Icon.svg";
import logo11 from "../assets/images/paypal-image .svg";
import logo12 from "../assets/images/amazon-icon-1 1.svg";
import logo13 from "../assets/images/CreditCard.svg";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function Support() {
  const [supportOptions] = useState([
    { id: 1, img: logo1, text: "Track Order" },
    { id: 2, img: logo2, text: "Reset Password" },
    { id: 3, img: logo3, text: "Payment Option" },
    { id: 4, img: logo4, text: "Shipping & Billing" },
    {
      id: 5,
      img: logo5,
      text: "Shopping Cart & Wallet",
      // path: "/orderactivity",
    },
    { id: 6, img: logo1, text: "Sell on Clicon" },
  ]);
  const [popularTopics] = useState([
    [
      "How do I return my item?",
      "What is Clicons Returns Policy?",
      "How long is the refund process?",
    ],
    [
      "What are the 'Delivery Timelines'?",
      "What is the 'Discover Your Daraz Campaign 2022'?",
      "What is the Voucher & Gift Offer in this Campaign?",
    ],
    [
      "How to cancel Clicon Order?",
      "Ask the digital and Device Community",
      "How to change my shop name?",
    ],
  ]);

  // State to handle modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [query, setQuery] = useState("");

  const handleSearch = () => {
    if (query.trim() === "") {
      alert("Please enter a search query!");
    } else {
      console.log("Searching for:", query);
      // Perform search logic here
    }
  };
  return (
    <>
      <Header />
      <div className="lg:flex justify-start items-start  ">
        {" "}
        <button className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50] ">
          <Link to="/">
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </Link>
        </button>
      </div>
      {/* Background Section */}
      <div
        className="w-full h-[335px] bg-none md:bg-center md:bg-no-repeat  -ml-5"
        style={{
          backgroundImage: `url(${reactangle})`,
        }}
      >
        {/* <div
        className="w-full mx-auto h-[335px]"
        style={{ backgroundImage: url(${reactangle}) }}
      > */}
        <div className="flex flex-col xs:justify-center items-center min-h-96 ">
          <div className="flex items-start lg:justify-start w-[432px] xs:justify-center">
            <button className=" text-start text-black font-semibold bg-[#EFD33D] px-6 p-2">
              HELP CENTER
            </button>
          </div>
          <div className="lg:w-[432px] md:w-[430px] w-[430px] mt-3 xs: flex xs:justify-center lg:justify-start">
            <h1 className="text-start text-xl font-semibold">
              How we can help you!
            </h1>
          </div>
          {/* Search Bar */}
          {/* <div className="relative flex items-center w-[90%] sm:w-[60%] lg:w-[36%] lg:justify-start mt-2 border border-gray-300">
            <input
              type="text"
              placeholder="Enter your question or keyword"
              className=" pb-[18px] w-[75%] pt-[19px] pl-[35px]  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 "
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 pointer-events-none text-[#FA8A3F]"
            />
            <button className="text-white bg-[#394E6C] absolute right-3 p-2 text-sm font-semibold rounded-md w-[90px]">
              SEARCH
            </button>
          </div> */}

          <div className="relative flex items-center w-[90%] sm:w-[60%] lg:w-[36%] lg:justify-start mt-2">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter your question or keyword"
              className="pb-[18px] w-[1000px] pt-[19px] pl-[35px] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500  border border-gray-300"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 pointer-events-none text-[#FA8A3F]"
            />
            <button
              onClick={handleSearch}
              className="text-white bg-[#394E6C] absolute right-3 p-2 text-sm font-semibold rounded-md w-[90px]"
            >
              SEARCH
            </button>
          </div>
        </div>
      </div>
      {/* Support Options */}
      {/* <div className="flex flex-col justify-center mt-10">
        <div className="md:text-center px-5">
          <h1 className="text-xl font-semibold">
            What can we assist you with today?
          </h1>
        </div>
        <div className="flex lg:justify-center md:justify-center px-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 py-6">
            {supportOptions.map((option) => (
              <Link to={option.path}>
                <div
                  key={option.id}
                  className="flex border-2 items-center px-4 py-2 gap-4 w-full sm:w-[280px]"
                >
                  <img src={option.img} alt={option.text} />
                  <div>{option.text}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div> */}
      {/* Popular Topics */}
      {/* <div className="flex flex-col mt-10">
        <div>
          <h1 className="text-xl font-semibold md:text-center text-start px-10">
            Popular Topics
          </h1>
        </div>
        <div className="flex flex-wrap justify-center gap-5 px-5 lg:flex-nowrap lg:gap-0">
          {popularTopics.map((column, index) => (
            <div
              key={index}
              className="w-full lg:w-[33%]  mt-10 flex md:justify-center md:text-center lg:text-start"
            >
              <div className="flex flex-col px-4 lg:px-16">
                {column.map((topic, i) => (
                  <h1 key={i} className={${i !== 0 && "mt-3"}}>
                    {topic}
                  </h1>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div> */}
      {/* Contact Methods */}
      <div className="bg-[#F2F4F5] py-5 mt-4">
        <div className="py-10">
          <div className="flex justify-center">
            <button className="bg-[#2DA5F3] text-white  px-6 py-2 rounded-md ">
              CONTACT US
            </button>
          </div>
          <div className="flex justify-center">
            <h1 className="font-bold  text-2xl py-3">
              Don’t find your answer.{" "}
              <span className="flex justify-center">Contact with us</span>
            </h1>
          </div>
        </div>
        {/* call us content */}
        <div className="flex flex-wrap gap-5 justify-center py-2">
          {[logo6, logo8].map((img, idx) => (
            <div
              key={idx}
              className="shadow-md bg-white w-full sm:w-[443px] flex px-4 py-6"
            >
              {/* Image Section */}
              <div className="mr-4 flex-shrink-0">
                <img src={img} alt="Contact Method" className="w-[50px]" />
              </div>
              {/* Content Section */}
              <div className="flex flex-col">
                {idx === 0 ? (
                  <>
                    <h1 className="text-black text-lg font-semibold">
                      Call us now
                    </h1>
                    <p className="text-black">
                      We are available online from 9:00 AM to 5:00 PM
                      <br />
                      (GMT+5:45) Talk with us now
                    </p>
                    <h1 className="text-2xl font-semibold text-black mt-3">
                      +1-202-555-0126
                    </h1>
                    <button className="px-7 py-2 font-semibold bg-[#2DA5F3] text-white w-40 rounded-md mt-4">
                      CALL NOW
                    </button>
                  </>
                ) : (
                  <>
                    <h1 className="text-black text-lg font-semibold">
                      Email Us
                    </h1>
                    <p className="text-black">
                      Feel free to drop us an email anytime, and we’ll get back
                      to you within 24 hours.
                      <br />
                      Our support team is available around the clock.
                    </p>
                    <h1 className="text-2xl font-semibold text-black mt-3">
                      support@clicon.com
                    </h1>
                    <button className="px-7 py-2 font-semibold bg-[#2DB224] text-white w-40 rounded-md mt-4">
                      CONTACT US
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl">
              <h2 className="text-2xl font-bold mb-4">Payment Option</h2>

              <div className="flex  gap-2 justify-center  text-center items-center ">
                {/* venmo */}
                <div className="flex flex-col justify-center items-center text-center border-r-2  p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={logo10}
                      className="  mx-auto"
                      alt="Amazon Pay Logo"
                    />
                    <h1>venmo</h1>
                  </div>
                  <div className="items-center mt-2">
                    <label className="flex items-center justify-center w-6 h-6 border-2 border-gray-500 rounded-full cursor-pointer">
                      <input
                        type="radio"
                        name="radio"
                        className="hidden peer"
                      />
                      <span className="w-4 h-4 rounded-full bg-transparent peer-checked:bg-blue-500"></span>
                    </label>
                  </div>
                </div>
                {/*  pay pal */}
                <div className="flex flex-col justify-center items-center text-center border-r-2 p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={logo11}
                      className=" mx-auto"
                      alt="Amazon Pay Logo"
                    />
                    <h1> Paypal</h1>
                  </div>
                  <div className="items-center mt-2">
                    <label className="flex items-center justify-center w-6 h-6 border-2 border-gray-500 rounded-full cursor-pointer">
                      <input
                        type="radio"
                        name="radio"
                        className="hidden peer"
                      />
                      <span className="w-4 h-4 rounded-full bg-transparent peer-checked:bg-blue-500"></span>
                    </label>
                  </div>
                </div>
                {/* amazon pay */}
                <div className="flex flex-col justify-center items-center text-center border-r-2 p-6 ">
                  <div className="flex flex-col items-center">
                    <img
                      src={logo12}
                      className="  mx-auto"
                      alt="Amazon Pay Logo"
                    />
                    <h1 className="">Amazon Pay</h1>
                  </div>
                  <div className="items-center mt-2">
                    <label className="flex items-center justify-center w-6 h-6 border-2 border-gray-500 rounded-full cursor-pointer">
                      <input
                        type="radio"
                        name="radio"
                        className="hidden peer"
                      />
                      <span className="w-4 h-4 rounded-full bg-transparent peer-checked:bg-blue-500"></span>
                    </label>
                  </div>
                </div>
                {/* amazon pay */}
                <div className="flex flex-col justify-center items-center text-center ">
                  <div className="flex flex-col items-center">
                    <img
                      src={logo13}
                      className=" mx-auto"
                      alt="Amazon Pay Logo"
                    />
                    <h1>Debid/Credit Card</h1>
                  </div>
                  <div className="items-center mt-2">
                    <label className="flex items-center justify-center w-6 h-6 border-2 border-gray-500 rounded-full cursor-pointer">
                      <input
                        type="radio"
                        name="radio"
                        className="hidden peer"
                      />
                      <span className="w-4 h-4 rounded-full bg-transparent peer-checked:bg-blue-500"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block mb-2">Name on Card</label>
                <input
                  type="text"
                  className="border rounded p-2 w-full"
                  placeholder="Enter cardholder's name"
                />
                <label className="block mt-4 mb-2">Card Number</label>
                <input
                  type="text"
                  className="border rounded p-2 w-full"
                  placeholder="1234 5678 9012 3456"
                />
                <div className="flex gap-3 mt-4">
                  <div className="flex flex-col">
                    {" "}
                    <label className="block mt-4 mb-2">Expire Date</label>
                    <input
                      type="text"
                      className="border rounded p-2 w-[270px] mr-2"
                      placeholder="MM/YY"
                    />
                  </div>

                  <div className="flex flex-col">
                    {" "}
                    <label className="block mt-4 mb-2">CVC</label>
                    <input
                      type="text"
                      className="border rounded p-2 w-[240px] mr-2"
                      // placeholder="MM/YY"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={closeModal}
                  className="mr-4 bg-gray-300 text-gray-700 py-2 px-4 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setIsPopupOpen(true)} // Show popup
                  className="bg-[#394e6c] text-white py-2 px-4 rounded"
                >
                  Done
                </button>

                {/* Popup */}
                {isPopupOpen && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
                      <div className="flex justify-center">
                        <img src={logo8} />
                      </div>
                      <h1 className="font-semibold text-lg">
                        Your payment is successfully place
                      </h1>
                      <p className="text-gray-600 mb-6">
                        Pellentesque sed lectus nec tortor tristique accumsan
                        quis dictum risus. Donec volutpat mollis nulla non
                        facilisis
                      </p>
                      {/* <button
                      onClick={() => setIsPopupOpen(false)} // Close popup
                      className="bg-red-600 text-white py-2 px-4 rounded"
                    >
                      Close
                    </button> */}

                      <div className="flex items-center justify-center gap-5 mt-5">
                        <div className="relative">
                          <Link to="/">
                            <button className="flex justify-center border-2 border-gray-300 p-2 w-[200px] font-semibold text-[#394e6c] px-10">
                              GO TO HOME
                            </button>
                          </Link>

                          <div className="absolute right-[160px] top-[52%] transform -translate-y-1/2">
                            <img src={logo7} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
export default Support;
