import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import logo1 from "../assets/images/CheckCircle.svg";
import logo2 from "../assets/images/Arrow.jpg";
import logo3 from "../assets/images/Arrow-left-img.jpg";
import logo4 from "../assets/images/detol-product.jpg";
import logo5 from "../assets/images/lifeboy-product.jpg";
import logo6 from "../assets/images/detol-pouche-image.jpg";
import logo7 from "../assets/images/Stack.svg";
import logo8 from "../assets/images/ArrowRight.svg";
import logo19 from "../assets/images/Arrow.jpg";
import logo20 from "../assets/images/Arrow-left-img.jpg";

import BellowHeader from "./BellowHeader";
import Recomendation from "./Recomendation";
import { postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";
function ProductConfirmation() {
  const urlParams = new URLSearchParams(window.location.search); // Get the query string from the current URL
  const sessionId = urlParams.get("session_id");
  console.log("IDDDDDDdd", sessionId);
  const Confrimorder = async () => {
    const token = getItem("token");
    const tokens = token.replace(/"/g, "");
    if (!token) {
      console.error("UserId or Token not found in localStorage");
      return;
    }
    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    const data = {};

    try {
      const response = await postData(
        `/payments/handlePayment?sessionId=${sessionId}`,
        data,
        config
      );
      console.log("Response done", response);
    } catch (error) {
      console.error("Error Order confrim", error);
    }
  };
  useEffect(() => {
    Confrimorder();
  }, []);
  return (
    <>
      <Header />
      <div className="container mx-auto  max-w-[1200px] pt-16  overflow-x-hidden">
        {/* <BellowHeader /> */}
        <div className="flex justify-center mt-10">
          <img src={logo1} alt="" />
        </div>
        <div className="flex justify-center mt-5 sm: px-12 text-center">
          <h1 className="text-2xl font-semibold ">
            Your Order is successfully placed
          </h1>
        </div>
        <div className="flex justify-center items-center mt-4 ">
          <h1 className="text-[#5F6C72] flex justify-center items-center text-center w-[80%] md:w-[35%]">
            Thank you for your purchase! Your order has been successfully placed
            and is now being processed. You’ll receive a confirmation email
            shortly with the details of your order.
          </h1>
        </div>
        <div className="mb-20 flex items-center gap-24 justify-center mt-5 md:gap-20">
          <div className="flex gap-5">
            <Link to="/">
              {" "}
              <button className="flex gap-2 border-2 px-5 py-3 text-[#394E6C]">
                <img src={logo7} alt=""></img>
                Go to Home
              </button>
            </Link>

            <Link to="/orderhistory">
              {" "}
              <button className="flex gap-2 border-2 px-5 py-3 bg-[#394E6C] border-[#394E6C] text-white">
                View order
                <img src={logo8} alt=""></img>
              </button>
            </Link>
          </div>
        </div>
        {/* <Recomendation /> */}
      </div>
      <Footer />
    </>
  );
}
export default ProductConfirmation;
