import React, { useState, useEffect, useRef } from "react";
import logo1 from "../assets/images/caresimply-main-logo2.svg";
import logo2 from "../assets/images/sofia-profesional-download.jpg";
import cartImage from "../assets/images/Carts.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { deleteItem, getItem, setItem } from "../utils/LocalStorageFunctions";
import { removeItem } from "../Lib/localStorage/localStorage";
import { getData } from "../services/services/request";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "./Redux/Slices/CategorySlice";
import SideNavFilter from "./SideNavFilter";
import CircularProgress from "@mui/material/CircularProgress";
import Usecartlogic from "./Hooks/usecartlogic";

function Header() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [mobileSearch, setmobileSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const Token = getItem("token");
  const [profileimg, setProfileimg] = useState([]);
  const [prpfilename, setPrpfilename] = useState([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const scrollRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDrawer = (value) => {
    setOpenDrawer(value);
  };
  const handleLogout = () => {
    setShowLogoutModal(true); // Open the popup on clicking logout
  };
  const confirmLogout = async () => {
    setIsLoggingOut(true); // Start loading

    try {
      // Simulate API call with timeout (remove this if you have actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Clear all stored data
      removeItem("token");
      removeItem("UserId");
      removeItem("Name");
      removeItem("Img");

      setShowLogoutModal(false);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoggingOut(false); // Stop loading
    }
  };
  const cancelLogout = () => {
    setShowLogoutModal(false); // Close the popup
  };
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Category.categories);

  const Profiledata = async () => {
    try {
      const userId = JSON.parse(getItem("UserId"));
      const token = getItem("token");

      if (!userId || !token) {
        console.error("UserId or Token not found in localStorage");
        return;
      }

      const tokens = token.replace(/"/g, ""); // this will be safe now

      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData(`/users/${userId}`, config);

      if (response && response.data) {
        setProfileimg(response.data.imgUrl);
        setPrpfilename(response.data.fullName);
        setLoading(false);
      } else {
        console.error("No data found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Profile:", error);
      setLoading(false);
    }
  };
  // const { items, Cartdata } = Usecartlogic();

  const Cartdata = async () => {
    const token = getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const tokens = token.replace(/"/g, ""); // safe now

    try {
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };

      const response = await getData("carts/getCart/ByToken", config);

      if (response && response.data && Array.isArray(response.data.items)) {
        const itemsLength =
          response.data.items.length > 0 ? response.data.items.length : 0;
        setItems(itemsLength);
      } else {
        console.error("No data or items found in the response");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data in Cart:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Profiledata();
    Cartdata();
    dispatch(fetchCategories({ pageNo: 100 }));
  }, [dispatch]);

  useEffect(() => {
    let scrollInterval;

    if (data.length > 7) {
      scrollInterval = setInterval(() => {
        if (scrollRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

          // Check if we've reached the end or start
          if (scrollLeft >= scrollWidth - clientWidth) {
            setScrollDirection(-1); // Reverse direction
          } else if (scrollLeft <= 0) {
            setScrollDirection(1); // Forward direction
          }

          // Smooth scroll
          scrollRef.current.scrollLeft += scrollDirection;
        }
      }, 30); // Adjust speed by changing this value
    }

    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [data.length, scrollDirection]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 20);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const DrawerList = (
    <div
      sx={{ width: 350 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <div className="pl-[3] pt-[5] relative ">
        <CloseIcon
          sx={{ position: "absolute", right: 15, top: 10, cursor: "pointer" }}
          onClick={() => toggleDrawer(false)}
        />
        <img
          src={profileimg}
          className="rounded-full w-[59px] h-[59px] mb-4 mt-5 ml-5"
          alt="User avatar"
        />
        <div className=" mb-[2] py-2 pl-4 font-bold border-b w-full">
          {prpfilename}
        </div>
      </div>
      <ul>
        <li>
          <Link to="/profilesection">
            <button className="w-full text-left py-2 px-4 hover:bg-gray-200">
              Profile
            </button>
          </Link>
        </li>
        <li>
          <Link to="/newaddress">
            <button className="w-full text-left py-2 px-4 hover:bg-gray-200">
              Address Book
            </button>
          </Link>
        </li>
        <li>
          <Link to="/orderhistory">
            <button className="w-full text-left py-2 px-4 hover:bg-gray-200">
              My Orders
            </button>
          </Link>
        </li>
        <li>
          <Link to="/premiummembership">
            {" "}
            <button className="w-full text-left py-2 px-4 hover:bg-gray-200">
              Care Simply Membership
            </button>
          </Link>
        </li>
        <li>
          <Link to="/support">
            {" "}
            <button className="w-full text-left py-2 px-4 hover:bg-gray-200">
              Need Help
            </button>
          </Link>
        </li>
        <li>
          <button
            onClick={handleLogout}
            className="w-full text-left py-2 px-4 hover:bg-gray-200"
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
  return (
    <>
      {isLoggingOut && (
        <div className="fixed inset-0 backdrop-blur-sm bg-black/30 flex justify-center items-center z-[9999]">
          <div className="bg-white/80 backdrop-blur-md p-8 rounded-lg shadow-xl flex flex-col items-center">
            <CircularProgress size={40} className="mb-4" />
            <p className="text-lg font-semibold text-gray-700">
              Logging out...
            </p>
          </div>
        </div>
      )}

      {showLogoutModal && (
        <div className="fixed inset-0 backdrop-blur-sm bg-black/30 flex justify-center items-center z-50">
          <div className="bg-white/90 backdrop-blur-sm rounded-lg p-6 w-80 shadow-xl">
            <h3 className="text-lg font-semibold text-center">
              Are you sure you want to logout?
            </h3>
            <div className="flex flex-col justify-between mt-4">
              <button
                onClick={confirmLogout}
                disabled={isLoggingOut}
                className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full flex items-center justify-center ${
                  isLoggingOut ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                {isLoggingOut ? (
                  <>
                    <CircularProgress
                      size={20}
                      color="inherit"
                      className="mr-2"
                    />
                    Logging out...
                  </>
                ) : (
                  "Logout"
                )}
              </button>
              <button
                onClick={cancelLogout}
                disabled={isLoggingOut}
                className={`bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 mt-4 ${
                  isLoggingOut ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <header
        className={`
          fixed 
          w-full 
          z-50 
          transition-all 
          duration-300 
          ease-in-out
          bg-white
          shadow-md
          ${visible ? "top-0" : "-top-[50px]"}
        `}
      >
        <div className="">
          <div className="header-nav-bar">
            <div className="relative w-full flex justify-between md:items-center px-4 bg-white shadow-sm">
              <div className="flex gap-10">
                <div>
                  <Link to="/">
                    <img
                      src={logo1}
                      alt="Logo"
                      className="lg:h-[50px] md:h-[35px] h-[50px]"
                    />
                  </Link>
                </div>
                {/* <div className="hidden md:relative md:flex md:items-center lg:w-[410px] h-[45]">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="md:block w-full md:border md:rounded-md md:pl-4 md:pr-10 md:py-2"
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="absolute right-5 text-gray-500"
                    onClick={() => setSearchQuery("")}
                  />
                </div> */}
              </div>
              {/* {mobileSearch ? (
                <div className="absolute flex items-center top-0 left-0 h-full w-full md:hidden">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full h-full border rounded-md pl-4 pr-10 focus:ring focus:ring-blue-500"
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="absolute right-10 text-gray-500"
                  />
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="absolute right-3 text-gray-500"
                    onClick={() => setmobileSearch(false)}
                  />
                </div>
              ) : null} */}
              <div className="flex items-center space-x-2 lg:pr-5">
                {/* {!mobileSearch && (
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-500 md:hidden"
                    onClick={() => setmobileSearch(true)}
                  />
                )} */}
                {Token ? null : (
                  <div>
                    <a href="/login">
                      <button className="px-4 py-1 bg-[#3FBAD9] text-white rounded hover:bg-[#33A9C7] focus:outline-none text-sm">
                        Sign In
                      </button>
                    </a>
                  </div>
                )}
                {Token ? (
                  <div className="flex gap-5">
                    <Link to="/cardcheckout">
                      <div className="flex items-center gap-3 relative">
                        <img
                          src={cartImage}
                          alt="Logo"
                          className="lg:h-[30px] md:h-[30px] h-[30px]"
                        />
                        {items > 0 && (
                          <span
                            className="absolute top-[-5px] right-[-5px] bg-red-500 text-white text-xs rounded-full px-2 py-1"
                            style={{ fontSize: "12px", padding: "1px 4px " }}
                          >
                            {items}
                          </span>
                        )}
                      </div>
                    </Link>

                    <button onClick={() => toggleDrawer(true)}>
                      <img
                        src={profileimg}
                        alt="User avatar"
                        className="rounded-full w-8 h-8"
                      />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="bg-black py-2 shadow-md w-full">
              <div className="container mx-auto">
                {/* Mobile Menu Button */}
                <div className="lg:hidden px-4">
                  <button 
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    className="text-white p-2 hover:bg-gray-800 rounded flex items-center gap-2"
                  >
                    <span>Categories</span>
                    <svg 
                      className={`w-4 h-4 transition-transform ${isMobileMenuOpen ? 'rotate-180' : ''}`} 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </div>

                {/* Desktop Menu */}
                <div
                  ref={scrollRef}
                  className="hidden lg:flex text-white overflow-x-auto scrollbar-hide px-4"
                  style={{
                    scrollBehavior: "smooth",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <div
                    className={`flex gap-4 md:gap-6 lg:gap-10 ${
                      data.length > 8 ? "animate-scroll" : "justify-center w-full"
                    } py-1`}
                  >
                    {Array.isArray(data) && data.length > 0 ? (
                      data.map((route, index) => (
                        <Link
                          key={index}
                          to={`/Prouductdetails/${route.id}`}
                          className="hover:text-blue-500 transition-colors text-sm md:text-base whitespace-nowrap flex-shrink-0"
                        >
                          {route.name}
                        </Link>
                      ))
                    ) : (
                      <p className="text-white">Loading categories...</p>
                    )}
                  </div>
                </div>

                {/* Mobile Menu Dropdown */}
                <div 
                  className={`lg:hidden overflow-hidden transition-all duration-300 ease-in-out ${
                    isMobileMenuOpen ? 'max-h-[400px]' : 'max-h-0'
                  }`}
                >
                  <div className="px-4 py-2">
                    {Array.isArray(data) && data.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {data.map((route, index) => (
                          <Link
                            key={index}
                            to={`/Prouductdetails/${route.id}`}
                            className="flex-shrink-0 bg-gray-800 text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm transition-colors"
                            onClick={() => setIsMobileMenuOpen(false)}
                          >
                            {route.name}
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white py-2">Loading categories...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        sx={{ zIndex: "999999999999999999999 !important" }}
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        anchor="right"
      >
        {DrawerList}
      </Drawer>
      <div className="pt-[header-height-in-pixels]">
        {/* Your main content */}
      </div>
    </>
  );
}
export default Header;
