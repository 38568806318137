import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import logo1 from "../assets/images/blue-Arrow-Button-left.svg";
import logo2 from "../assets/images/blue-Arro- Button-right.svg";
import OrdeEMpty from "../assets/images/orderlistempty.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../services/services/request";
import { Link, useNavigate } from "react-router-dom";
import { getItem } from "../utils/LocalStorageFunctions";

function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [expandable, setexpandable] = useState("");
  const [currentpage, setcurrentpage] = useState(1);
  const navigate = useNavigate();
  const ordersPerPage = 10;

  // Slice the orders array to show only 10 orders for the current page
  const currentOrders = orders.slice(
    (currentpage - 1) * ordersPerPage,
    currentpage * ordersPerPage
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(orders.length / ordersPerPage);
  const incPage = () => {
    if (currentpage < totalPages) {
      setcurrentpage(currentpage + 1);
    }
  };

  const decPage = () => {
    if (currentpage > 1) {
      setcurrentpage(currentpage - 1);
    }
  };

  function formatDate(dateString, format = "DD/MM/YYYY HH:mm:ss") {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    switch (format) {
      case "DD/MM/YYYY HH:mm:ss":
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      case "MM/DD/YYYY HH:mm:ss":
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
      case "YYYY-MM-DD HH:mm:ss":
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      case "Month DD, YYYY HH:mm:ss":
        return `${date.toLocaleString("default", {
          month: "long",
        })} ${day}, ${year} ${hours}:${minutes}`;
      default:
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  }
  const SearchOrder = async () => {
    const token = getItem("token") || ""; // Default to empty string if null
    const tokens = token.replace(/"/g, "");
    const userId = JSON.parse(getItem("UserId"));
    const pageNo = 1000;

    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    try {
      const response = await getData(
        `/orders?userId=${userId}&&pageSize=${pageNo}`,
        config
      );
      // console.log(" api dataaaa", response);
      const data = response.items;
      setOrders(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    SearchOrder();
  }, []);
  const orderstats = (id) => {
    navigate("/orderactivity", { state: { id } });
  };

  return (
    <>
      <Header />
      <div className="flex justify-center items-center py-4 px-4 lg:px-10 mt-24">
        <div className="border-2 w-full lg:w-[70%]">
          <div className="flex mt-4 ml-4">
            <h1 className="font-semibold text-lg lg:text-xl">Order History</h1>
          </div>

          {/* Check if orders are empty */}
          {orders.length === 0 ? (
            <div className="flex justify-center items-center mt-8">
              <img
                src={OrdeEMpty} // Image when there are no orders
                alt="No Orders"
                className="w-1/2"
              />
            </div>
          ) : (
            // Horizontal scrolling container
            <div className="mt-5 overflow-x-auto">
              <table className="min-w-full table-auto border-collapse ">
                <thead>
                  <tr className="bg-[#F2F4F5] border-b">
                    <th className="text-left px-2 lg:px-5 py-3 text-sm lg:text-base whitespace-nowrap">
                      ORDER ID
                    </th>
                    <th className="text-left px-2 lg:px-5 py-3 text-sm lg:text-base whitespace-nowrap">
                      STATUS
                    </th>
                    <th className="text-left px-2 lg:px-5 py-3 text-sm lg:text-base whitespace-nowrap">
                      DATE
                    </th>
                    <th className="text-left px-2 lg:px-5 py-3 text-sm lg:text-base whitespace-nowrap">
                      TOTAL
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <React.Fragment key={index}>
                      <tr className="border-b">
                        <td
                          onClick={() => {
                            orderstats(order.id);
                          }}
                          className="font-medium cursor-pointer  px-2 lg:px-5 py-3 text-sm lg:text-lg whitespace-nowrap"
                        >
                          {order.id}
                        </td>
                        <td
                          onClick={() => {
                            orderstats(order.id);
                          }}
                          className={`font-medium text-sm lg:text-lg px-2 lg:px-5 py-3 whitespace-nowrap ${
                            order.status === "DELIVERED"
                              ? "text-[#2DB224]"
                              : order.status === "CANCELLED"
                              ? "text-[#EE5858]"
                              : "text-[#FA8232]"
                          }`}
                        >
                          {order.status}
                        </td>
                        <td
                          onClick={() => {
                            orderstats(order.id);
                          }}
                          className="text-sm lg:text-lg px-2 lg:px-5 py-3 whitespace-nowrap"
                        >
                          {formatDate(order.createdAt)}
                        </td>
                        <td className="text-sm lg:text-lg px-2 lg:px-5 py-3 whitespace-nowrap">
                          ${order.totalAmount}
                        </td>
                        <td>
                          {expandable === order.id ? (
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              className="cursor-pointer"
                              onClick={() => setexpandable(null)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              className="mr-8 cursor-pointer"
                              onClick={() => setexpandable(order.id)}
                            />
                          )}
                        </td>
                      </tr>
                      {expandable === order.id ? (
                        <tr className="border-b">
                          <td colSpan="6" className="px-5 py-4">
                            <div className="bg-gray-100 rounded-lg p-4">
                              <table className="w-full">
                                <thead>
                                  <tr className="border-b bg-gray-200">
                                    <th className="px-2 lg:px-5 py-2 text-sm text-left">
                                      Product Name
                                    </th>
                                    <th className="px-2 lg:px-5 py-2 text-sm text-center">
                                      Quantity
                                    </th>
                                    <th className="px-2 lg:px-5 py-2 text-sm text-center">
                                      Price
                                    </th>
                                    <th className="px-2 lg:px-5 py-2 text-sm text-center">
                                      Total Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order?.items?.map((product) => {
                                    return (
                                      <tr
                                        key={product.id}
                                        className="border-b hover:bg-gray-50"
                                      >
                                        <td className="px-2 lg:px-5 py-2 text-sm text-left">
                                          {product.medicineId.medicineName}
                                        </td>
                                        <td className="px-2 lg:px-5 py-2 text-sm text-center">
                                          {product.quantity}
                                        </td>
                                        <td className="px-2 lg:px-5 py-2 text-sm text-center">
                                          ${product.medicineId.price}
                                        </td>
                                        <td className="px-2 lg:px-5 py-2 text-sm text-center">
                                          ${product.totalPrice}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-center mt-4 gap-4 py-5">
                <div>
                  <button onClick={decPage}>
                    <img src={logo1} alt="Previous" />
                  </button>
                </div>
                {[...Array(totalPages)].map((_, index) => (
                  <div
                    key={index}
                    className="h-[43px] w-[43px] flex items-center"
                  >
                    <h1
                      className={`border-2 rounded-full px-4 py-2 text-center cursor-pointer ${
                        currentpage === index + 1
                          ? "bg-[#667799] text-white"
                          : "bg-transparent text-black"
                      }`}
                      onClick={() => setcurrentpage(index + 1)}
                    >
                      {index + 1}
                    </h1>
                  </div>
                ))}
                <div>
                  <button onClick={incPage}>
                    <img src={logo2} alt="Next" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderHistory;
