import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import logo19 from "../assets/images/Arrow.jpg";
import logo20 from "../assets/images/Arrow-left-img.jpg";
import { getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";

const Recomendation = () => {
  const [product, setProduct] = useState([]);
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025, // for tablets and smaller screens
        settings: {
          slidesToShow: 2, // Show 3 slides on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // for mobile screens
        settings: {
          slidesToShow: 2, // Show 2 slides on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // for very small mobile screens
        settings: {
          slidesToShow: 1, // Show 1 slide on very small screens
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Scroll buttons
  const scrollPrev = () => {
    sliderRef.current?.slickPrev();
  };
  const scrollNext = () => {
    sliderRef.current?.slickNext();
  };
  const ProductSingleget = async () => {
    const pageNo = 1000;
    try {
      const response = await getData(`/medicines/?pageSize=${pageNo}`);
      // console.log("2nd api dmf;slklata", response);
      setProduct(response.items);
      // setImgary(response.data.image);

      // console.log("Product data", response.items);
    } catch (error) {}
  };
  useEffect(() => {
    ProductSingleget();
  }, []);

  const Buyapi = async (id) => {
    const payload = {
      item: [
        {
          medicineId: id,
          quantity: "1",
        },
      ],
    };
    try {
      const token = getItem("token");
      const tokens = token.replace(/"/g, "");
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        navigate("/login");
        return;
      }
      const config = {
        headers: {
          "x-access-token": tokens,
        },
      };
      const response = await postData("/carts/addToCart", payload, config);
      console.log("CARt data", response);
      navigate("/cardcheckout");
    } catch (error) {
      console.log("Errorin ADD to cart", error);
    }
  };

  return (
    <div>
      <div className="container mx-auto  max-w-[1300px] overflow-x-hidden ">
        <div className="flex text-center items-center justify-between px-4">
          <div>
            <h1 className="text-xl ml-3 font-semibold">Recommendation</h1>
          </div>
          <div className="flex gap-2 border-2 p-2 justify-center">
            <div className="border-r-2  p-2">
              <button onClick={scrollPrev} className=" p-2 ">
                <img src={logo19} alt="arrow" />
              </button>
            </div>
            <div className="p-2">
              <button onClick={scrollNext} className=" p-2   ">
                <img src={logo20} alt="arrow" />
              </button>
            </div>
          </div>
        </div>
        {/* Product Cards Section */}
        <Slider ref={sliderRef} {...settings}>
          {/* <div className="flex items-center justify-between gap-2 mt-4 relative hover:red-700  "> */}
          {product.map((product) => (
            <div
              key={product.id}
              className="w-[10%]  relative  p-5 md:pl-5 gap-3 "
            >
              <Link to="">
                <div className=" ">
                  {/* Image */}
                  <div className=" flex  ">
                    <img
                      src={product.image[0]}
                      className="h-[150px] w-[150px] md:mr-4 lg:pr-2 lg:mr-0"
                      alt="Backend"
                    />
                    <div className="flex flex-col">
                      {" "}
                      <h1 className="font-bold text-xl text-[#008080] mt-2">
                        {product.medicineName}
                      </h1>
                      <div className="flex mt-3 gap-5">
                        {" "}
                        <span className="text-black font-bold text-xl">
                          <span className="p-1">$</span>
                          {product.price}
                        </span>
                        {/* <span className="text-gray-300 text-xl line-through">
                          ${product.originalPrice}
                        </span> */}
                        {/* <span className="border-2 bg-[#394E6C] p-1 w-[24%] h-[35px] flex justify-center text-white rounded-md">
                          {product.discount}
                        </span> */}
                      </div>
                      <p className="border-2 hover:bg-[#394E6C] p-2 w-[100%] flex justify-center rounded-md mt-3 hover:text-white">
                        <Link
                          onClick={() => {
                            Buyapi(product.id);
                          }}
                        >
                          Add to cart
                        </Link>
                      </p>
                    </div>
                  </div>
                  {/* Product Details */}
                  <div className="p-4 ">
                    <div className="flex text-center items-center justify-between"></div>
                    <h1 className="flex flex-col ">
                      <span className="text-yellow-300">{product.rating}</span>
                    </h1>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          {/* </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default Recomendation;
