import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import logo1 from "../../assets/images/wakies-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import logo4 from "../../assets/images/spray-cream-images.jpg";
import logo5 from "../../assets/images/skin-care-images.jpg";
import logo6 from "../../assets/images/set-creamimages.jpg";
import {
  faWallet,
  faSignOutAlt,
  faClipboardList,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";

function Cancellationdetails() {
  const [startingactivateIndex, setStartingactivateIndex] = useState(null);

  const startingAccordion = (index) => {
    setStartingactivateIndex(startingactivateIndex === index ? null : index);
  };
  return (
    <>
      <Header />
      <div className="container mx-auto py-5 max-w-[1200px] ">
        <p className=" pb-10 text-blue-200 ">
          Home <span className="mx-2 text-black">{" > "}</span>My Account
          <span className="mx-2 text-black">{" > "}</span>
          <span className="text-black">Cancellation Details</span>
        </p>
      </div>

      <div className="container mx-auto max-w-[1200px]  ">
        <div className="flex">
          {/* Sidebar Menu */}
          <div className="w-[30%]">
            <div className="bg-white rounded-lg">
              <div className="flex items-center space-x-4 bg-[#f9fafc] p-4 rounded-md mb-6">
                {/* Profile section */}
                <img
                  src={logo1}
                  alt="Profile"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <h2 className="text-lg font-medium">Hello,</h2>
                  <p className="font-semibold text-xl">Christine A. Watkins</p>
                </div>
              </div>

              {/* Menu Items */}
              <ul className="space-y-6 bg-[#f9fafc]">
                <li className="flex items-center space-x-2 border-l-4 border-blue-500 pl-2">
                  <span className="text-lg">
                    <FontAwesomeIcon icon={faClipboardList} />
                  </span>
                  <Link to="/myorder">
                    <a href="#" className="font-medium">
                      My Orders
                    </a>
                  </Link>
                </li>
                <li className="flex items-center space-x-2 border-l-4 border-blue-500 pl-2">
                  <Link to="/whishlist">
                    <span className="text-lg">
                      <FontAwesomeIcon icon={faHeart} className="space-x-4" />
                    </span>
                    <a href="#" className=" font-medium m-2">
                      Wishlist
                    </a>
                  </Link>
                </li>

                {/* Brand section */}
                <div className="w-full relative">
                  <button
                    className=" block text-left items-center w-[100%] "
                    onClick={() => startingAccordion(1)}
                  >
                    <FontAwesomeIcon icon={faUser} className="" />
                    <span className="font-semibold text-gray-800 m-2">
                      Account Settings
                    </span>
                    <svg
                      className={`h-5 w-5 text-gray-500 transform transition-transform duration-300 absolute right-0 top-0 ${
                        startingactivateIndex === 1 ? "rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {startingactivateIndex === 1 && (
                    <ul className="ml-8 space-y-2">
                      <li>
                        <a href="#" className="text-gray-700">
                          Profile Information
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-gray-700">
                          <Link to="/addres">Manage Addresses</Link>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-gray-700">
                          Notification Preferences
                        </a>
                      </li>
                    </ul>
                  )}
                </div>

                <li className="flex items-center space-x-2">
                  <span className="text-lg">
                    {" "}
                    <FontAwesomeIcon icon={faWallet} className="" />
                  </span>
                  <a href="#" className="font-medium">
                    Payments
                  </a>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-lg">
                    <FontAwesomeIcon icon={faStethoscope} />
                  </span>
                  <a href="#" className="font-medium">
                    Medical History
                  </a>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-lg">
                    {" "}
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <a href="#" className="font-medium">
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Wishlist Section */}
          <div className="w-[70%] flex flex-col justify-center text-center   items-start h-full pb-4">
            <div className="pb-4 pl-5">
              <div className="flex items-center">
                <span className="mx-2 text-black">{"<"}</span>
                <h1 className="font-semibold text-4xl">Cancellation Details</h1>
              </div>

              {/* Second Order */}
              <div>
                <div className="flex justify-start p-5 w-full bg-[#f1f8ff] mt-6">
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between w-full">
                      <h1 className="text-black font-semibold">
                        Order No: #0258963110
                      </h1>
                      <p>
                        Total(3 items):
                        <span className="text-black font-semibold">$63.00</span>
                      </p>
                    </div>
                    <div className="flex justify-between w-full">
                      <p>
                        Order Date:
                        <span className="text-black font-semibold">
                          02 Dec 2022 6:40 PM
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#f9fafc]">
                  {/* progress bar */}
                  <div className="max-w-[775px] mx-auto items-center ">
                    <div className="flex justify-center">
                      <main className="main-wrapper w-[100%]">
                        <div className="steps-wrapper">
                          <div className="steps flex items-center justify-between relative mt-4">
                            <div className="flex justify-center w-full">
                              {/* First Order */}
                              <div className="flex flex-col items-center mx-4 w-[60%]">
                                <div className="w-[20px] h-[20px]">
                                  <span className="bg-sky-500 border border-sky-500 rounded-full w-full h-full flex items-center justify-center relative z-[999] ">
                                    <span className="block w-[10px] h-[10px]  rounded-full "></span>
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <h1>Cancellation in Progress</h1>
                                </div>
                              </div>

                              {/* Fourth Order */}
                              <div className="flex flex-col items-center mx-4 w-[40%]">
                                <div className="w-[20px] h-[20px]">
                                  <span className="bg-sky-500 border border-sky-500 rounded-full w-full h-full flex items-center justify-center z-[999] relative ">
                                    <span className="block w-[10px] h-[10px] bg-white rounded-full z-[999] relative"></span>
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <h1>In Progress</h1>
                                </div>
                              </div>
                              {/* Progress Bar */}
                              <div
                                className="progress-bar absolute top-[15%] left-[50%] w-[49%] h-[4px] bg-slate-400 z-[99]"
                                style={{ transform: "translateX(-42%)" }}
                              >
                                <span className="progress block h-full w-[74%] "></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>

                  {/* order verified */}
                  <div className="flex text-left p-5 bg-[#f1f8ff] m-5">
                    <div>
                      <p>
                        18 Oct 2022 2:40 PM
                        <span className="font-semibold p-2">
                          An item(s) from your order has been cancelled.
                        </span>
                      </p>
                      <p>
                        18 Oct 2022 2:40 PM
                        <span className="font-semibold p-2">
                          Your refund request is being processed.
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* cerave products section */}
                  <div>
                    {/* First SEction */}
                    <div className=" border-gray-200 pt-2 pb-4 border-b ">
                      <table className=" w-full">
                        <tbody>
                          <tr className="">
                            {/*First Td Checkbox */}

                            {/* Second Td */}
                            <td className="w-2/3">
                              <table>
                                <tr className="flex gap-4">
                                  {/* Image */}
                                  <td className="">
                                    <img
                                      src={logo4}
                                      alt="Product"
                                      className="w-20 h-20 rounded-md "
                                    />
                                  </td>

                                  {/* Content (Column-wise with 4 headings) */}
                                  <td className="align-top ">
                                    <div className="flex flex-col  ">
                                      <div className="flex justify-start">
                                        <h1>CERAVE</h1>
                                      </div>
                                      <div className="flex justify-start">
                                        <h1>
                                          CeraVe Acne Resurfacing Retinol Face
                                          Se
                                        </h1>
                                      </div>
                                      <div className="text-left">
                                        <p>
                                          <span className="font-semibold ">
                                            Reason
                                          </span>
                                          : Payment not completed on time
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            {/* Third Td */}
                            {/* -1 + Buttons */}
                            <td
                              className="align-top items-center flex "
                              align=""
                            >
                              <div>
                                <h1>Qty1</h1>
                              </div>
                            </td>
                            {/* Fourth Td */}

                            <td
                              className="align-top text-right"
                              align="align-left"
                            >
                              <div className="flex ">
                                <h1>$21.00</h1>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* Second SEction */}
                    <div className=" border-gray-200 pt-2 pb-4 border-b ">
                      <table className=" w-full">
                        <tbody>
                          <tr className="">
                            {/*First Td Checkbox */}

                            {/* Second Td */}
                            <td className="w-2/3">
                              <table>
                                <tr className="flex gap-4">
                                  {/* Image */}
                                  <td className="">
                                    <img
                                      src={logo5}
                                      alt="Product"
                                      className="w-20 h-20 rounded-md "
                                    />
                                  </td>

                                  {/* Content (Column-wise with 4 headings) */}
                                  <td className="align-top ">
                                    <div className="flex flex-col  ">
                                      <div className="flex justify-start">
                                        <h1>CERAVE</h1>
                                      </div>
                                      <div className="flex justify-start">
                                        <h1>
                                          CeraVe Acne Resurfacing Retinol Face
                                          Se
                                        </h1>
                                      </div>
                                      <div className="text-left">
                                        <p>
                                          <span className="font-semibold ">
                                            Reason
                                          </span>
                                          : Payment not completed on time
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            {/* Third Td */}
                            {/* -1 + Buttons */}
                            <td
                              className="align-top items-center flex "
                              align=""
                            >
                              <div>
                                <h1>Qty1</h1>
                              </div>
                            </td>

                            {/* Fourth Td */}

                            <td
                              className="align-top text-right"
                              align="align-left"
                            >
                              <div className="flex ">
                                <h1>$21.00</h1>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* Third SEction */}
                    <div className=" border-gray-200 pt-2 pb-4  ">
                      <table className=" w-full">
                        <tbody>
                          <tr className="">
                            {/*First Td Checkbox */}

                            {/* Second Td */}
                            <td className="w-2/3">
                              <table>
                                <tr className="flex gap-4">
                                  {/* Image */}
                                  <td className="">
                                    <img
                                      src={logo6}
                                      alt="Product"
                                      className="w-20 h-20 rounded-md "
                                    />
                                  </td>

                                  {/* Content (Column-wise with 4 headings) */}
                                  <td className="align-top ">
                                    <div className="flex flex-col  ">
                                      <div className="flex justify-start">
                                        <h1>CERAVE</h1>
                                      </div>
                                      <div className="flex justify-start">
                                        <h1>
                                          CeraVe Acne Resurfacing Retinol Face
                                          Se
                                        </h1>
                                      </div>
                                      <div className="text-left">
                                        <p>
                                          <span className="font-semibold ">
                                            Reason
                                          </span>
                                          : Payment not completed on time
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            {/* Third Td */}
                            {/* -1 + Buttons */}
                            <td
                              className="align-top items-center flex "
                              align=""
                            >
                              <div>
                                <h1>Qty1</h1>
                              </div>
                            </td>

                            {/* Fourth Td */}

                            <td
                              className="align-top text-right"
                              align="align-left"
                            >
                              <div className="flex ">
                                <h1>$21.00</h1>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Cancellationdetails;
