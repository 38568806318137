import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../assets/images/CheckCircle.svg";
import logo5 from "../assets/images/lifeboy-product.jpg";
import logo6 from "../assets/images/detol-pouche-image.jpg";
import logo7 from "../assets/images/Stack.svg";
import logo8 from "../assets/images/ArrowRight.svg";

function PaymentSucseful() {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div className="flex justify-center">
            <img src={logo1} />
          </div>
          <div className="mt-5">
            <h1 className="text-2xl font-semibold">
              Your payment is successfully placed
            </h1>
          </div>
          <div className="mt-4">
            <h1 className="w-[52%] mx-auto text-center">
              Pellentesque sed lectus nec tortor tristique accumsan quis dictum
              risus. Donec volutpat mollis nulla non facilisis.
            </h1>
          </div>
          <div className="flex items-center justify-center gap-5 mt-5">
            <div className="relative">
              <button className="border-2 border-gray-300 p-2 w-[160%] font-semibold text-[#394e6c]">
                GO TO HOME
              </button>

              <div className="absolute right-[80px] top-[50%] transform -translate-y-1/2">
                <img src={logo7} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSucseful;
