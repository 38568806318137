import React from "react";
import { Link, useLocation } from "react-router-dom";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const BellowHeader = () => {
  // const location = useLocation();
  // const currentPath = location.pathname.split("/")[1];
  // console.log("currentPath: ", currentPath);

  const location = useLocation();
  const currentPath = location.pathname.replace("/", "");

  return (
    <div>
      <div className="flex ">
        <div className="mt-7 md:ml-[20px] ml-[20px] lg:flex justify-start items-start lg:mr[50px]">
          {" "}
          <button className="rounded-full p-3 h-[40px]   bg-gray-200 hover:bg-[#4CAF50] ">
            <Link
              to={currentPath === "shippingpayment" ? "/cardcheckout" : "/"}
            >
              {" "}
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </Link>
          </button>
        </div>

        <div className="flex lg:gap-8 md:gap-8 justify-center border-b-2 mt-5 p-5 text-center  lg:w-full md:w-full  ">
          <div>
            <Link to="/cardcheckout">
              <h1
                className={` hover:text-[#3DC47E] font-semibold ${
                  currentPath === "cardcheckout"
                    ? "text-[#3DC47E]"
                    : "text-[#9D9D9D]"
                }`}
              >
                CART
              </h1>
            </Link>
          </div>
          <div>
            <Link to="/shippingpayment">
              <h1
                className={` hover:text-[#3DC47E] font-semibold ${
                  currentPath === "shippingpayment"
                    ? "text-[#3DC47E]"
                    : "text-[#9D9D9D]"
                }`}
              >
                SHIPPING & PAYMENT
              </h1>
            </Link>
          </div>
          <div>
            <div>
              <h1
                className={` font-semibold ${
                  currentPath === "productconfirmation"
                    ? "text-[#3DC47E]"
                    : "text-[#9D9D9D]"
                }`}
              >
                ORDER CONFIRMATION
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BellowHeader;
