import React, { useEffect, useState } from "react";
import { getData } from "../services/services/request";

export default function NewsLetter() {
  const [imgarry, setImgarry] = useState(null); // Start with null as the initial state

  const BannerFooterAPI = async () => {
    try {
      const response = await getData("/banners?placement=footer&status=active");
      const data = response.items;

      if (Array.isArray(data) && data.length > 0) {
        const imageUrl = data[0].imageUrl;
        setImgarry(imageUrl); // Set image URL if it's valid
      } else {
        console.error("No valid banners found or data is not an array");
        setImgarry(null); // Set to null if no valid image URL found
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setImgarry(null); // Set to null in case of an error
    }
  };

  useEffect(() => {
    BannerFooterAPI();
  }, []);

  return (
    <>
      <div className="flex justify-center px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 rounded-md h-[350px] w-full">
        <div className="h-full w-full flex justify-center shadow-xl">
          {/* Conditionally render the image or null */}
          {imgarry ? (
            <div className="h-full w-full">
              <img
                src={imgarry}
                className="w-full object-cover h-full rounded-md"
                alt="Footer banner"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
