import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import botavatar from "../assets/images/Frame 31284.png";
// Chatbot steps
const steps = [
  {
    id: "1",
    message: "Hi there! Welcome to the chatbot. What's your name?",
    trigger: "2",
  },
  {
    id: "2",
    user: true,
    trigger: "3",
  },
  {
    id: "3",
    message:
      "Nice to meet you, {previousValue}! Don't message otherwise I'll send your number to the police.",
    end: true,
  },
];

// Custom chatbot theme
const theme = {
  background: "#fff",
  fontFamily: "Arial",
  headerBgColor: "#3047EC",
  headerFontColor: "#fff",
  headerFontSize: "16px",
  botBubbleColor: "#F2F2F2",
  botFontColor: "#000000",
  userBubbleColor: "#E5EEFF",
  userFontColor: "#013499",
};
const CustomHeader = ({ setchatBotOpen }) => (
  <div className="relative w-full bg-[#3047EC] z-50 p-4 flex justify-between items-center">
    <h3 className="text-white font-bold ">ChatBot</h3>
    <button onClick={() => setchatBotOpen(false)}>
      <FontAwesomeIcon icon={faXmark} className=" z-4  text-white relative " />
    </button>
  </div>
);

const ChatbotComponent = ({ setchatBotOpen }) => {
  // const closebot =
  return (
    <div className="relative">
      <div className="fixed xs:px-4 xs:pl-6 right-0 bottom-32 lg:right-55 lg:bottom w-[400px]">
        <ThemeProvider theme={theme}>
          <ChatBot
            steps={steps}
            headerComponent={<CustomHeader setchatBotOpen={setchatBotOpen} />}
            botAvatar={botavatar}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default ChatbotComponent;
