// import React, { useState } from "react";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import { Wellnes } from "../components/Wellnes";
// import FeaturedProducts from "../components/FeaturedProducts";
// import NeedMed from "../components/NeedMed";
// import BrowseCategories from "../components/BrowseCategories";
// import SpecialOffers from "../components/SpecialOffers";
// import Pamper from "../components/Pamper";
// import CustomerReview from "../components/CustomerReview";
// import PopularBrand from "../components/PopularBrand";
// import NewsLetter from "../components/NewsLetter";

// import ChatbotComponent from "./ChatBot";

// function Home() {

//   return (
//     <>
//       <div className="overflow-x-hidden overflow-y-hidden">
//         <div>
//           <Header />
//         </div>

//         <div>
//           <Wellnes />
//         </div>
//         <div className="pt-6">
//           <FeaturedProducts />
//         </div>

//         <div>
//           <NeedMed />
//         </div>
//         {/* <div>
//           <BrowseCategories />
//         </div> */}
//         <div>
//           <SpecialOffers />
//         </div>
//         <div>
//           <Pamper />
//         </div>
//         <div className="py-10">
//           <CustomerReview />
//         </div>
//         <div>
//           <PopularBrand />
//         </div>
//         <div>
//           <NewsLetter />
//         </div>
//         <div>
//           <Footer
//           // setchatBotOpen={setchatBotOpen}
//           />
//           {/* {chatBotOpen ? (
//             <ChatbotComponent setchatBotOpen={setchatBotOpen} />
//           ) : null} */}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Home;

import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Wellnes } from "../components/Wellnes";
import FeaturedProducts from "../components/FeaturedProducts";
import NeedMed from "../components/NeedMed";
import BrowseCategories from "../components/BrowseCategories";
import SpecialOffers from "../components/SpecialOffers";
import Pamper from "../components/Pamper";
import CustomerReview from "../components/CustomerReview";
import PopularBrand from "../components/PopularBrand";
import NewsLetter from "../components/NewsLetter";
import ChatbotComponent from "./ChatBot";

function Home() {
  return (
    <>
      {/* <div
        style={{
          width: "100%",
          position: "sticky",
          top: "0px",
          zIndex: "99999999999999999999999999999",
        }}
      > */}
      <Header />
      {/* </div> */}
      <div className="overflow-x-hidden overflow-y-hidden bg-gray-100 pt-16">
        <div>
          <Wellnes />
        </div>
        <div className="pt-6">
          <FeaturedProducts />
        </div>
        <div>
          <NeedMed />
        </div>
        {/* <div>
          <BrowseCategories />
        </div> */}
        <div>{/* <SpecialOffers /> */}</div>
        <div>
          <Pamper />
        </div>
        <div className="py-10">{/* <CustomerReview /> */}</div>
        <div>{/* <PopularBrand /> */}</div>
        <div>
          <NewsLetter />
        </div>
        <div>
          <Footer
          // setchatBotOpen={setchatBotOpen}
          />
          {/* {chatBotOpen ? (
            <ChatbotComponent setchatBotOpen={setchatBotOpen} />
          ) : null} */}
        </div>
      </div>
    </>
  );
}
export default Home;
