import React, { useEffect, useState } from "react";
import logo1 from "../assets/images/forgot-password-image.png";
import { Link, useNavigate } from "react-router-dom";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { postData } from "../services/services/request";
import { setItem } from "../Lib/localStorage/localStorage";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [authMethod, setAuthMethod] = useState("email");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const validateInput = () => {
    // Phone number regex (adjust according to your phone format needs)
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (!username) {
      setError("Email or Phone number is required");
      return false;
    } else if (!emailRegex.test(username) && !phoneRegex.test(username)) {
      setError("Please enter a valid email or phone number");
      return false;
    }
    return true;
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setError(""); // Clear any existing errors

    // Phone number regex (adjust according to your phone format needs)
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    // Determine auth method based on input
    if (phoneRegex.test(value)) {
      setAuthMethod("phone");
    } else if (emailRegex.test(value)) {
      setAuthMethod("email");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await postData("/auths/forgot/link", {
        username: username,
        authMethod: authMethod,
        deviceType: "web",
      });

      if (response.code === 200) {
        setSuccess(true);
        setShowSuccessModal(true);
        
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      let errorMessage = "Failed to process request";

      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col sm:flex-row justify-center items-center min-h-screen w-full mx-auto"
      style={{ backgroundImage: `url(${caresimply})` }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Image Section */}
        <div className="w-full sm:w-1/2">
          <img
            src={logo1}
            className="h-auto sm:h-[637px] w-full sm:w-[555px] object-cover"
            alt="Forgot Password Illustration"
          />
        </div>

        {/* Form Section */}
        <div className="w-full lg:h-[300px] md:h-[300px] sm:w-1/2 bg-[#f5f7f9] p-6 flex justify-center sm:justify-start">
          <div className="w-full max-w-lg sm:w-full sm:max-w-[520px]">
            <h1 className="font-bold text-3xl sm:text-4xl text-center sm:text-left">
              Forgot <span className="text-[#AEDFF7]">Password?</span>
            </h1>
            <p className="text-[#5bacad] font-semibold mt-2 text-sm sm:text-base text-center sm:text-left">
              No worries, we'll send you reset instructions
            </p>

            {success ? (
              <div className="text-center">
                <p className="text-green-600 mb-4">
                  Password reset instructions have been sent to your{" "}
                  {authMethod}.
                </p>
                <Link
                  to="/login"
                  className="text-blue-500 hover:text-blue-700 font-semibold"
                >
                  Return to Login
                </Link>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col w-full mt-4">
                  <label
                    htmlFor="emailOrPhone"
                    className="block text-gray-700 font-bold text-base sm:text-xl"
                  >
                    Email or Phone number
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="emailOrPhone"
                    value={username}
                    onChange={handleUsernameChange}
                    className="px-4 py-2 border border-gray-300 rounded-md mt-2 w-full sm:max-w-[520px]"
                    placeholder="Enter your email or phone"
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>

                {/* Reset Password Button */}
                <button className="bg-[#394e6c] text-white w-full rounded-md p-2 font-semibold mt-6 sm:max-w-[520px]">
                  {loading ? "Loading ..." : " RESET PASSWORD"}
                </button>
              </form>
            )}

            {/* Back to Login */}
            <p className="text-center flex flex-col-reverse mt-32">
              <Link to="/login">
                Back to <span className="font-semibold">Sign in</span>
              </Link>
            </p>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
            <div className="flex flex-col items-center">
              {/* Success Icon */}
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              
              {/* Success Message */}
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                Link Sent Successfully!
              </h3>
              <p className="text-gray-600 text-center mb-6">
                Password reset instructions have been sent to your {authMethod}.
              </p>
              
              {/* Progress Bar */}
              <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                <div className="bg-green-500 h-2 rounded-full animate-[progress_2s_ease-in-out]"></div>
              </div>

              <p className="text-sm text-gray-500">
                Redirecting to login page...
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
