import React, { useEffect, useState, useRef } from "react";
import logo1 from "../assets/images/sign-Up-image.png";
import logo2 from "../assets/images/google.svg";
import logo3 from "../assets/images/apple-logo-svgrepo-com 1.svg";
import logo4 from "../assets/images/device-mobile.svg";
// import logo8 from "../assets/images/show-pass.svg";
import { Link, useNavigate } from "react-router-dom";
import logo9 from "../assets/images/logo-simple.png";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getData, postData } from "../services/services/request";
import { setItem } from "../Lib/localStorage/localStorage";
export default function SignUp() {
  const navigate = useNavigate();
  // const [SignUpData, setSignUpData] = useState({
  //   name: "",
  //   email: "",
  //   password: "",
  //   C_password: "",
  // });
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("email"); // Default to email
  const [password, setPassword] = useState("");
  const [C_password, setC_password] = useState("");
  const [Error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [hidePassword, sethHidePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorBackend, setErrorBackend] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchCode, setSearchCode] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignUpDataSubmit = async (e) => {
    e.preventDefault();
    setErrorBackend(null);
    setLoading(true);

    // Check if the user agrees to terms and conditions
    if (!isChecked) {
      setCheckboxError(
        "You must agree to the terms and conditions to continue."
      );
      setLoading(false); // Stop loading
      return; // Prevent form submission
    } else {
      setCheckboxError(""); // Clear error when checkbox is checked
    }

    // Validate form inputs
    const errors = validateForm();
    setError(errors);

    // If validation fails, stop the submission and display errors
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      // Ensure email and password validation is done before hitting the API
      if (selectedMethod === "email" && !emailInput) {
        setError({ email: "Email is Required*" });
        setLoading(false);
        return;
      }

      if (selectedMethod === "phone" && !phoneInput) {
        setError({ phone: "Phone Number is Required*" });
        setLoading(false);
        return;
      }

      if (!isValidPassword(password)) {
        setError({
          password:
            "Password must be at least 8 characters long, include one uppercase letter, one symbol, and one digit",
        });
        setLoading(false);
        return;
      }

      // Proceed with API call if all validations are passed
      const payload = {
        password: password,
        firstName: name,
        lastName: lastname,
        authMethod: selectedMethod,
        deviceId: "123",
        deviceType: "web",
        role: "USER",
      };

      // Add either email or phone to payload based on selected method
      if (selectedMethod === "email") {
        payload.email = emailInput;
      } else {
        payload.phone = phoneInput;
        payload.countryCode = selectedCountryCode; // Corrected line
      }

      const response = await postData("/auths/register/emailOrPhone", payload);

      // Handle successful response
      // setItem("token", response?.data.session.accessToken);
      setItem("UserId", response?.data.id);
      navigate("/OtpVerificationSing");
    } catch (error) {
      // Handle error response
      const errorMessage = error?.response?.data?.message;
      setErrorBackend(errorMessage);
      console.error("Signup error======:", errorMessage);
    } finally {
      // Stop loading after the request completes
      setLoading(false);
    }
  };
  const Countrycode = async () => {
    try {
      const countriescode = await getData("/auths/countries/Pin");

      // Ensure the response contains valid data
      if (Array.isArray(countriescode.data)) {
        setCountryCode(countriescode.data);
      } else {
        setCountryCode([]); // Fallback in case data is not in the expected format
      }

      countriescode.data.forEach((country) => {
        // console.log(country.dial_code, "KKKKKKKKKKKKKKKKKKKK");
      });

      return countriescode;
    } catch (error) {
      console.error("Error fetching country code data:", error);
      setCountryCode([]); // If an error occurs, ensure `countryCode` is an empty array
      return null;
    }
  };
  useEffect(() => {
    Countrycode();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (name.length === 0) {
      newErrors.name = "Field is Required*";
    } else if (name.length <= 2) {
      newErrors.name = "Length Should be at Least 3 Char long*";
    }
    if (selectedMethod === "email" && !emailInput) {
      newErrors.email = "Email is Required*";
    } else if (selectedMethod === "email" && !isValidEmail(emailInput)) {
      newErrors.email = "Invalid Email Format";
    }
    if (password.length === 0) {
      newErrors.password = "Password is required";
    } else if (!isValidPassword(password)) {
      newErrors.password =
        "Password must be at least 8 characters long, include one uppercase letter, one symbol, and one digit";
    }
    if (C_password.length === 0) {
      newErrors.C_password = "Field is Required";
    } else if (password !== C_password) {
      newErrors.C_password = "Password Must Match";
    }
    return newErrors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const openInNewTab = (path) => {
    // Open the link in a new tab
    window.open(path, "_blank");
  };

  const isValidPassword = (pass) => {
    const passwordRegex = /[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(pass);
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  // Add validation functions
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Handle input changes
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailInput(value);
    setSelectedMethod("email");
    setPhoneInput(""); // Clear phone when email is entered
    setError((prev) => ({ ...prev, email: "" }));
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Only allow digits
    setPhoneInput(value);
    setSelectedMethod("phone");
    setEmailInput(""); // Clear email when phone is entered
    setError((prev) => ({ ...prev, phone: "" }));
  };

  // Add function to handle search/filter
  const handleCodeSearch = (value) => {
    setSearchCode(value);
    setIsDropdownOpen(true);
    
    // Show all codes when input is empty
    if (!value.trim()) {
      setFilteredCountryCodes(countryCode);
      return;
    }

    const filtered = countryCode.filter(country => 
      country.dial_code.includes(value) || 
      country.code.toLowerCase().includes(value.toLowerCase()) ||
      country.name.toLowerCase().includes(value.toLowerCase())
    );
    
    setFilteredCountryCodes(filtered);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountryCode(country.dial_code);
    setSearchCode(country.dial_code);
    setIsDropdownOpen(false);
  };

  return (
    <div
      className="flex justify-center py-6 h-screen items-center w-full mx-auto bg-cover bg-center"
      style={{ backgroundImage: `url(${caresimply})` }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Left Side Image */}
        <div className="sm:h-full pt-52 md:h-full  lg:pt-0 md:pt-0">
          <div>
            <img
              src={logo1}
              className=" h-[500px] w-[500px] object-cover"
              alt="Logo"
            />
          </div>
        </div>
        {/* Right Side Form */}
        <div className="w-full sm:w-1/2 bg-[#F5F7F9] px-6 md:p-2  lg:px-12">
          {/* <div className="flex justify-end h-[100px]">
            <img src={logo9} alt="Company Logo" />
          </div> */}
          <div className="text-left pt-20">
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
              Sign <span className="text-[#AEDFF7]">Up</span>
            </h1>
            {/* <p className="text-[#5BACAD] font-semibold mt-2">
              Please Login to Continue
            </p> */}
          </div>
          {/* Input Fields */}
          <form onSubmit={handleSignUpDataSubmit}>
            <div>
              {" "}
              {/* <div className="flex flex-col w-full py-2">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold text-base md:text-lg"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="email"
                  value={enteredUsername}
                  onChange={(e) => setEnteredUsername(e.target.value)}
                  className="block px-4 py-2 border border-gray-300 rounded-md  focus:outline-none"
                  placeholder="Email or Phone number"
                />
                <p className="text-red-500 mt-1 mb-2">{emailPhonEerror}</p>
              </div> */}
              <div className="flex gap-2">
                {" "}
                <div className="flex flex-col w-full  py-1">
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-bold text-lg sm:text-xl"
                  >
                    Name
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    // id="firstName"
                    className="block px-4 py-2 border border-gray-300 rounded-md  w-full sm:max-w-md"
                    placeholder="Enter Name . . ."
                    // name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {Error.name && (
                    <div className="text-red-500 font-semibold">
                      {Error.name}
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full  py-1">
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-bold text-lg sm:text-xl"
                  >
                    Last Name
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    // id="firstName"
                    className="block px-4 py-2 border border-gray-300 rounded-md  w-full sm:max-w-md"
                    placeholder="Last Name . ."
                    // name="name"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                  {Error.name && (
                    <div className="text-red-500 font-semibold">
                      {Error.name}
                    </div>
                  )}
                </div>
              </div>
              {/* Email/Phone Toggle */}
              <div className="flex gap-2 py-2 ">
                <button
                  type="button"
                  onClick={() => setSelectedMethod("email")}
                  className={` ${
                    selectedMethod === "email"
                      ? "text-blue-500 text-black"
                      : "text-black"
                  }`}
                >
                  Email
                </button>
                <div>/</div>
                <button
                  type="button"
                  onClick={() => setSelectedMethod("phone")}
                  className={`  ${
                    selectedMethod === "phone"
                      ? "text-blue-500 text-black"
                      : "text-black"
                  }`}
                >
                  Phone
                </button>
                <div className="text-gray-400 text-xs pt-1 pl-4">
                  Select Email / Phone{" "}
                </div>
              </div>
              {/* Conditional Input based on selected method */}
              {selectedMethod === "email" ? (
                <div className="flex flex-col w-full">
                  <label className="block text-gray-700 font-bold text-base md:text-lg">
                    Email
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="email"
                    value={emailInput}
                    onChange={handleEmailChange}
                    className="block px-4 py-2 border border-gray-300 rounded-md w-full sm:max-w-md"
                    placeholder="Email Address"
                  />
                  {Error.email && (
                    <div className="text-red-500 font-semibold">
                      {Error.email}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col w-full">
                  <label className="block text-gray-700 font-bold text-base md:text-lg">
                    Phone Number
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex border rounded-md">
                    <div className="relative" ref={dropdownRef}>
                      <input
                        type="text"
                        value={searchCode}
                        onChange={(e) => handleCodeSearch(e.target.value)}
                        onFocus={() => {
                          setIsDropdownOpen(true);
                          setFilteredCountryCodes(countryCode); // Show all codes on focus
                        }}
                        placeholder="+00"
                        className="w-16 px-2 py-2 border-r rounded-md focus:outline-none"
                      />
                      {isDropdownOpen && (
                        <div className="absolute left-0 top-full mt-1 w-48 max-h-48 overflow-y-auto bg-white border rounded-md shadow-lg z-50">
                          {filteredCountryCodes.map((country) => (
                            <div
                              key={country.code}
                              onClick={() => handleCountrySelect(country)}
                              className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                            >
                              <span className="font-medium">{country.dial_code}</span>
                              <span className="text-sm text-gray-600">
                                {country.name} ({country.code})
                              </span>
                            </div>
                          ))}
                          {filteredCountryCodes.length === 0 && (
                            <div className="px-3 py-2 text-gray-500">No matches found</div>
                          )}
                        </div>
                      )}
                    </div>
                    <input
                      type="text"
                      name="phone"
                      value={phoneInput}
                      onChange={handlePhoneChange}
                      maxLength="10"
                      className="flex-1 px-4 py-2 focus:outline-none"
                      placeholder="Phone number"
                    />
                  </div>

                  {Error.phone && (
                    <div className="text-red-500 font-semibold">
                      {Error.phone}
                    </div>
                  )}
                </div>
              )}
              <div className="relative ">
                <label className="block text-gray-700 font-bold text-base md:text-lg">
                  {" "}
                  Password
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  // id="password"
                  className="block px-4 py-2 border border-gray-300 rounded-md  w-full sm:max-w-md"
                  placeholder="Password"
                  // name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {Error.password && (
                  <div className="text-red-500 font-semibold">
                    {Error.password}
                  </div>
                )}
                <div className="absolute lg:right-[13px] lg:top-[38px]  right-[18px] top-[34px]  text-[#C4C4C4]">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
              </div>
              {/* Confirm Password */}
              <div className="relative py-1">
                <label className="block text-gray-700 font-bold text-base md:text-lg">
                  Confirm Password
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>

                <input
                  type={hidePassword ? "text" : "password"}
                  // id="C_password"
                  className="block px-4 py-2 border border-gray-300 rounded-md  w-full sm:max-w-md"
                  placeholder="Confirm Password"
                  name="C_password"
                  value={C_password}
                  onChange={(e) => setC_password(e.target.value)}
                />
                {Error.C_password && (
                  <div className="text-red-500 font-semibold">
                    {Error.C_password}
                  </div>
                )}
                <div className="absolute lg:right-[13px] lg:top-[42px] right-[18px] top-[38px] text-[#C4C4C4]">
                  <button
                    type="button"
                    onClick={() => sethHidePassword("hidePassword")}
                  >
                    <FontAwesomeIcon icon={hidePassword ? faEye : faEyeSlash} />
                  </button>
                </div>
              </div>
              <div className="flex   py-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="checkbox2"
                    className="form-checkbox h-4  text-blue-600 border-gray-300 rounded"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <label
                    htmlFor="checkbox"
                    className="ml-2 text-gray-700 text-sm md:text-base"
                  >
                    I agree to the{" "}
                    <button onClick={() => openInNewTab("/termsCondition")}>
                      <span className="text-blue-500">
                        Terms and Conditions
                      </span>
                    </button>
                  </label>
                </div>
              </div>
              {checkboxError && (
                <div className="text-red-500 font-semibold">
                  {checkboxError}
                </div>
              )}
              {/* <button onClick={handleSignIn}>Sign In</button>
              {isSignedIn && (
                <p style={{ color: "green" }}>Sign-in successful!</p>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
            </div>

            <div className="">
              <button
                type="submit"
                className="bg-[#394E6C] text-white w-full rounded-md py-2 font-semibold hover:bg-blue-700 transition duration-200"
              >
                {loading ? "Loading ..." : " SIGN UP"}
              </button>
              {/* {isSignedIn && (
                <p style={{ color: "green" }}>Sign-in successful!</p>
              )} */}
              {errorBackend && <p style={{ color: "red" }}>{errorBackend}</p>}{" "}
            </div>
          </form>

          {/* OR Divider */}
          <div className="relative flex py-2 items-center ">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="flex-shrink mx-4 text-gray-500">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          {/* Sign Up Link */}
          <div className="text-center py-2">
            {/* className="text-gray-600  text-sm md:text-base"> */}
            Do you have account?{" "}
            <Link to="/login">
              <span className="text-blue-500 font-semibold">Sign in</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
