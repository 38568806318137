import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../services/services/request";
import Skeleton from "@mui/material/Skeleton";

export default function NeedMed() {
  const sliderRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const setting = {
    dots: false,
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay interval (3 seconds)
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // For tablets
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 568, // For mobile
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  const scrollPrev = () => {
    sliderRef.current.slickPrev();
  };

  const scrollNext = () => {
    sliderRef.current.slickNext();
  };
  const SearchMedicine = async () => {
    const pageNo = 1000;
    try {
      const response = await getData(`/medicines?pageSize=${pageNo}`);
      // console.log(" api dataaaaaaaaaaa", response);
      const data = response.items;
      // console.log("daataaaaaaaa", data);
      if (Array.isArray(data)) {
        setProducts(data);
        setLoading(false);
      } else {
        console.error("Received data is not an array:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    SearchMedicine();
  }, []);

  return (
    <div className="py-14 flex justify-center">
      <div className="container mx-auto max-w-[1200px] ">
        <div className="relative flex items-center justify-center">
          <div className="text-[90px] opacity-15 text-blue-800 uppercase xs:text-[30px] text-center sm:text-[40px] md:text-[50px] lg:text-[90px]">
            Health Care Products
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[35px] uppercase text-center whitespace-nowrap xs:text-[20px] sm:text-[8px] md:text-[25px] lg:text-[40px]">
            Our Health Care Products
          </div>
        </div>
        {/* Header Section */}
        <div className="flex px-5 items-center justify-between  mb-4">
          <div>
            <h1 className="text-sm sm:text-xl md:text-2xl lg:text-3xl font-normal text-[#304F5E]">
              Our Health Care Products
            </h1>
          </div>

          <div className="flex gap-2">
            {/* Left Button */}
            <button
              onClick={scrollPrev}
              className="rounded-full p-3 h-[40px] flex bg-gray-200 hover:bg-[#4CAF50]"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            {/* Right Button */}
            <button
              onClick={scrollNext}
              className="rounded-full p-3 h-[40px] flex bg-gray-200 hover:bg-[#4CAF50]"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>

        {/* Slider Section */}
        <div>
          {loading ? (
            <div className="flex justify-center items-center gap-5">
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
              <div className=" w-full">
                <Skeleton height={300} />
                <Skeleton width="60%" />
              </div>
            </div>
          ) : (
            <Slider ref={sliderRef} {...setting}>
              {products.map((product) => (
                <div
                  key={product.id}
                  className="p-3 relative rounded-lg mt-4 lg:w-[32%] md:w-[80%] sm:w-[95%] mx-auto group"
                >
                  <Link to={`/productdetails/${product.id}`}>
                    <div className="relative rounded-md h-full transition-all">
                      {/* Image */}
                      <div className="relative">
                        {product.newproduct && (
                          <div className="absolute top-2 left-2 flex items-center space-x-2 bg-opacity-75 p-2 rounded bg-[#00a95d]">
                            <img
                              src={product.logo}
                              className="object-fill"
                              alt="Logo"
                            />
                            <p className="text-sm font-semibold">
                              {product.arrivals}
                            </p>
                          </div>
                        )}
                        <img
                          src={product.image[0]}
                          className="h-[300px] w-full object-fill"
                          alt="Product"
                        />
                      </div>

                      {/* "Show More" Text */}
                      <div className="absolute inset-0 flex items-center  bg-black bg-opacity-40 justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <span className="text-white  text-lg font-semibold">
                          Show more
                        </span>
                      </div>

                      {/* Product Details */}
                      <div className="p-4 mx-0 w-full bg-[#262626]">
                        <div className="flex justify-between">
                          <h1 className="font-bold text-lg text-white text-truncate">
                            {product.medicineName}
                          </h1>
                          <span className="font-bold px-4 py-2 mt-3 mb-2 rounded-md border-2 p-1 text-white">
                            <span className="p-1">$</span>
                            {product.price}
                          </span>
                        </div>
                        <span className="text-sm text-gray-400 text-truncate">
                          {product.medicineDetails}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
