import React, { useRef, useState } from "react";
import logo1 from "../assets/images/reset-password-image.jpg";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { postData } from "../services/services/request";
import { getItem } from "../Lib/localStorage/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { setItem } from "../utils/LocalStorageFunctions";

export default function OtpVerificationSing() {
  const inputRefs = useRef([]); // Refs to handle input focus
  const [otp, setOtp] = useState(["", "", "", ""]);
  // console.log(otp);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      // Allow only single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const activationCode = otp.join("");
    const userId = getItem("UserId");

    try {
      const response = await postData("/auths/verifyOTP", {
        activationCode,
        userId,
        deviceId: "123",
        deviceType: "web",
      });
      console.log("OTP response:", response);
      console.log("HELLO", response);
      setItem("token", response?.data.session.accessToken);
      setItem("UserId", response?.data.id);
      navigate("/");
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      setError(errorMessage);
      console.error("Login error:", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (otp[index]) {
        // Clear current input
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        // Move to previous input if empty
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text"); // Get pasted text
    if (/^\d{4}$/.test(pasteData)) {
      // If the pasted data is a 4-digit number
      const newOtp = pasteData.split(""); // Split the pasted data into individual characters
      setOtp(newOtp);
      inputRefs.current[3]?.focus(); // Move to the last input field after paste
    }
  };

  return (
    <div
      className="flex justify-center items-center h-screen w-full mx-auto"
      style={{
        backgroundImage: `url(${caresimply})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end p-4">
          <img
            src={logo1}
            className="h-[300px] lg:h-[521px] w-[250px] lg:w-[415px] object-contain"
            alt="Forgot Password Illustration"
          />
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2 bg-[#f5f7f9] border-2 p-6">
          <div className="flex flex-col h-auto lg:h-[521px] justify-center pl-6 lg:pl-[25px] py-4">
            <h1 className="font-bold text-2xl lg:text-4xl text-left">
              Email /Phone <span className="text-[#AEDFF7]"> Verification</span>
            </h1>
            <p className="text-[#5bacad] font-semibold mt-2">
              Now type in the OTP sent to
            </p>

            <label
              htmlFor="otp"
              className="block text-gray-700 font-bold text-lg lg:text-xl mt-4"
            >
              Enter OTP
            </label>
            {/* OTP Input Fields */}
            <div
              className="flex justify-start sm:justify-start lg:justify-start items-center gap-2 mt-2 max-w-[440px]"
              onPaste={handlePaste} // Attach paste event
            >
              {otp.map((digit, index) => (
                <input
                  ey={index}
                  ref={(el) => (inputRefs.current[index] = el)} // Assign ref
                  type="text"
                  value={digit}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className="w-16 h-14 text-center text-2xl border-2 border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
                />
              ))}
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}

            {/* Reset Password Button */}
            <button
              onClick={submitHandler}
              className="bg-[#394e6c] text-white rounded-md py-2 px-4 font-semibold mt-6 w-full lg:w-[400px]"
            >
              {loading ? "Loading ..." : "SUBMIT"}
            </button>

            {/* Back to Sign In */}
            {/* <div className="text-center mt-4 py-5">
              <a href="#" className="text-gray-600">
                Back to <span className="text-black">Sign In</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
