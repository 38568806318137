import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import logo1 from "../assets/images/Vector.svg";
import logo3 from "../assets/images/Vector2.png";
import logo2 from "../assets/images/image 13.svg";
import Address from "./addAddress";
import { deleteData, getData, postData } from "../services/services/request";
import { getItem } from "../utils/LocalStorageFunctions";
import EditIcon from "@mui/icons-material/Edit";
import EddittADDress from "./EditAddress";

function NewAddress() {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([{ logo: logo1 }]);
  const [newAddressID, setNewAddressID] = useState(addresses?.length);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [formData, setFormData] = useState({
    id: newAddressID,
    name: "",
    email: "",
    address: "",
    phoneNumber: "",
  });
  const [selectedId, setSelectedId] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(""); // Track error message
  const token = getItem("token");
  const tokens = token.replace(/"/g, "");
  const userId = JSON.parse(getItem("UserId"));
  const pageNo = 1000;

  const config = {
    headers: {
      "x-access-token": tokens,
    },
  };

  const AddressAPI = async () => {
    setLoading(true); // Set loading to true when the request starts
    setError(""); // Clear previous errors

    try {
      if (!token) {
        console.error("UserId or Token not found in localStorage");
        setError("Token not found");
        setLoading(false);
        return;
      }

      const response = await getData(
        `/addresses?userId=${userId}&&pageSize=${pageNo}`,
        config
      );

      if (response.code === 200) {
        setAddresses(response.items);
      } else {
        console.error("Error: ", response.status);
        setError("Failed to load addresses");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Something went wrong while loading addresses.");
    } finally {
      setLoading(false); // Set loading to false after the request ends
    }
  };

  useEffect(() => {
    AddressAPI();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDelete = async (deleteId) => {
    const data = {};
    try {
      // console.log("Token", tokens);
      // console.log("config", config);
      const response = await deleteData(`/addresses/${deleteId}`, data, config);
      AddressAPI();
    } catch (error) {
      console.error("Error while deleting address: ", error);
    }
  };
  const handleEdit = (addressId) => {
    setSelectedId(addressId); // Update the selectedId if needed
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: addressId, // Pass the address ID here to the form data
    }));
    setModalOpen1(true); // Open the modal
  };

  const handleAddressClick = (id) => {
    setSelectedId(id); // Set the selected address's id
    const selectedAddress = addresses.find((address) => address._id === id);
    // console.log("Selected Address: ", selectedAddress); // Log the selected address
  };

  return (
    <>
      <Header />
      <div className="lg:flex justify-start items-start pt-24">
        <button className="rounded-full flex p-3 h-[40px] lg:ml-[20px] lg:md-[10px] mt-[10px] bg-gray-200 hover:bg-[#4CAF50]">
          <Link to="/">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </Link>
        </button>
      </div>

      {/* EditAddress Modal */}
      <Address
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close the modal
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        AddressAPI={AddressAPI}
      />
      <EddittADDress
        open={isModalOpen1}
        onClose={() => setModalOpen1(false)} // Close the modal
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        AddressAPI={AddressAPI}
        selectedId={selectedId}
      />

      <div className="flex flex-wrap mt-10 gap-5 lg:justify-between items-start">
        <div className="w-full flex justify-end lg:justify-end items-center px-2 text-right sm:text-left">
          <div>
            <img src={logo2} alt="Logo" />
          </div>
          <div className="ml-2">
            <button
              onClick={() => setModalOpen(true)}
              className="font-bold underline"
            >
              Add new address
            </button>
          </div>
        </div>
        <div className="flex justify-center flex-wrap gap-5 w-full">
          {loading && !addresses?.length && (
            <p className="mt-10 text-gray-400">Loading addresses...</p>
          )}
          {error ? (
            <p className="mt-10 text-red-500">{error}</p>
          ) : addresses?.length === 0 ? (
            <p className="mt-10 text-gray-400">Please add a new address</p>
          ) : (
            addresses?.map((address) => (
              <div
                key={address._id}
                onClick={() => handleAddressClick(address._id)}
              >
                <div className={"rounded-md p-4 outline-dashed"}>
                  <div className="flex flex-col">
                    <div className="pb-2 flex justify-between">
                      <img src={logo1} alt="Logo" />
                      <div className=" flex gap-2">
                        <EditIcon
                          size="x"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the address click event from being triggered
                            handleEdit(address._id); // Open the modal with the selected address details
                          }}
                          className="cursor-pointer"
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          size="x"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(address._id);
                          }}
                          className="cursor-pointer pt-1"
                        />
                      </div>
                    </div>

                    <div>
                      <h1 className="text-lg font-semibold">
                        {address.address}, {address.city}, {address.state}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-lg font-semibold">
                        {address.country}, {address.zipCode}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewAddress;
