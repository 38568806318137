import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../assets/images/image-signIn.png";
import logo9 from "../assets/images/logo-simple.png";
import caresimply from "../assets/images/care-simply-logo-bg-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../services/services/request";
import { setItem } from "../Lib/localStorage/localStorage";
import { LoginFunction } from "../components/Redux/Slices/LoginSlice"; // Import your Redux action
import Cookies from "js-cookie"; // Import js-cookie for handling cookies
import { GoogleLogin } from "@react-oauth/google";
import GoogleIcon from "@mui/icons-material/Google";
import { jwtDecode } from "jwt-decode";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { use } from "react";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

const clientId =
  "564908570278-0dugld1ogcts4kq7rfss1tlc6j17vin8.apps.googleusercontent.com";

export default function Login() {
  const [username, setemailPhone] = useState("");
  const [user, setUser] = useState([]);
  const [password, setPassword] = useState("");
  const [emailPhonEerror, setemailPhoneError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // Track the "Remember me" checkbox state
  const [authMethod, setAuthMethod] = useState("email");
  const [profile, setProfile] = useState([]); // Add this new state
  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const savedAuthMethod = Cookies.get("authMethod");

    if (savedUsername && savedPassword) {
      setemailPhone(savedUsername);
      setPassword(savedPassword);
      if (savedAuthMethod) {
        setAuthMethod(savedAuthMethod);
      }
      setRememberMe(true);
    }
  }, []);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          console.log("GOOGLE response data ", profile);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    setemailPhoneError("");
    setpasswordError("");
  }, [username, password]);

  const validateInputs = () => {
    let isValid = true;

    // Phone number regex (adjust according to your phone format needs)
    const phoneRegex = /^\d{10}$/; // Matches exactly 10 digits
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (!username) {
      setemailPhoneError("Email or Phone number is required");
      isValid = false;
    } else if (!emailRegex.test(username) && !phoneRegex.test(username)) {
      setemailPhoneError("Please enter a valid email or phone number");
      isValid = false;
    }

    if (!password) {
      setpasswordError("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setemailPhone(value);

    // Phone number regex (adjust according to your phone format needs)
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    // Determine auth method based on input
    if (phoneRegex.test(value)) {
      setAuthMethod("phone");
    } else if (emailRegex.test(value)) {
      setAuthMethod("email");
    }

    if (emailPhonEerror) {
      setemailPhoneError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError) {
      setpasswordError("");
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const response = await postData("/auths/login", {
        username,
        password,
        authMethod: authMethod,
        verificationType: "password",
        deviceType: "web",
      });

      setItem("token", response?.data.session.accessToken);
      setItem("UserId", response?.data.id);

      // Save username, password, and authMethod in cookies if "Remember me" is checked
      if (rememberMe) {
        Cookies.set("username", username, { expires: 30 });
        Cookies.set("password", password, { expires: 30 });
        Cookies.set("authMethod", authMethod, { expires: 30 });
      } else {
        // If not remembering, remove any cookies set before
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("authMethod");
      }

      navigate("/");
    } catch (error) {
      let errorMessage = "Server offline ";

      if (error?.response) {
        const statusCode = error?.response?.status;
        if (statusCode === 401) {
          errorMessage = "Unauthorized access. Please check your credentials.";
        } else if (statusCode === 402) {
          errorMessage = "Payment required. Please check your account.";
        } else if (statusCode === 403) {
          errorMessage =
            "Forbidden. You do not have permission to access this resource.";
        } else {
          errorMessage = error?.response?.data?.message || errorMessage;
        }
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      if (credentialResponse.credential) {
        // Decode the JWT token received from Google
        const decodedData = jwtDecode(credentialResponse.credential);
        console.log("Google User Data:", decodedData);

        const { email, name, picture, sub: googleId } = decodedData;

        let nameParts = name.split(" ");
        let firstName = nameParts[0];
        let lastName = nameParts[1] || "";
        const loginData = {
          username: email,
          firstName: firstName,
          lastName: lastName,
          profileImage: picture,
          googleId: googleId,
          authMethod: "google",
          deviceType: "web",
          verificationType: "google",
        };

        // Make API call to your backend
        const response = await postData("/auths/login", loginData);

        if (response.data) {
          // Store user data
          setItem("token", response.data.session.accessToken);
          setItem("UserId", response.data.id);
          setItem("userName", name);

          // Save auth method in cookies if "Remember me" is checked
          if (rememberMe) {
            Cookies.set("authMethod", "google", { expires: 30 }); // Save google as authMethod
          }

          // Navigate to home page
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Google Login Error:", error);
      setError(
        error.response?.data?.message ||
          "Google login failed. Please try again."
      );
    }
  };

  return (
    <div
      className="flex justify-center h-screen items-center py-10 w-full mx-auto bg-cover bg-center"
      style={{ backgroundImage: `url(${caresimply})` }}
    >
      <div className="flex flex-col lg:h-[570px] md:h-[570px] sm:flex-row justify-center items-center bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden w-full max-w-5xl mx-auto">
        {/* Left Side Image */}
        <div className="sm:h-full pt-52 md:h-full lg:pt-0 md:pt-0">
          <img
            src={logo1}
            className="h-[500px] w-[450px] object-cover"
            alt="Logo"
          />
        </div>

        {/* Right Side Form */}
        <div className="w-full sm:w-1/2 bg-[#F5F7F9] p-6 md:p-10 lg:p-12">
          <Link to="/">
            <div className="flex justify-center h-[100px]">
              <img src={logo9} alt="Company Logo" />
            </div>
          </Link>

          <div className="text-left">
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">
              Welcome <span className="text-[#AEDFF7]">Back</span>
            </h1>
            <p className="text-[#5BACAD] font-semibold mt-2">
              Please SignIn to Continue
            </p>
          </div>

          <form onSubmit={submitHandler}>
            <div>
              {/* Email or Phone number input */}
              <div className="flex flex-col w-full">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold text-base md:text-lg"
                >
                  Email
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  value={username}
                  onChange={handleUsernameChange}
                  className="block px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                  placeholder="Email"
                />
                <p className="text-red-500 mt-1 mb-2 text-md">
                  {emailPhonEerror}
                </p>
              </div>

              {/* Password input */}
              <div className="relative">
                <label className="block text-gray-700 font-bold text-base md:text-lg">
                  Password
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </label>

                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="block px-4 py-2 border border-gray-300 rounded-md mt-2 focus:outline-none w-full"
                  placeholder="Password"
                />
                <p className="text-red-500 mb-2 text-[15px]">{passwordError}</p>

                <div className="absolute right-[8px] top-[50px]">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 -top-1 text-[#C4C4C4]"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
            </div>

            {/* Render error message if any */}
            {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}

            {/* Remember Me and Forgot Password */}
            <div className="flex justify-between items-center py-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="checkbox2"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="checkbox2"
                  className="ml-2 text-gray-700 text-sm md:text-base"
                >
                  Remember me
                </label>
              </div>
              <Link
                to="/forgotpassword"
                className="text-sm md:text-base font-semibold"
              >
                Forgot Password
              </Link>
            </div>

            <div>
              <button
                type="submit"
                className="bg-[#394E6C] text-white w-full rounded-md py-2 font-semibold hover:bg-blue-700 transition duration-200"
              >
                {loading ? "Loading ..." : "Sign In"}
              </button>
            </div>
          </form>

          {/* Google Login Button */}
          <div className="mt-3 flex flex-col md:flex-row justify-between">
            <GoogleLogin 
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.error("Google Login Failed");
                setError("Google sign in failed. Please try again.");
              }}
              shape="rectangular"
              width="100%"  
              icon={true} 
              useOneTap={true}
              className="font-semibold bg-[#394E6C] hover:bg-blue-700 w-full md:w-auto"
            />

            <Link to="/loginWithnumber">
              <button className="border text-[13px] w-full md:w-auto flex gap-1 px-4 rounded-md py-2 bg-white transition duration-200">
                <div>
                  <PhoneAndroidIcon />
                </div>
                <div className="pt-1">With Phone Number</div>
              </button>
            </Link>
          </div>

          {/* Sign Up Link */}
          <div className="text-center py-2 flex gap-1 justify-center">
            <div> Don't have an account?</div>
            <Link to="/signup">
              <span className="text-blue-400 font-semibold">Sign Up</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
