import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { getItem } from "../utils/LocalStorageFunctions";
import { getData, postData } from "../services/services/request";
import { useNavigate } from "react-router-dom";

function Address({ open, onClose, handleInputChange, error, AddressAPI }) {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [errror, setError] = useState(null);
  const [countryCode, setCountryCode] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("00");
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [searchCode, setSearchCode] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation before submitting
    if (
      !firstname ||
      !lastname ||
      !email ||
      !phoneNumber ||
      !address ||
      !city ||
      !zipcode ||
      !country ||
      !state
    ) {
      setError("Please fill all required fields.");
      return;
    }

    // Ensure phone number is numeric and of valid length
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError("Invalid phone number. Please enter a valid 10-digit number.");
      return;
    }

    const token = getItem("token");
    const tokens = token.replace(/"/g, ""); // Ensure proper token formatting

    const config = {
      headers: {
        "x-access-token": tokens,
      },
    };

    const data = {
      firstName: firstname,
      lastName: lastname,
      address,
      country,
      state,
      city,
      zipCode: zipcode,
      email,
      phoneNumber,
      phoneCode: selectedCountryCode,
    };

    try {
      const response = await postData("/addresses", data, config);
      if (response.code === 200) {
        // Clear all states after success
        setFirstname("");
        setLastname("");
        setEmail("");
        setPhoneNumber("");
        setAddress("");
        setCity("");
        setZipcode("");
        setCountry("");
        setState("");
        setSelectedCountryCode("00"); // Reset to default country code
        onClose();
        AddressAPI();
      } else {
        console.error("Failed to add address:", response);
      }
    } catch (error) {
      console.error("Error during address submission:", error);
      if (error.response) {
        console.error("Response error:", error.response);
      }
    }
  };

  const Countrycall = async () => {
    try {
      const countryres = await getData("/auths/countries/List");
      setCountries(countryres.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const handleCodeSearch = (value) => {
    setSearchCode(value);
    setIsDropdownOpen(true);

    // Show all codes when input is empty
    if (!value.trim()) {
      setFilteredCountryCodes(countryCode);
      return;
    }

    const filtered = countryCode.filter(
      (country) =>
        country.dial_code.includes(value) ||
        country.code.toLowerCase().includes(value.toLowerCase()) ||
        country.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredCountryCodes(filtered);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountryCode(country.dial_code);
    setSearchCode(country.dial_code);
    setIsDropdownOpen(false);
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await getData("/auths/countries/Pin");
      if (response.data) {
        setCountryCode(response.data);
        if (!selectedCountryCode && response.data.length > 0) {
          setSelectedCountryCode(response.data[0].dial_code);
        }
      }
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  useEffect(() => {
    Countrycall();
    fetchCountryCodes();
  }, []);

  const fetchStates = async (countryCode) => {
    try {
      setLoadingStates(true); // Set loading to true while fetching states
      const response = await getData(
        `/auths/states?countryCode=${countryCode}`
      );
      if (response.data && Array.isArray(response.data.states)) {
        setStates(response.data.states);
      } else {
        console.error("Invalid states data:", response.data);
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      setStates([]); // Fallback to an empty array on error
    } finally {
      setLoadingStates(false); // Set loading to false after fetching states
    }
  };

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    const selectedCountry = countries.find(
      (Country) => Country.code3 === countryCode
    );

    if (selectedCountry) {
      setCountry(selectedCountry.name);
      setState(""); // Reset state when country changes
      fetchStates(countryCode);
    } else {
      console.error("Country not found for the selected code:", countryCode);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded-md max-w-[500px] w-[100%] mx-4">
          <h2 className="text-2xl font-bold mb-4">Add Address</h2>
          <form onSubmit={handleSubmit}>
            <div className="flex gap-2 mt-2 w-[100%] ">
              <div className="w-[100%]">
                <label className="block text-sm font-medium">First Name</label>
                <input
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  className=" max-w-[215px] w-[100%] border rounded p-1 mt-1"
                  required
                />
              </div>
              <div className="w-[100%]">
                <label className="block text-sm font-medium">Last Name</label>
                <input
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  className=" max-w-[305px] w-[100%] border rounded p-1 mt-1"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col lg:w-[430px] md:w-[440px] mt-2 ">
              <label className="flex text-gray-700 font-medium justify-start">
                Phone Number
              </label>
              <div className="flex border rounded-md">
                <div className="relative" ref={dropdownRef}>
                  <input
                    type="text"
                    value={searchCode}
                    onChange={(e) => handleCodeSearch(e.target.value)}
                    onFocus={() => {
                      setIsDropdownOpen(true);
                      setFilteredCountryCodes(countryCode); // Show all codes on focus
                    }}
                    placeholder="+00"
                    className="max-w-16 w-[100%] px-2 py-2 border-r rounded-md focus:outline-none"
                  />
                  {isDropdownOpen && (
                    <div className="absolute left-0 top-full mt-1 w-48 max-h-48 overflow-y-auto bg-white border rounded-md shadow-lg z-50">
                      {filteredCountryCodes.map((country) => (
                        <div
                          key={country.code}
                          onClick={() => handleCountrySelect(country)}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                        >
                          <span className="font-medium">
                            {country.dial_code}
                          </span>
                          <span className="text-sm text-gray-600">
                            {country.name} ({country.code})
                          </span>
                        </div>
                      ))}
                      {filteredCountryCodes.length === 0 && (
                        <div className="px-3 py-2 text-gray-500">
                          No matches found
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <input
                  type="text"
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength="10"
                  className="flex-1 px-4 py-2 focus:outline-none rounded-md"
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border rounded p-1 mt-1"
                required
              />
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium">Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full border rounded p-1 mt-1"
                required
              />
            </div>
            <div className="flex gap-2 mt-2">
              <div className="w-[100%] ">
                <label className="block text-sm font-medium">Country</label>
                <select
                  value={country}
                  onChange={handleCountryChange}
                  className="max-w-[215px] w-[100%] border rounded p-2 mt-1"
                >
                  <option value="">{country || "Select"}</option>
                  {countries.map((country) => (
                    <option key={country.code3} value={country.code3}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[100%] ">
                <label className="block text-sm font-medium">State</label>
                <select
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  className="max-w-[215px] w-[100%] border rounded p-2 mt-1"
                  disabled={loadingStates}
                >
                  <option value="">Select</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {loadingStates && <p>Loading states...</p>}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="mt-2 w-[100%] ">
                <label className="block text-sm font-medium">City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="max-w-[215px] w-[100%] border rounded p-1 mt-1"
                  required
                />
              </div>
              <div className="mt-2 w-[100%]">
                <label className="block text-sm font-medium">Zip Code</label>
                <input
                  type="text"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                  className="max-w-[215px] w-[100%] border rounded p-1 mt-1"
                  required
                />
              </div>
            </div>
            <p className="text-red-500 flex justify-center mb-2 text-[15px]">
              {errror}
            </p>

            <div className="flex justify-between mt-[10px]">
              <button
                type="button"
                onClick={() => onClose()}
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default Address;
