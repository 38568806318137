import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo1 from "../assets/images/freepik--Character--inject-1.png";
import logo5 from "../assets/images/lifeboy-product.jpg";
import logo6 from "../assets/images/detol-pouche-image.jpg";
import logo7 from "../assets/images/House.svg";
import logo8 from "../assets/images/ArrowLeft.svg";
import freepik from "../assets/images/freepik--background-image.png";
import Header from "./Header";
import Footer from "./Footer";

function Error() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="flex justify-center items-center lg:min-h-screen md:py-28 py-28">
        <div className="text-center w-full max-w-screen-lg px-4">
          {/* Image Section */}
          <div className="flex flex-col justify-center items-center ">
            <div
              className="pt-20 w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] bg-no-repeat bg-center bg-contain"
              style={{ backgroundImage: `url(${freepik})` }}
            >
              <div className="flex justify-center text-center">
                <img
                  src={logo1}
                  className="w-[80%] sm:w-[70%] md:w-[60%] h-auto"
                  alt="Error Illustration"
                />
              </div>
            </div>
          </div>

          {/* Text Section */}
          <div className="mt-6">
            <h1 className="font-semibold text-2xl sm:text-3xl">
              404, Page not found
            </h1>
          </div>

          <div className="mt-4">
            <h1 className="w-full sm:w-[70%] md:w-[50%] lg:w-[60%] mx-auto text-center">
              Something went wrong. It looks like your request could not be
              found. The link might be broken, or the page has been removed.
            </h1>
          </div>

          {/* Button Section */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-5 mt-5">
            <div className="relative">
              {/* <div>
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="border-gray-300 p-2 w-[200px] h-[50px] font-semibold bg-[#394e6c] text-white"
                >
                  GO BACK
                </button>
              </div> */}
              <div className="absolute right-[150px] top-[50%] transform -translate-y-1/2">
                <img src={logo8} alt="Arrow Icon" />
              </div>
            </div>
            <div className="relative">
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="border-2 border-gray-300 p-2 w-[200px] h-[50px] font-semibold text-[#394e6c]"
              >
                GO TO HOME
              </button>
              <div className="absolute right-[160px] top-[50%] transform -translate-y-1/2">
                <img src={logo7} alt="Home Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Error;
