import React, { useState } from "react";

const Termscondition = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Terms and Conditions</h1>
      <p>
        Please read these terms and conditions carefully before using our
        service.
      </p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          By accessing or using our service, you agree to be bound by these
          Terms and Conditions and our Privacy Policy. If you do not agree to
          these terms, you must not use our service.
        </p>
      </section>

      <section>
        <h2>2. Use of Service</h2>
        <p>
          Our service allows you to access various resources and features. You
          agree to use the service only for lawful purposes and in accordance
          with the rules outlined in this agreement.
        </p>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <ul>
          <li>
            You are responsible for maintaining the confidentiality of your
            account and password.
          </li>
          <li>
            You agree not to use our service for illegal activities or in any
            manner that could damage, disable, overburden, or impair the
            service.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Privacy and Data Collection</h2>
        <p>
          We respect your privacy and are committed to protecting your personal
          information. Please review our Privacy Policy for detailed information
          on how we collect, use, and store your data.
        </p>
      </section>

      <section>
        <h2>5. Termination</h2>
        <p>
          We may suspend or terminate your access to the service at any time if
          we believe you are violating these terms.
        </p>
      </section>

      <section>
        <h2>6. Changes to Terms</h2>
        <p>
          We reserve the right to modify or replace these Terms and Conditions
          at any time. It is your responsibility to review these terms
          periodically for changes.
        </p>
      </section>

      <section>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions regarding these Terms and Conditions, please
          contact us at{" "}
          <a href="mailto:support@example.com">support@example.com</a>.
        </p>
      </section>
    </div>
  );
};

export default Termscondition;
